import { Component } from 'react';

import SignLayout from '../../../../layout/SignLayout';
import UserInfo from './components/UserInfo';
import Interest from './components/Interest';
import Profile from './components/Profile';
export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      info: {
        email: '',
        password: '',
        confirmPassword: '',
        avatar: '',
        baseUrl: ''
      },
      profile: {
        firstName: '',
        lastName: '',
        country: '',
        state: '',
        postalCode: '',
        address: '',
        address2: '',
        city: '',
        birthYear: '',
        birthMonth: '',
        birthDay: '',
        phoneNum: '',
        zipCode: 'string', //TODO
        ssn: '',
        isCheck: false
      }
    };
    this.onClickNextStep = this.onClickNextStep.bind(this);
    this.updateInfo = this.updateInfo.bind(this);
    this.setStep = this.setStep.bind(this)
    this.updateProfile = this.updateProfile.bind(this)
  }

  updateInfo(params) {
    this.setState({info:{...this.state.info, ...params}});
  }
  updateProfile(params) {
    this.setState({profile:{...this.state.profile, ...params}});
  }

  onClickNextStep() {
    this.setState({step: 2});
  }

  setStep(step) {
    this.setState({step: step});
  }

  render() {
    const { step } = this.state;
    // const switchStep = () => this.setState({ step: Math.abs(step - 1) });
    let children = new Function();
    switch (step) {
    case 1:
      children = <UserInfo info={this.state.info} updateInfo={this.updateInfo} setStep={this.setStep} />;
      break;
    case 2:
      children = <Profile info={this.state.profile} updateInfo={this.updateProfile} setStep={this.setStep} />
      break;
    case 3:
      children = <Interest info={this.state.info} profile={this.state.profile} updateInfo={this.updateInfo} setStep={this.setStep}/>;
      break;
    default:
      children = <UserInfo info={this.state.info} />;
    };
    return (
      <SignLayout orientation="1" title="Already have an account?" type="1" role="influencer" disableSubTitle={true}>
        {children}
      </SignLayout>
    );
  };
};
