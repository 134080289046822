import { Component } from 'react';

import Dialog from '../../../../../../components/Dialog';
import Button from '../../../../../../components/Button';

import './index.css';

export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    }
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({visible: false});
  }

  render() {
    return (
      <div>
        <Dialog close={this.onClick} visible={this.state.visible}>
          {
            <div className="flex-column flex-center">
              <div className="flex-column flex-center">
                <div className="help-text">For any additional help, please contact</div>
                <div className="help-email">help@baocommunications.com</div>
              </div>
              <Button className="help-btn-ok" colorSet="white" onClick={this.onClick}>OK</Button>
            </div>
          }
        </Dialog>
      </div>
    )
  }
}
