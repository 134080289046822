import { Component } from 'react';

import Card from '../../../../../../components/Card';
import Avatar from '../../../../../../components/Avatar';

import './index.css';

import request from '../../../../../../service/request';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../../../../../router/config';

class CollaborationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
    this.load = this.load.bind(this);
  }

  async load() {
    const { 
      data: 
			{
			  results
			} 
    } = await request(['collaboration.list', {
      query: {
        page: 1,
        page_size: 10
      }
    }]);
    this.setState({
      list: results
    });
  }

  async componentDidMount() {
    await this.load();
  }

  render() {
    const {
      state: {
        list
      },
      props: {
        history: {
          push
        }
      }
    } = this;
    const currentDate = new Date();
    return (
      list.map((each, i) => {
        const {
          id,
          product: {
            image_base_url = '',
            images = '',
            name = ''
          } = {},
          end_date
        } = each;
        const endDate = new Date(end_date);
        return (
          <Card
            key={i}
            prefix={<Avatar className="overview-card-avatar" avatar={`${image_base_url}${images.split(':')[0]}`} />}
            suffix={<div onClick={() => push(`${BRAND_PATH}/collaboration/${id}`)} className="overview-card-suffix">Details</div>}
            shadowed={false}
            className="brand-card"
          >
            <div className="overview-card flex-grow">
              <div className="overview-card-title">{name}</div>
              <div className="overview-card-status">{endDate > currentDate ? 'Open' : 'Done'}</div>
            </div>
          </Card>
        );
      })
    );
  }
}

export default withRouter(CollaborationList);