import { Component } from 'react';
import Button from '../../../../../../../../../../components/Button';
import ConfirmedDialog from '../../../../../../../../../../components/ConfirmedDialog';
import Avatar from '../../../../../../../../../../components/Avatar';

import './index.css';

import { assembleFullName } from '../../../../../../../../../../utils/util';

export default class  ConfirmOfferButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.changeVisible = this.changeVisible.bind(this);
  }

  changeVisible(forcedVisible) {
    const {
      state: {
        visible
      }
    } = this;
    this.setState({
      visible: forcedVisible === undefined ? !visible : forcedVisible
    });
  }

  render() {
    const {
      props: {
        className = '',
        rate = '',
        influencer: {
          avatar = '',
          avatar_base_url = '',
          first_name = '',
          last_name = ''
        } = {},
        submit
      },
      state: {
        visible
      },
      changeVisible
    } = this;

    return (
      <>
        <ConfirmedDialog
          className="confirmoffer-dialog"
          visible={visible}
          close={changeVisible}
          confirm={submit}
        >
          {
            <>
              <div className="confirmoffer-title">Confirm new offer: $ {rate} send to</div>
              <span className="confirmoffer-subtitle flex-row flex-center">
                <span><Avatar avatar={`${avatar_base_url}${avatar}`} className="confirmoffer-avatar" /></span
                ><span className="confirmoffer-title-underline">{assembleFullName(first_name, last_name)}</span>
              </span>
              <div className="confirmoffer-warning">
                You can only send new offer rate <span
                  className="confirmoffer-underline">ONCE</span>, sent rate cannot be undone.
              </div>
            </>
          }
        </ConfirmedDialog>
        <Button
          className={className}
          onClick={() => changeVisible(true)}
        >
          Send
        </Button>
      </>
    );
  }
}
