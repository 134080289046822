import { isNamedFunction } from './type';

export const isPrivate = (name) => name[0] === '_';

export const bindProperties = (obj) => {
  Object.keys(obj).forEach((each) => {
    const ele = obj[each];
    isNamedFunction(ele) && (obj[each] = ele.bind(obj));
    isPrivate(each) &&
      Object.defineProperty(obj, each, {
        configurable: false,
        writable: false,
        enumerable: false
      });
  });
};
