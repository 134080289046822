import { Component } from 'react';

import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog';

import request from '../../../../../../service/request';

import { PLATFORM } from '../../../../../../constants/platforms';

import './index.css';

export default class DraftApprovedComponet extends Component {
  constructor(props) {
    super(props);
    const {
      data: {
        collaboration: {
          quota          
        },
        social_links
      }
    } = props;
    let obj = Object.keys(quota);
    let quotaList = []
    obj.map((each) => {
      for(let i = 0; i < quota[each]; i++) {
        quotaList.push({name: each, key: each + i, value: social_links && social_links[i] && social_links[i].link || ''});
      }
    })
    this.state = {
      isShowDialog: false,
      quota,
      quotaList
    };
    this.confirm = this.confirm.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.updateParams = this.updateParams.bind(this);
  }

  updateParams(event) {
    const { quotaList } = this.state;
    let temp = quotaList;
    let name = event.target.name;
    let value = event.target.value;
    temp.map((each, key) => {
      if(each.key == name) {
        each.value = value;
      }
    })
    this.setState({quotaList: temp});
  }

  async confirm() {
    const { 
      state: {
        quotaList
      },
      props: {
        orderId,
        data: {
          next = new Function()
        }
      }
	 	} = this;
		 let socialLinks = [];
		 quotaList.map(each => {
			 socialLinks.push(each.value);
		 });
    const { data } = await request(['order.update.submit_social_links', {
      params: orderId,
      data: {
        social_links: socialLinks
      }
    }]);
    data && !data.detail && next({data, pass: true});
    data && !data.detail && this.openDialog();
  }

  openDialog() {
    this.setState({isShowDialog: true})
  }

  closeDialog() {
    this.setState({isShowDialog: false});
  }

  render() {
    const {
      state: {
        quotaList
      },
      props: {
        editable
      }
    } = this;

    return (
      <div className='flex-column flex-center'>
        <div className='draft-approve-comp-title'>
					Please Provide Your Social Media Links
        </div>
        <div className='flex-column flex-around-center draft-approve-comp-block'>
          {
            quotaList.map((each, key) => {
              return (
                <Input 
                  key={key}
                  label={PLATFORM[each.name].label}
                  name={each.key}
                  onChange={this.updateParams}
                  value={each.value}
                  labelClassName='draft-approve-comp-input-label'
                  className='draft-approve-comp-input'
                  placeholder="URL"
                  width="49.6vw"
                  height="4.7526vw"
                />
              );
            })
          }
          {/*<Input 
            label='Instagram'
            name='instagram'
            value={instagram}
            onChange={this.updateParams}
            labelClassName='draft-approve-comp-input-label'
            className='draft-approve-comp-input'
            placeholder="URL"
            width="49.6vw"
            height="4.7526vw"
          />
          <Input 
            label='TikTok'
            name='tikTok'
            value={tikTok}
            onChange={this.updateParams}
            labelClassName='draft-approve-comp-input-label'
            className='draft-approve-comp-input'
            placeholder="URL"
            width="49.6vw"
            height="4.7526vw"
          />*/}
          {
            editable && (
              <Button className="draft-approve-comp-btn" onClick={this.confirm}>Send To Brand</Button>
            )
          }
        </div>
        <Dialog
          close={this.closeDialog}
          visible={this.state.isShowDialog}
          className='flex-column flex-center'
        >
          {
            <div className='flex-column flex-center'>
              <div className='draft-approve-comp-dialog-confirm'>Your links has been send to the brand</div>
              <div className='draft-approve-comp-dialog-brand'>
								
              </div>
              <Button className="draft-approve-comp-dialog-btn" colorSet="white" onClick={this.closeDialog}>Back</Button>
            </div>
          }
        </Dialog>
      </div>
    );
  }
}
