import { Component } from 'react';

import SelectableImage from '../../../../../../../../components/SelectableImage';

import './index.css';

// import request from '../../../../../../../../service/request';
import { INTEREST_IMG_LIST } from '../../../../../../../../constants/interests';

export default class InterestList extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   list: [
    //     {
    //       image: require('../../../../../../../../assets/images/interest/interest-beauty.jpeg'),
    //       interest: 'beauty'
    //     },
    //     {
    //       image: require('../../../../../../../../assets/images/interest/interest-cosmetics.jpeg'),
    //       interest: 'cosmetics'
    //     },
    //     {
    //       image: require('../../../../../../../../assets/images/interest/interest-fashion.webp'),
    //       interest: 'fashion'
    //     },
    //     {
    //       image: require('../../../../../../../../assets/images/interest/interest-health.jpeg'),
    //       interest: 'health'
    //     },
    //     {
    //       image: require('../../../../../../../../assets/images/interest/interest-lifestyle.jpeg'),
    //       interest: 'lifestyle'
    //     }
    //   ]
    // };
    this.load = this.load.bind(this);
  }

  async load() {
    // const { list: [] } = await request(['', {
    //   data: {}
    // }]) || '';
  }

  render() {
    const {
      props: {
        data: {
          interests = ''
        }
      }
    } = this;

    const list = interests ? interests.split(':') : [];

    return (
      list.map((each, i) => {
        return (
          <div key={i} className="interest-card">
            <SelectableImage
              selected={false}
              image={(INTEREST_IMG_LIST[each] || {}).origin}
              label={each}
              plain={true}
            />
          </div>
        );
      })
    );
  }
}
