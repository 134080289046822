import { Component } from 'react';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Dialog from '../../../../components/Dialog';

import request from '../../../../service/request';

import './index.css';

//import request from '../../service/request';

//import { ROLE } from '../../store/user/auth';

export default class ForgotPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      otp: '',
      email: '',
      password: '',
      confirmedPassword: '',
      isShowDialog: false,
      otpErrMsg: ''
    };
    this.callback = props.callback
    this.updateParams = this.updateParams.bind(this);
    this.submit = this.submit.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setOTP = this.setOTP.bind(this);
  }

  updateParams(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({[name]: value});
  }

  setEmail() {
    const { email } = this.state;
    if(!email) return;
    request(['password_reset/.create', {
      data: {
        email
      }
    }]);
    this.setState({
      step: 1
    });
  }

  async setOTP(){
    const otp = this.state.otp
    if(!otp) return;
    const res = await request(['password_reset.create.validate_token/', {
      data: {
        token: otp
      }
    }]);
    if (res.status === 404) {
      this.setState({otpErrMsg: 'Please enter the correct OTP'})
      return
    }

    this.setState({
      step: 2
    });
  }

  submit() {
    const { password, confirmedPassword, opt } = this.state;
    if(!password || !confirmedPassword) return;
    request(['password_reset.create.confirm', {
      data: {
        password,
        token: opt
      }
    }]);
    this.setState({
      isShowForgotPwd: false,
      isShowDialog: true
    });
  }

  render() {
    const {
      state: {
        step,
        otp,
        email,
        password,
        confirmedPassword,
        isShowDialog
      }
    } = this;
    return (
      <div className="login-block flex-column flex-start-center" >
        <div className="signin-title">SET NEW PASSWORD</div>
        <div className="signin-subtitle">
          Please follow the instructions below
        </div>
        <div className="input-block">
          {
            (() => {
              switch(step) {
              case 0:
                return(
                  <div className='forgotpwd-email-block'>
                    <Input
                      label="Please Enter Your Email Address Associated With Your Account"
                      name='email'
                      value={email}
                      colorSet="gray"
                      className="input-email"
                      placeholder="Bcom@gmail.com"
                      onChange={this.updateParams}
                    />
                    <Button className="forgotpwd-btn-next" colorSet={email ? 'blue' : 'gray'} onClick={this.setEmail}>Next</Button>
                    <span style={{
                      marginLeft: '15px'
                    }} />
                    <Button className="forgotpwd-btn-next" colorSet={'white'} onClick={() => this.callback()}>Cancel</Button>
                  </div>
                );
              case 1:
                return(
                  <div>
                    <div className='forgotpwd-otp-title'>
                      To continue, complete this verification step. We&rsquo;ve sent a One Time Password(OTP) to your influencer email. Please enter it below.
                    </div>
                    <Input
                      label="Enter OTP"
                      name='otp'
                      value={otp}
                      colorSet="gray"
                      className="input-email"
                      placeholder="One Time Password"
                      onChange={this.updateParams}
                    />
                    <div style={{
                      color: '#f00',
                      marginBottom: '12px'
                    }}>
                      {this.state.otpErrMsg}
                    </div>
                    <Button className="forgotpwd-btn-next" colorSet={otp ? 'blue' : 'gray'} onClick={this.setOTP}>Next</Button>
                    <span style={{
                      marginLeft: '15px'
                    }} />
                    <Button className="forgotpwd-btn-next" colorSet={'white'} onClick={() => this.callback()}>Cancel</Button>
                  </div>
                );
              case 2:
                return(
                  <div>
                    <Input
                      label="Enter New Password"
                      name='password'
                      value={password}
                      colorSet="gray"
                      type="password"
                      className="input-password"
                      placeholder="password"
                      onChange={this.updateParams}
                      autoComplete='off'
                    />
                    <Input
                      label="Confirm New Password"
                      name='confirmedPassword'
                      value={confirmedPassword}
                      colorSet="gray"
                      type="password"
                      className="input-password"
                      placeholder="password"
                      onChange={this.updateParams}
                      autoComplete='off'
                    />
                    <Button className="forgotpwd-btn-next" colorSet={password && confirmedPassword ? 'blue' : 'gray'} onClick={this.submit}>Submit</Button>
                    <span style={{
                      marginLeft: '15px'
                    }} />
                    <Button className="forgotpwd-btn-next" colorSet={'white'} onClick={() => this.callback()}>Cancel</Button>
                  </div>
                );
              }
            })()
          }
        </div>
        <Dialog
          visible={isShowDialog}
          className="flex-column flex-center"
        >
          {
            <div className='flex-column flex-center'>
              <div className='flex-column flex-center singin-dialog-font'>
                <div>
                  Your Password Has Been Reset !
                </div>
                <div>
                  Redirect to login
                </div>
              </div>
            </div>
          }
        </Dialog>
      </div>
    );
  }
};
