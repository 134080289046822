import { Component } from 'react';
import './index.css';

export default class Select extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      name,
      label,
      className,
      type='text', //
      width = '29.68vw',
      height = '3.64vw',
      colorSet = 'white', //white,gray
      style = {},
      value,
      onChange,
      isRequire = false,
      isRed = false
    } = this.props;
    return(
      <div>
        <div className='flex-row flex-start-center'>
          {
            isRequire ?
              <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
              :
              <></>
          }
          {
            label ?
              <div className='label'>{label}</div>
              :
              <></>
          }
        </div>
        <div>
          <select
            name={name}
            label={label}
            value={value}
            className={`
						select ${className}
						${isRequire || isRed ?
        				value == '' ?
          ''
          				// 'input-color_red'
          			: `select-color_${colorSet}`
        			: '`select-color_${colorSet}`'
      				}`
            }
            type={type}
            onChange={onChange}
            style={{ ...{width, height},...style }}
          >
            {children}
          </select>
        </div>
      </div>
    );
  }
}
