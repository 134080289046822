import { Component } from 'react';

import SettingLayout from '../../../../layout/SettingLayout';
import BrandProfile from './components/BrandProfile';
import Language from './components/Language';
import ChangePassword from './components/ChangePassword';
import Notifications from './components/Notifications';
import Help from './components/Help';

//const menuList = [
//  [{ name: 'Language', selected: true }], 
//  [
//    { name: 'Brand Profile', selected: false }, 
//    { name: 'Change Password', selected: false }, 
//    { name: 'Notifications', selected: false }
//  ], 
//  [{ name: 'Help', selected: false }]
//];

export default class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: 'Language',
      menuList: [
        [{ name: 'Language', selected: true }], 
        [
          { name: 'Brand Profile', selected: false }, 
          { name: 'Change Password', selected: false }, 
          { name: 'Notifications', selected: false }
        ], 
        [{ name: 'Help', selected: false }]
      ]
    };
    this.onClickNextStep = this.onClickNextStep.bind(this);
    this.setMenulistSelected = this.setMenulistSelected.bind(this);
  }

  onClickNextStep(item) {
    this.setMenulistSelected(item);
    this.setState({option: item.name});
  }

  setMenulistSelected(item) {
    const { menuList } = this.state;	
    let arr = menuList;
    arr.map((each, j) => {
      each.map((obj, i) => {
        if(item.name === obj.name) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
      })
    });
    this.setState({menuList: arr});
  }

  render() {
    let children = new Function();
    const { option, menuList } = this.state;
    const { callback } = this.props;
    switch(option) {
    case 'Language':
      children = <Language />
      break;
    case 'Brand Profile':
      children = <BrandProfile callback={callback}/>
      break;
    case 'Change Password':
      children = <ChangePassword />
      break;
    case 'Notifications':
      children = <Notifications />
      break;
    case 'Help':
      children = <Help />
      break;
    default: 
      children = <Language />
      break;
    }
    return (
      <div> 
        <SettingLayout 
          setEffect={this.onClickNextStep} 
          menuList={menuList}
          isBack={false}
        >
          <div style={{marginLeft: '1.2369vw'}}>
            {children}
          </div>
        </SettingLayout>
      </div>
    );
  }
};
