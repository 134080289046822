import { Component } from 'react';

import CollabRequirements from '../CollabRequirements';
import Image from '../Image';

import { formatStatistics } from '../../utils/util';

import './index.css';
export default class PublishedCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { 
      props:
			 { 
			   className,
				 data: {
			     source: platform = '',
			     link = '',
			     //TO DO
			     image = '',
			     likes = '',
			     comments = '',
			     followers = ''
			     ////TO DO
				 }
			 }
    } = this;
    return (
      <div className={className}>
        <div>
          <CollabRequirements
            platform={platform}
            className="published-card-collab-requirements"
          >
            {
              <a href={link} target='_blank' className='published-card-collab-requirements-link' rel="noreferrer">Link</a>
            }
          </CollabRequirements>
        </div>
        <div className='published-card-collab-card-cantainer'>
          <Image 
            className='published-card-collab-card-img'
            image={image}
          />
          <div className='published-card-collab-card-block flex-column flex-center-start'>
            <div className="published-card-collab-card-context flex-row flex-start-center">
              <div className="published-card-collab-card-num flex-row">
                <div className="flex-grow"></div> 
                {formatStatistics(likes)}
              </div>
							Likes
            </div>
            <div className="published-card-collab-card-context flex-row flex-start-center">
              <div className="published-card-collab-card-num flex-row">
                <div className="flex-grow" />
                {formatStatistics(comments)}
              </div>
							Comments
            </div>
            <div className="published-card-collab-card-context flex-row flex-start-center">
              <div className="published-card-collab-card-num flex-row">
                <div className="flex-grow" />
                {formatStatistics(followers)}
              </div>
							Views
            </div>
            {/*<div className='flex-column flex-center'>
              <div className='published-card-collab-card-context flex-row flex-between-center'>
                <div className="flex-grow" />
                <div className='published-card-collab-card-num'>{likes}</div>
                <div className='published-card-collab-card-label'>Likes</div>
              </div>
            </div>
            <div className='flex-column flex-center' style={{marginTop: '0.9765vw'}}>
              <div className='published-card-collab-card-context flex-row flex-between-center'>
                <div className="flex-grow" />
                <div className='published-card-collab-card-num'>{comments}</div>
                <div className='published-card-collab-card-label'>Comments</div>
              </div>
            </div>
            <div className='flex-column flex-center' style={{marginTop: '0.9765vw'}}>
              <div className='published-card-collab-card-context flex-row flex-between-center'>
                <div className="flex-grow" />
                <div className='published-card-collab-card-num'>{followers}</div>
                <div className='published-card-collab-card-label'>Views</div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
