import { Component } from 'react';
import { Checkbox } from '@mui/material';
import Image from '../../../../../../../../../../components/Image';

import './index.css';

import { PLATFORM } from '../../../../../../../../../../constants/platforms';

const FILTERS = [
  {
    label: 'Platform Accounts',
    field: 'platformList'
  },
  {
    label: 'Number Of Followers',
    field: 'levelList'
  },
  {
    label: 'Sort By',
    field: 'sortByList'
  }
];

export default class FilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platformList: [
        {
          name: 'Instagram',
          value: 'instagram',
          checked: false
        },
        {
          name: 'TikTok',
          value: 'tiktok',
          checked: false
        },
        {
          name: 'Instagram Story',
          value: 'instagram_story',
          checked: false
        },
        {
          name: 'YouTube',
          value: 'youtube',
          checked: false
        }
      ],
      levelList: [
        {
          name: '0-1k',
          value: '0',
          checked: false
        },
        {
          name: '1k-5k',
          value: '1',
          checked: false
        },
        {
          name: '5k-10k',
          value: '2',
          checked: false
        },
        {
          name: '10k-100k',
          value: '3',
          checked: false
        },
        {
          name: '100k-1M',
          value: '4',
          checked: false
        },
        {
          name: '1M +',
          value: '5',
          checked: false
        }
      ],
      sortByList: [
        {
          name: 'Number of followers',
          value: 'followers',
          checked: false
        },
        {
          name: 'Number of likes',
          value: 'likes',
          checked: false
        },
        {
          name: 'Number of views',
          value: 'views',
          checked: false
        },
        {
          name: 'Date Applied',
          value: 'date',
          checked: false
        },
        {
          name: 'Alphabetical',
          value: 'alphabetical',
          checked: false
        },
        {
          name: 'Approved',
          value: 'approved',
          checked: false
        }
      ]
    }
  }

  render() {
    const { state } = this;
    return (
      <div className="full flex-column flex-center-start">
        {
          FILTERS.map((each, i) => {
            const { label, field } = each;
            return (
              <div key={i} className="flex-row flex-start-center">
                <div className="brandcollabfilter-title flex-row">
                  <div className="flex-grow"></div>
                  {label}
                </div>
                {
                  state[field].map((each, i) => {
                    const { name, value } = each;
                    const { icon } = PLATFORM[value.toLowerCase()] || {};
                    return (
                      <div key={i} className="flex-row flex-start-center">
                        <Checkbox></Checkbox>
                        {
                          icon && <Image
                            className='brandcollabfilter-icon'
                            image={icon}
                          />
                        }
                        <div className="brandcollabfilter-label">{name}</div>
                      </div>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}
