import { Component } from 'react';
import SectionTitle from '../../../../../../../../components/SectionTitle';
import FilterList from './components/FilterList';

import './index.css';

export default class Filter extends Component {
  render() {
    return (
      <div className="brandcollabfilter">
        <SectionTitle title="Influencer Work" />
        <div className="brandcollabfilter-content full-width">
          <FilterList />
        </div>
      </div>
    );
  }
}
