import { Component } from 'react';

import AllCategories from '../AllCategories';
import Select from '../../../../../../components/Select';
import FilterCheckBox from '../../../../../../components/FilterCheckBox';

import request from '../../../../../../service/request';

import './index.css';


export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandList: [],
      categories: [
        {
          name: 'Cosmetics',
          value: 'cosmetics',
          checked: false
        },
        {
          name: 'Fashion', 
          value: 'fashion', 
          checked: false
        },
        {
          name: 'Fitness', 
          value: 'fitness', 
          checked: false
        },
        {
          name: 'Nutrition', 
          value: 'nutrition', 
          checked: false
        },
        {
          name: 'Food',
          value: 'food',
          checked: false
        },
        {
          name: 'Beverages',
          value: 'beverages',
          checked: false
        },
        {
          name: 'Hair Care',
          value: 'hair care',
          checked: false
        },
        {
          name: 'Home',
          value: 'home',
          checked: false
        },
        {
          name: 'Garden',
          value: 'garden',
          checked: false
        },
        {
          name: 'Others',
          value: 'others',
          checked: false
        }
      ],
      platformList: [
        {
          icon: require('../../../../../../assets/images/logo/logo-instagram.webp'), 
          name: 'Instagram',
          value: 'instagram',
          checked: false
        },
        {
          icon: require('../../../../../../assets/images/logo/logo-tiktok.webp'), 
          name: 'TikTok',
          value: 'tiktok',
          checked: false
        },
        {
          icon: require('../../../../../../assets/images/logo/logo-instagram_story.webp'), 
          name: 'Instagram Story',
          value: 'instagram_story',
          checked: false
        },
        {
          icon: require('../../../../../../assets/images/logo/logo-youtube.webp'), 
          name: 'Youtube',
          value: 'youtube',
          checked: false
        }
      ],
      withList: [
        //{
        //  icon: require('../../../../../../assets/images/logo/logo-youtube.webp'), 
        //  name: 'With Promo Code',
        //  value: 'With Promo Code',
        //  checked: false
        //},
        //{
        //  icon: require('../../../../../../assets/images/logo/logo-youtube.webp'), 
        //  name: 'With Affiliate Link',
        //  value: 'With Affiliate Link',
        //  checked: false
        //}
      ],
      sortBy: ''
    }
    this.filterChecked = this.filterChecked.bind(this);
    this.selectSortBy = this.selectSortBy.bind(this);
  }

  filterChecked(event, type) {
    const {
      state: {
        brandList,
        categories,
        platformList,
        withList,
        sortBy
      },
      props: {
        queryCollab
      }
    } = this;
    let name = event.target.name;
    let category = event.target.outerText;
    switch(type) {
    case 'categories': 
      categories.map((each, i) => {
        if(each.name === category) {
          each.checked = !each.checked;
        } else {
          each.checked = false;
        }
      });
      this.setState({categories});
      break;
    case 'platformList': 
      platformList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({platformList});
      break;
    case 'brandList': 
      brandList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({brandList});
      break;
    case 'withList': 
      withList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({withList});
      break;
    }
    queryCollab({
      brandList,
      categories,
      platformList,
      withList,
      sortBy
    });
  }

  async queryBrand() {
    const {
      data
    } = await request(['brand.list', {
      query: {
        page: 1,
        page_size: 100
      }
    }]);
    data.map((each) => {
      each.image = each.avatar_base_url + each.avatar;
      each.checked = false;
    });
    this.setState({brandList: data});
  }

  selectSortBy(event) {
    const {
      state: {
        categories,
        brandList,
        platformList,
        withList
      },
      props: {
        queryCollab
      }
    } = this;
    this.setState({sortBy: event.target.value}, () => {
      queryCollab({
        categories,
        brandList,
        platformList,
        withList,
        sortBy: this.state.sortBy
      });
    });
  }

  async componentDidMount() {
    await this.queryBrand();
  }

  render() {
    const {
      state: {
        categories,
        brandList,
        platformList,
        withList,
        sortBy
      },
      props: {
        socialStatusList
      }
    } = this;
    return (
      <div>
        <div className="influencer-list-filter-categories">
          <AllCategories 
            categories={categories}
            onChange={event => this.filterChecked(event, 'categories')}
          />
        </div>
        <div className="influencer-list-filter-platform">
          <FilterCheckBox 
            scroll={true}
            onChange={event => this.filterChecked(event, 'brandList')}
            filterList={brandList} 
            label="Brands"
          />
        </div>
        <div>
          <FilterCheckBox 
            onChange={event => this.filterChecked(event, 'platformList')}
            filterList={(() => {
              let arr = [];
              for(let i = 0; i < socialStatusList.length; i++) {
                platformList.map(each => {
                  if(each.value == socialStatusList[i]) {
                    arr.push(each);
                  }
                })
              }
              return arr;
            })()} 
            label="Platform Accounts"
          />
        </div>
        <div>
          <FilterCheckBox 
            className="influencer-list-filter-with"
            onChange={event => this.filterChecked(event, 'withList')}
            filterList={withList} 
          />
        </div>
        <div className="influencer-list-filter-select">
          <Select value={sortBy} onChange={this.selectSortBy} height="3.05vw" width="12.04vw">
            <option style={{display: 'none'}}>Sort product by</option>
            <option value='start_date'>Start Date</option>
            <option value='end_date'>End Date</option>
          </Select>
        </div>
      </div>
    );
  }
}
