import { Component } from 'react';

import Container from '../Container';
import Input from '../../../../../../components/Input';
import Select from '../../../../../../components/Select';
import Button from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog';
import UploadImage from '../../../../../../components/UploadImage';

import { formatDate, calcDays, formatSSN } from '../../../../../../utils/util';

import request from '../../../../../../service/request';

import './index.css';


import { observer } from 'mobx-react';

import { RootStoreContext } from '../../../../../../store';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';

class Settings extends Component {
  static contextType = RootStoreContext;
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      country: '',
      gender: '',
      phoneNum: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      ssn: '',
      payRageStart: '',
      payRageEnd: '',
      avatar: '',
      baseUrl: '',
      isShowDeletDialog: false
    }
    this.updateParams = this.updateParams.bind(this);
    this.update = this.update.bind(this);
    this.deleteMyAccount = this.deleteMyAccount.bind(this);
    this.getInfluencerInfo = this.getInfluencerInfo.bind(this);
    this.getImg = this.getImg.bind(this);
  }

  async getInfluencerInfo() {
    const {
      influencer: {
        info: {
          avatar,
          baseUrl,
          firstName,
          lastName,
          email,
          birthYear,
          birthMonth,
          birthDay,
          country,
          gender,
          phoneNum,
          address,
          address2,
          city,
          state,
          postalCode,
          ssn,
          payRageStart,
          payRageEnd
        }
      }
    } = this.context;
    this.setState({
      avatar,
      baseUrl,
      firstName,
      lastName,
      email,
      birthYear,
      birthMonth,
      birthDay,
      country,
      gender,
      phoneNum,
      address,
      address2,
      city,
      state,
      postalCode,
      ssn,
      payRageStart,
      payRageEnd
    });
  }

  updateParams(event) {
    let name = event.target.name;
    this.setState({[name]: event.target.value});
  }

  async update() {
    let { 
      firstName,
      lastName,
      email,
      birthYear,
      birthMonth,
      birthDay,
      country,
      gender,
      phoneNum,
      address,
      address2,
      city,
      state,
      postalCode,
      ssn,
      payRageStart,
      payRageEnd,
      avatar
    } = this.state;
    if(firstName && lastName && email && birthYear && birthMonth && birthDay && country && gender && address && city && state && postalCode && ssn && payRageStart && payRageEnd) {
      let birthdate = formatDate(birthYear, birthMonth, birthDay);
      let params = {
        email,
        first_name: firstName,
        last_name: lastName,
        country,
        state,
        postal_code: postalCode,
        address,
        address2,
        city,
        birthdate,
        phone_number: phoneNum,
        ssn,
        avatar,
        pay_from: payRageStart,
        pay_to: payRageEnd,
        gender: gender
      //interests: this.state.interests
      }
      const { 
        data
      } = await request(['influencer.update', {data: params}]);
      const { influencer } = this.context; 
      influencer.setInfo({
        id: data.user.id,
        avatar: data.avatar,
        baseUrl: data.avatar_base_url,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.user.email,
        birthYear: data.birthdate.split('-')[0],
        birthMonth: data.birthdate.split('-')[1],
        birthDay: data.birthdate.split('-')[2],
        country: data.country,
        gender: data.gender,
        phoneNum: data.phone_number,
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        postalCode: data.postal_code,
        ssn: data.ssn,
        payRageStart: data.pay_from,
        payRageEnd: data.pay_to
      });
    }
  }

  async deleteMyAccount() {
    //await request(['user.delete']);
    this.setState({isShowDeletDialog: false});
  }

  getImg(id, data) {
    const { avatar, base_url } = data; 
    this.setState({
      avatar,
      baseUrl: base_url
    });
  }

  async componentDidMount() {
    await this.getInfluencerInfo()
  }

  render() {
    const { 
      state: {
        firstName,
        lastName,
        email,
        birthYear,
        birthMonth,
        birthDay,
        country,
        gender,
        //phoneNum,
        address,
        address2,
        city,
        state,
        postalCode,
        ssn,
        payRageStart,
        payRageEnd,
        avatar,
        baseUrl,
        isShowDeletDialog
      }
    } = this;
    return (
      <Container
        title="Settings"
        subtitle="Manage and edit your personal information"
      >
        <div className="setting-info">
          <div className='setting-info-upload flex-row flex-around-center'>
            <div className='setting-upload flex-column flex-center'>
              <UploadImage 
                id='influencer_logo'
                uploadTransId='profile.create.avatar'
                getImg={this.getImg}
                image={avatar ? `${baseUrl}${avatar}` : ''}
              />
              <div>
                <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
                <div className='setting-upload-font'>Click to change photo(Please upload image less than 2MB)</div>
              </div>
            </div>
            <div>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing = {1}
              >
                <Input 
                  isRequire='true'
							    name="lastName"
                  value={lastName}
                  label="Last Name"
                  width="40vw"
                  onChange={this.updateParams}
						 />
                <Input 
                  isRequire='true'
                  name="firstName"
                  value={firstName}
                  label="First Name"
                  width="40vw"
                  onChange={this.updateParams}
                />
              </Stack>
            </div>
          </div>
          <Grid
            container
            spacing={1}
          >
            {/*<Grid item xs={6}>
              <Input 
                isRequire='true'
                name="firstName"
                value={firstName}
                label="First Name"
                width="90.67vw" 
                height="3.38vw"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                isRequire='true'
							  name="lastName"
                value={lastName}
                label="Last Name"
							  width="90.67vw" 
							  height="3.38vw"
                onChange={this.updateParams}
						 />
            </Grid>*/}
            <Grid item xs={6}>
              <Input 
                isRequire='true'
                name="email"
                value={email}
                label="Email"
							  width="90.67vw" 
							  height="3.38vw"
                onChange={this.updateParams}
						 />
            </Grid>
            <Grid item xs={6}>
              <div className='flex-row flex-start-center'>
                <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
                <div className='setting-info-birth-date-font'>Birth Date(YYYY/MM/DD)</div>
              </div>
              <div className='flex-row flex-between-center'>
                {/*<Grid item xs={4}>*/}
                <Select
                  //isRequire='true'
                  isRed='true'
                  value= {birthYear}
                  name="birthYear"
                  width="8.3vw"
                  height="3.58vw"
                  onChange={this.updateParams}
                >
                  <option style={{display: 'none'}}></option>
                  {
                    (()=>{
                      let arr = [];
                      for(let i = 2022; i > 1950; i--){
                        arr.push(<option key={i} value={i}>{i}</option>);
                      }
                      return arr;
                    })()
                  }
                </Select>
                {/*</Grid>
              <Grid item xs={4}>*/}
                <Select
                  //isRequire='true'
                  isRed='true'
                  value={birthMonth}
                  name="birthMonth"
                  width="8.3vw"
                  height="3.58vw"
                  //style={{marginTop: '0.4vw'}}
                  onChange={this.updateParams}
                >
                  <option style={{display: 'none'}}></option>
                  {
                    (()=>{
                      let arr = [];
                      for(let i = 1; i <= 12; i++){
                        arr.push(<option key={i} value={i < 9 ? '0' + i : i + ''}>{i < 9 ? '0' + i : i + ''}</option>);
                      }
                      return arr;
                    })()
                  }
                </Select>
                {/*</Grid>
              <Grid item xs={4}>*/}
                <Select
                  //isRequire='true'
                  isRed='true'
                  value={birthDay}
                  name="birthDay"
                  width="8.65vw"
                  height="3.58vw"
                  //style={{marginTop: '0.4vw'}}
                  onChange={this.updateParams}
                >
                  <option style={{display: 'none'}}></option>
                  {
                    (()=>{
                      let arr = [];
                      let days = calcDays(this.state.birthYear, this.state.birthMonth);
                      for(let i = 1; i <= days; i++){
                        arr.push(<option key={i} value={i < 9 ? '0' + i : i + ''}>{i < 9 ? '0' + i : i + ''}</option>);
                      }
                      return arr;
                    })()
                  }
                </Select>
                {/*</Grid>*/}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
                name="country"
                value={country}
                label="Country"
                width="90.67vw" 
                height="3.38vw"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                isRequire='true'
                name="gender"
                label="Gender"
                width="35.9vw" 
                height="3.58vw"
                value={gender}
                onChange={this.updateParams}
              >
                <option style={{display: 'none'}}></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </Grid>
            {/*<Grid item xs={6}>
              <Input
							  name="phoneNum" 
                value={this.state.phoneNum}
                label="Phone Number"
                width="90.67vw" 
                height="3.38vw"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="setting-verify">Verify My Phone</div>
            </Grid>*/}
            <Grid item xs={6}>
              <Input
                isRequire='true'
                value={address}
                name="address"
                label="Address"
                width="90.67vw" 
                height="3.38vw"
                colorSet="white"
                onChange={this.updateParams} />
            </Grid>
            <Grid item xs={6}>
              <Input
                value={address2}
                name="address2"
                label="Address Line 2"
                width="90.67vw" 
                height="3.38vw"
                colorSet="white"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
                value={city}
                name="city"
                label="City"
                width="90.67vw" 
                height="3.38vw"
                colorSet="white"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                isRequire='true'
                value={state}
                name="state"
                label="State"
                width="12.69vw"
                height="3.38vw"
                colorSet="white"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                isRequire='true'
                value={postalCode}
                name="postalCode"
                label="Postal Code"
                width="13.34vw"
                height="3.38vw"
                colorSet="white"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
                value={formatSSN(ssn)}
                name="ssn"
                label="Social Security Number (SSN)"
                width="90.67vw" 
                height="3.38vw"
                colorSet="white"
                placeholder="xxx-xx-xxx"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={2}>
              <div className="flex-row flex-start-center">
                <AttachMoneyIcon sx={{mt: '1.3vw'}}/>
                <Input
                  isRequire='true'
                  value={payRageStart}
                  name="payRageStart"
                  label="Pay Range"
                  width="9.7656vw" 
                  height="3.38vw"
                  colorSet="white"
                  placeholder="xx-xxx-xxx"
                  onChange={this.updateParams}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <HorizontalRuleIcon sx={{ml: '3.9vw', mt: '2.4739vw'}}/>
            </Grid>
            <Grid item xs={3}>
              <div className="flex-row flex-center">
                <AttachMoneyIcon sx={{mt: '1.3vw'}}/>
                <Input
                  isRequire='true'
                  value={payRageEnd}
                  name="payRageEnd"
                  width="9.1145vw" 
                  height="3.38vw"
                  colorSet="white"
                  placeholder="xx-xxx-xxx"
                  onChange={this.updateParams}
                  style={{marginTop: '0.5vw'}}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="setting-text">
									SSN will be used to process payments, otherwise user cannot apply for any paid collaboration.
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="setting-text">
									Per post/story/video/etc. This will be shared with brands when applying for a collaboration.
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="flex-row flex-between-center">
          <Button 
            className="setting-update" 
            colorSet={ 
              firstName && lastName && email && birthYear && birthMonth && birthDay && country && gender && address && city && state && postalCode && ssn && payRageStart && payRageEnd ?
                'blue' : 'gray'
            }
            onClick={this.update}
          >
              Update
          </Button>
          {/*<div onClick={() => this.setState({isShowDeletDialog: true})} className="setting-delete">
						Delete My Account
          </div>*/}
        </div>
        <Dialog visible={isShowDeletDialog}>
          {
            <div className="flex-column flex-center">
              <div className="flex-column flex-center">
                <div className="help-text">For any additional help, please contact</div>
                <div className="help-email">help@baocommunications.com</div>
              </div>
              <Button className="help-btn-ok" colorSet="white" onClick={this.deleteMyAccount}>OK</Button>
            </div>
          }
        </Dialog>
      </Container>
    )
  }
};

export default observer(Settings);