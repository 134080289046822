import { Component } from 'react';

import './index.css';

export default class Fits extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { value, onClick, className } = this.props;
    let color = 'white';
    if(value.checked) {
      color = 'black'
    } else {
      color = 'white'
    }
    return (
      <div 
        onClick={onClick}
        className={`fits-container flex-row flex-center fits-color_${color} ${className}`}
      >
        {value.name}
      </div>
    );
  }
}