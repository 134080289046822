import { Component } from 'react';

import Container from '../../../Container';
import Button from '../../../../../../../../components/Button';
import Dialog from '../../../../../../../../components/Dialog';
import Image from '../../../../../../../../components/Image';

import './index.css';

const containerStyle = {
  width: '35.35vw',
  'min-height': '14.38vw',
  padding: '0'
}

export default class AccountCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
    this.add = this.add.bind(this);
    this.ok = this.ok.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  add() {
    this.setState({visible: true})
  }

  ok() {
    const { title, add, login  } = this.props;
    login().then((res) => {
      if(res) {
        add(title);
        this.setState({visible: false})
      }
    });
    //add(title);
    //this.setState({visible: false})
  }

  cancel() {
    this.setState({visible: false})
  }

  render() {
    const { title, info, style, href } = this.props;
    return (
      <div style={style}>
        <Container style={containerStyle}>
          <div className="account-card-container">
            <div className="account-card-title-block flex-row flex-between-center">
              <div>
                <div className="account-card-title">{title}</div>
              </div>
              <Button className="account-card-btn-add" colorSet="purple" onClick={this.add}>Add</Button>
            </div>
            <div>
              {
                info.map((each, key) => {
                  return (
                    <div key={key} className="account-card-info">{each}</div>
                  )
                })
              }
            </div>
          </div>
        </Container>
        <Dialog
          close={this.cancel}
          visible={this.state.visible}
          className='z-index-3'
        >
          {
            <div className="flex-column flex-center">
              <div className="flex-column flex-center account-card-dialog-text">
                <div className="">You will be redirect to {title}</div>
                <div className="">login to authorize</div>
              </div>
              <div className="flex-row flex-between-center">
                {
                  title == 'Instagram' ?
                    <Button className="account-card-btn-dialog" colorSet="white" onClick={this.ok}>
                      {
                        <div>
                          <Image
                            className="account-card-btn-dialog-icon"
                            image={require('../../../../../../../../assets/images/socialAccount/Instagram_logo_2016.svg.webp')}
                          />
                        </div>
                      }
                      Login with Instagram
                    </Button> : title == 'YouTube' ?
                      <a href={href} style={{textDecoration: 'none'}}>
                        <Button className="account-card-btn-dialog" colorSet="transparent" onClick={this.ok}>
                          {
                            <div>
                              <Image
                                className="account-card-btn-dialog-youtube"
                                image={require('../../../../../../../../assets/images/socialAccount/YouTube-Logo.wine.png')}
                              />
                            </div>
                          }
                        </Button>
                      </a> :
                      <a href={href} style={{textDecoration: 'none'}}>
                        <Button className="account-card-btn-dialog" colorSet="white" onClick={this.ok}>OK</Button>
                      </a>
                }
                <Button className="account-card-btn-dialog" colorSet="black" onClick={this.cancel}>Cancel</Button>
              </div>
            </div>
          }
        </Dialog>
      </div>
    )
  }
}
