import { Component } from 'react';

import './index.css';

export default class Counter extends Component {

  constructor(props = {}) {
    super(props);
    this.state = {
      counter: 0
    }

    this.increase = this.increase.bind(this);
    this.reduce = this.reduce.bind(this);
  }

  increase() {
    const { getCounter, platform } = this.props;
    let newCounter = this.state.counter + 1;
    this.setState({counter: newCounter});
    getCounter(platform, newCounter);
  }

  reduce() {
    const { getCounter, platform } = this.props;
    let newCounter = this.state.counter;
    if(this.state.counter > 0) {
      newCounter--;
      this.setState({counter: newCounter});
    }
    getCounter(platform, newCounter);
  }

  render() {
    return (
      <div className='counter-container flex-row flex-center'>
        <div>{this.state.counter}</div>
        <div className='arrow-block flex-column'>
          <div className='arrow-up' onClick={this.increase}></div>
          <div className='arrow-down' onClick={this.reduce}></div>
        </div>
      </div>
    );
  }
};
