import { Component } from 'react';

import Container from '../Container';
import Select from '../../../../../../components/Select';
import Button from '../../../../../../components/Button';

import Divider from '@mui/material/Divider';

import './index.css';

export default class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {language: 'English'};
    this.selectLanguage = this.selectLanguage.bind(this);
    this.save = this.save.bind(this);
  }

  selectLanguage(event) {
    this.setState({language: event.target.value});
  }

  save(){}

  render() {
    return (
      <Container
        title="Language Setting"
        subtitle="Select and manage your default language"
      >
        <div className="brand-language-container">
          <Divider sx={{mt: '0.651vw', mb: '2.6vw', borderColor: 'black'}}/>
          <div className='brand-language-text inline-block'>
            <div className='inline-block'>Default Language</div>
            <div className='inline-block brand-language-type'>{this.state.language}</div>
          </div>
          <Select width="10.09vw" value={this.state.language} onChange={this.selectLanguage}>
            <option value="English">English</option>
            <option value="Chinese">Chinese</option>
          </Select>
          <Button className='brand-language-btn-save' onClick={this.save}>Save</Button>
        </div>
      </Container>
    )
  }
}