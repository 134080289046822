import { Component } from 'react';
import MuiButton from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './index.css';

export default class Button extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      colorSet = 'blue',
      className = '',
      style = {},
      disabled,
      clickable = true,
      onClick = new Function()
    } = this.props;

    const theme = createTheme({
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none'
            }
          }
        }
      }
    });
    return (
      <ThemeProvider theme={theme}>
        <MuiButton
          className={`btn flex-unshrink btn-color_${colorSet}${clickable ? '' : '_unclickable'} ${className}`}
          disableRipple={true}
          onClick={onClick}
          disabled={disabled}
          sx={style}
        >
          {children}
        </MuiButton>
      </ThemeProvider>
    );
  }
};
