import { makeAutoObservable } from 'mobx';

class Brand {

  info = {
    id: '',
    name: '',
    website: '',
    primaryEmail: '',
    secondaryEmail: '',
    contactNumber: '',
    country: '',
    employeesNumber: '',
    marketBudgetYearly: '',
    companyName: '',
    avatarBaseUrl: '',
    avatar: '',
    position: '',
    industry: ''
  }
  
  constructor() {
    makeAutoObservable(this);
  }

  setInfo(info) {
    Object.assign(this.info, info);
  }
}

export default Brand;

////import { bindProperties } from '../../utils/object';

//export default function () {
//  const brand = {
//    id: '',
//    name: '',
//    website: '',
//    primaryEmail: '',
//    secondaryEmail: '',
//    contactNumber: '',
//    country: '',
//    employeesNumber: '',
//    marketBudgetYearly: '',
//    companyName: '',
//    avatar: '',
//    position: '',
//    industry: '',
//    getInfo: function () {
//      return this.info;
//    },
//    setInfo: function (info) {
//      return this.info = info;
//    }
//  };

//  //bindProperties(brand);

//  makeAutoObservable(brand);
//  return brand;
//}
