import { Component } from 'react';

import Tabs from '../../../../components/Tabs';
import Filter from '../../../../components/Filter';
import List from '../../../../components/List';
import CollabCard from '../../../../components/CollabCard';
import MakeCollabButton from '../../../../components/MakeCollabButton';
import LoadMore from '../../../../components/LoadMore';

import './index.css';

import { withRouter } from 'react-router';
import request from '../../../../service/request';

const TABS = [
  {
    label: 'ACTIVE COLLABORATION'
  },
  {
    label: 'PAST COLLABORATION'
  }
];

const STATUS = {
  0: 'open',
  1: 'done'
};

class Collaboration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      displayedList: [],
      page: 1,
      total: 0,
      tab: 0
    }
    this.load = this.load.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.searchCollab = this.searchCollab.bind(this);
    this.saveFunc = this.saveFunc.bind(this);
    this.deleteCollab = this.deleteCollab.bind(this);
  }

  async load() {
    const {
      state: {
        list,
        page,
        tab
      }
    } = this;
    const {
      data: {
        count,
        results = []
      } = {}
    } = await request(['collaboration.list', {
      query: {
        page,
        active: tab == 0
      }
    }]) || '';
    this.setState({
      list: list.concat(results),
      page: page + 1,
      total: count
    });
  }

  changeTab() {
    this.setState({
      tab: Math.abs(this.state.tab - 1),
      page: 1,
      list: []
    }, () => {
      this.load();
    });
  }

  saveFunc(data) {
    const { list } = this.state;
    this.setState({
      list: list.concat(new Array(data))
    });
  }

  async searchCollab(data) {
    const {
      platformList,
      //withList,
      sortBy
    } = data;
    let platform = [];
    platformList.map(each => {
      if(each.checked === true) {
        platform.push(each.value);
      }
    });
    const {
      data: {
        count,
        results = []
      }
    } = await request(['collaboration.list', {
      query: {
        page: 1,
        page_size: 10,
        platform_accounts: platform.join(','),
        order_by: sortBy,
        active: this.state.tab == 0
      }
    }]);
    this.setState({
      list: results,
      total: count
    });
  }

  deleteCollab(id) {
    const { list } = this.state;
    request(['collaboration.delete', {
      params: id
    }]).then(() => {
      this.setState({
        list: list.filter(each => each.id != id)
      });
    });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      state: {
        list = [],
        total,
        tab
      },
      load,
      changeTab
    } = this;
    const currentDate = new Date();

    return (
      <div className="brand-collab-list-container flex-column flex-start-between">
        <div className="width-inherit flex-row flex-between-center">
          <Tabs tabs={TABS} selected={tab} onClick={changeTab} />
          <MakeCollabButton
            label="Add New Collaboration"
            className="btn-addnewcollab"
            labelClassName="btn-addnewcollab-label"
            saveFunc={this.saveFunc}
          />
        </div>
        <div className="width-inherit flex-row scroll-y">
          <div className="brand-collab-list-filter flex-unshrink">
            <Filter 
              searchCollab={this.searchCollab}
            />
          </div>
          <div className="scroll">
            <List className="brand-collab-list flex-start">
              {
                list
                  .filter(each => {
                    const endDate = new Date(each.end_date);
                    return tab ? currentDate > endDate : currentDate <= endDate;
                  }).map((each, i) => (
                    <CollabCard
                      key={i}
                      className="brand-collab-list-item"
                      data={{ ...each, status: STATUS[tab], current_date: currentDate }}
                      deleteFunc={this.deleteCollab}
                    />
                  ))
              }
            </List>
            <div className="brand-collab-list-bottom width-inherit flex-row flex-center">
              {
                total > list.length
                  ? <LoadMore className="btn-loadmore-collablist" colorSet="blue" onClick={load} />
                  : <></>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(Collaboration);
