import { Component } from 'react';
import { withRouter } from 'react-router';

import SettingLayout from '../../../../layout/SettingLayout';
import SocialAccount from './components/SocialAccounts';
import Settings from './components/Settings';
import Language from './components/Language';
import ChangePassword from './components/ChangePassword';
import Interests from './components/Interests';
import Notifications from './components/Notifications';
import PhoneVerification from './components/PhoneVerification';
import Help from './components/Help';

import './index.css';

const menuList = [
  [{ name: 'Social Accounts', selected: true }], 
  [
    { name: 'Settings', selected: false }, 
    { name: 'Language', selected: false }, 
    { name: 'Change Password', selected: false }, 
    { name: 'Interests', selected: false }, 
    { name: 'Notifications', selected: false }
  ], 
  [{ name: 'Phone Verification', selected: false} ], 
  [{ name: 'Help', selected: false }]
];

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {option: this.props.location.params};
    this.onClickNextStep = this.onClickNextStep.bind(this);
    this.setMenulistSelected = this.setMenulistSelected.bind(this);
  }

  componentDidMount() {
    if(this.props.location.params) {
      this.setMenulistSelected(this.props.location.params);
      this.setState({option: this.props.location.params});
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.location.params != prevProps.location.params) {
      this.setMenulistSelected(this.props.location.params);
      this.setState({option: this.props.location.params});
    }
  }

  onClickNextStep(item) {
    this.setMenulistSelected(item.name);
    this.setState({option: item.name});
  }

  setMenulistSelected(item) {
    menuList.map((each, j) => {
      each.map((obj, i) => {
        if(item === obj.name) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
      })
    })
  }

  render() {
    let children = new Function();
    let { option } = this.state;
    switch(option) {
    case 'Social Accounts':
      children = <SocialAccount />
      break;
    case 'Settings': 
      children = <Settings />
      break;
    case 'Language':
      children = <Language />
      break;
    case 'Change Password':
      children = <ChangePassword />
      break;
    case 'Interests':
      children = <Interests />
      break;
    case 'Notifications':
      children = <Notifications />
      break;
    case 'Phone Verification':
      children = <PhoneVerification />
      break;
    case 'Help':
      children = <Help />
      break;
    default: 
      children = <SocialAccount />
      break;
    }
    return (
      <SettingLayout setEffect={this.onClickNextStep} menuList={menuList}>
        <div style={{marginLeft: '5.664vw'}}>
          {children}
        </div>
      </SettingLayout>
    );
  }
}

export default withRouter(Config);