import { Component } from 'react';
import './index.css';

export default class AllCategories extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { categories, onChange } = this.props;
    return (
      <div>
        <div className="influencer-list-categories-title">All Categories</div>
        {
          categories.map((each, i) => {
            return (
              <div 
                className={`influencer-list-categories-item ${each.checked ? 'influencer-list-categories-item-checked' : ''}`} 
                key={i}
                onClick={onChange}
              >
                {each.name}
              </div>
            )
          })
        }
      </div>
    )
  }
}