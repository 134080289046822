import { Component } from 'react';

import Container from '../Container';
import Input from '../../../../../../components/Input';
import Select from '../../../../../../components/Select';
import Button from '../../../../../../components/Button';
import UploadImage from '../../../../../../components/UploadImage';
import Dialog from '../../../../../../components/Dialog';

import request from '../../../../../../service/request';

import './index.css';

import Grid from '@mui/material/Grid';

import { observer } from 'mobx-react';

import { RootStoreContext } from '../../../../../../store';

//@observer
class BrandProfile extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      website: '',
      primaryEmail: '',
      secondaryEmail: '',
      contactNumber: '',
      country: '',
      employeesNumber: '',
      marketBudgetYearly: '',
      industry: '',
      avatarBaseUrl: '',
      avatar: '',
      visible: false
    }
    this.updateParams = this.updateParams.bind(this);
    this.update = this.update.bind(this);
    this.getImg = this.getImg.bind(this);
    this.getBrandInfo = this.getBrandInfo.bind(this);
  }

  updateParams(event) {
    let name = event.target.name;
    this.setState({[name]: event.target.value});
  }

  async getBrandInfo() {    
    const {
      brand: {
        info: {
          name,
          website,
          primaryEmail,
          secondaryEmail,
          contactNumber,
          country,
          employeesNumber,
          marketBudgetYearly,
          avatarBaseUrl,
          avatar,
          position,
          industry
        }
      }
    } = this.context;
    this.setState({
      name,
      website,
      primaryEmail,
      secondaryEmail,
      contactNumber,
      country,
      employeesNumber,
      marketBudgetYearly,
      industry,
      position,
      avatarBaseUrl,
      avatar
    });
  }

  async componentDidMount() {
    await this.getBrandInfo();
  }

  getImg(id, data) {
    const { avatar } = data; 
    this.setState({
      avatar
    });
  }

  async update() {
    const {
      state: {
        name,
        website,
        primaryEmail,
        contactNumber,
        country,
        employeesNumber,
        marketBudgetYearly,
        industry,
        position,
        avatar
      }
    } = this;

    if(name&&website&&primaryEmail&&contactNumber&&country&&employeesNumber&&marketBudgetYearly&&industry&&position&&avatar){
      const {
        state: {
          name,
          website,
          primaryEmail,
          secondaryEmail,
          contactNumber,
          country,
          employeesNumber,
          marketBudgetYearly,
          industry,
          avatar,
          position
        }
      } = this;
      let params = {
        email: primaryEmail,
        name,
        avatar,
        website,
        secondary_email: secondaryEmail,
        contact_number: contactNumber,
        country,
        position,
        industry,
        employees_number: employeesNumber,
        market_budget_yearly: marketBudgetYearly
      }
      const {
        data = {}
      } = await request(['brand.update', {data: params}]);
      const { brand } = this.context;
      data && !data.detail && brand.setInfo({
        id: data.user.id,
        name: data.name,
        website: data.website,
        primaryEmail: data.user.email,
        secondaryEmail: data.secondary_email,
        contactNumber: data.contact_number,
        country: data.country,
        employeesNumber: data.employees_number,
        marketBudgetYearly: data.market_budget_yearly,
        companyName: data.company_name,
        avatarBaseUrl: data.avatar_base_url,
        avatar: data.avatar,
        position: data.position,
        industry: data.industry
      });
      this.setState({
        visible: true
      });
    }
  }

  render() {
    const {
      state: {
        name,
        website,
        primaryEmail,
        secondaryEmail,
        contactNumber,
        country,
        marketBudgetYearly,
        industry,
        position,
        avatarBaseUrl,
        avatar,
        employeesNumber,
        visible
      }
    } = this;
    return (
      <Container
        title="Brand Profile"
        subtitle="Manage and edit brand information"
      >
        <div className="brand-profile-info">
          <Grid
            container
            spacing={1}
          >
            <Grid item xs={6}>
              <div>
                <div className='brand-profile-logo'>
                  <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
									Brand Logo(Please upload image less than 2MB)
                </div>
              </div>
              <UploadImage 
                id='brand_logo'
                uploadTransId='profile.create.avatar'
                getImg={this.getImg}
                image={avatar ? `${avatarBaseUrl}${avatar}` : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                isRequire='true'
							  name="website"
                value={website}
                label="Website"
							  width="25.78vw" 
                height="3.38vw"
                onChange={this.updateParams}
						 />
            </Grid>
            <Grid item xs={6}>
              <Input 
                isRequire='true'
                name="name"
                value={name}
                label="Brand Name"
                width="30.92vw" 
							  height="3.38vw"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                isRequire='true'
                name="primaryEmail"
                value={primaryEmail}
                label="Primary Contact Email"
							  width="25.78vw" 
							  height="3.38vw"
                onChange={this.updateParams}
						 />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="secondaryEmail"
                value={secondaryEmail}
                label="Secondary Contact Email"
							  width="30.92vw" 
							  height="3.38vw"
                onChange={this.updateParams}
						 />
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
                name="contactNumber"
                value={contactNumber}
                label="Primary Contact Number"
                width="25.78vw" 
                height="3.38vw"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
                name="country"
                value={country}
                label="Country"
                width="30.92vw" 
                height="3.38vw"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                isRequire='true'
                name="employeesNumber"
                label="Number of Employees"
                width="27.66vw" 
                height="3.58vw"
                value={employeesNumber}
                onChange={this.updateParams}
              >
                <option style={{display: 'none'}}></option>
                <option value="00">{'<50'}</option>
                <option value="01">{'50-200'}</option>
                <option value="02">{'200-500'}</option>
                <option value="03">{'500+'}</option>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
							  name="marketBudgetYearly" 
                value={marketBudgetYearly}
                label="Market Budget Yearly"
                width="30.92vw" 
                height="3.38vw"
                onChange={this.updateParams}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
                value={industry}
                name="industry"
                label="Industry"
                width="25.78vw" 
                height="3.38vw"
                onChange={this.updateParams} />
            </Grid>
            <Grid item xs={6}>
              <Input
                isRequire='true'
                value={position}
                name="position"
                label="Position"
                width="30.92vw"
                height="3.38vw"
                onChange={this.updateParams} />
            </Grid>
          </Grid>
        </div>
        <div className="flex-row flex-between-center">
          <Button 
            className="brand-profile-update"
            onClick={this.update}
            colorSet={
              name&&website&&primaryEmail&&contactNumber&&country&&employeesNumber&&marketBudgetYearly&&industry&&position&&avatar ?
                'blue' : 'gray'
            }
          >
						Save
          </Button>
        </div>
        <Dialog
          className='flex-column flex-center'
          visible={visible}
        >
          {
            <div className='flex-column flex-center'>
              <div className='brand-profile-update-dialog-text'>Brand Profile Has Been Updated!</div>
              <Button onClick={() => {this.setState({visible: false})}} className='brand-profile-update-btn' colorSet='white'>OK</Button>
            </div>
          }
        </Dialog>
      </Container>
    )
  }
};

export default observer(BrandProfile);