import { Component } from 'react';

//import './index.css';

import { formatDate } from '../../../../../../../../utils/util';

import request from '../../../../../../../../service/request';

import { withRouter } from 'react-router';
class CommentList extends Component {
  constructor(props) {
    super(props);
    const { id = '' } = props.match.params;
    this.state = {
      id,
      list: []
    };
    this.load = this.load.bind(this);
  }

  async load() {
    const { id } = this.state;
    const { 
      data: {
        results = []
      } 
    } = await request([`influencer.read.${id}.comment`]);
    this.setState({
      list: results
    });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      state: {
        list = []
      },
      props: {
        className
      }
    } = this;

    return (
      (list || []).map((each, i) => {
        const { 
          extra: {
            brand_title, 
            brand_name
          }, 
          text, 
          created_date 
        } = each;
        const date = new Date(created_date);
        return (
          <div key={i} className={`comment-card flex-column bg_white ${className}`}>
            <div className="flex-row">
              {/*<div className="comment-username">{sender}</div>*/}
              <div className="comment-position flex-grow">{brand_title}</div>
              <div className="comment-brandname">{brand_name}</div>
            </div>
            <div className="comment-content">{text}</div>
            <div className="comment-date flex-row-reverse width-inherit">
              {formatDate(date.getFullYear(), date.getMonth(), date.getDay())}
            </div>
          </div>
        );
      })
    );
  }
}

export default withRouter(CommentList);