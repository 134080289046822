const INTEREST_IMG_TYPE = [
  'FASHION',
  'SKIN CARE',
  'BEAUTY',
  'MAKEUP',
  'COSMETICS',
  'CLOTHES',
  'ART',
  'CULTURE',
  'HEALTH',
  'FITNESS',
  'NUTRITION',
  'TECHNOLOGY',
  'HOME',
  'LIFE STYLE',
  'WELL BEING'
];

export const INTEREST_IMG_LIST = INTEREST_IMG_TYPE.map(each => ({
  label: each,
  image: require(`../../../../../../assets/images/interest/interest-${each.toLowerCase().split(' ').join('')}.jpeg`)
}));

export function getInterestImgList(type = []) {
  let arr = [];
  type.map(each => {
    if(INTEREST_IMG_TYPE.includes(each.toLocaleUpperCase())) {
      arr.push({
        label: each,
        image: require(`../../../../../../assets/images/interest/interest-${each.toLowerCase().split(' ').join('')}.jpeg`)
      })
    }
  });
  return arr;
}

export const ACCOUNT_TAB_LIST = [
  {
    title: 'Instagram Story', 
    avatar:  require('../../../../../../assets/images/socialAccount/img1.jpg'),
    userName: 'Blair Bao',
    disabled: false,
    active: true,
    login: (e) => {
      window.FB.login(function(response){
        window.FB.api('/me', function(response) {
        });
      });
      e.preventDefault()
    },
    logout: () => {
      window.FB.logout(function(response) {
		 });
    }
  },
  {
    title: 'Youtube', 
    avatar:  require('../../../../../../assets/images/socialAccount/img1.jpg'),
    userName: 'Blair Bao',
    disabled: true,
    active: false,
    href: `https://api.baocommunications.com/api/youtube/oauth?from=${location.href}`,
    login: () => {
      //await request(['youtube/oauth.read']);
    }
  }
]

export const ACCOUNT_CARD_LIST = [
  {title: 'Instagram', info: ['1000+ followers', '2% engagement rate', 'Relavent and consistent content', 'Public account', 'Authenticity of audience']}, 
  {title: 'Youtube', info: ['1000+ subscribers', 'Minimum average of 2000 views for the last 5 videos', 'Relavent and consistent content', 'Active channel', 'Authenticity of audience']},
  {title: 'Tiktok', info: ['1000+ followers', '2% engagement rate', '500 average views per post', 'Relavent and consistent content', 'Public account', 'Authenticity of audience']}, 
  {title: 'Instagram Story', info: ['1000+ followers', '500 average retweets per post', '500 average views per post', 'Relavent and consistent content', 'Public account', 'Authenticity of audience']}
]