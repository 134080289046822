import { Component } from 'react';

import Avatar from '../Avatar';
import Button from '../Button';

import './index.css';

export default class FollowCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followStatus: false // false: Follow; true : Following
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({followStatus: !this.state.followStatus});
  }

  render() {
    const {
      props: {
        description = 'Follow the brand to be the first to know when new products become available!',
        image_base_url = '',
        image = '',
        data: {
          avatar_base_url = '',
          avatar = '',
          name
        } = {}
      },
      state: {
        followStatus
      }
    }  = this;
    return (
      <div className='follow-card-container'>
        <div className="follow-card-avatar-container flex-column flex-center">
          <Avatar
            avatar={`${image_base_url}${image}`}
            className="follow-card-avatar"
            shape="cubic"
          />
          <Avatar
            avatar={`${avatar_base_url}${avatar}`}
            className="follow-card-inner-avatar z-index-1"
            shape="circle"
          />
        </div>
        <div className='flex-column flex-center'>
          <div className="follow-card-category">
            {name}
          </div>
          <div className='follow-card-category-description'>
            {description}
          </div>
          <Button 
            colorSet=''
            onClick={this.onClick}
            className={`follow-card-btn ${followStatus ? 'follow-card-btn-purple' : 'follow-card-btn-white'}`}
          >
            {followStatus ? 'Following' : 'Follow'}
          </Button>
        </div>
      </div>
    );
  }
}
