import { Component } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import request from '../../service/request';

import './index.css';
export default class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.uploadImage = this.uploadImage.bind(this);
    this.remove = this.remove.bind(this);
    this.state = {
      hasImg: false
    }
  }

  async uploadImage() {
    const { id, getImg, uploadTransId } = this.props;
    let reads = new FileReader();
    let file = document.getElementById(`input_${id}`).files[0];
    const {
      data
    } = await request([
      uploadTransId,
      {
        data: {
          image_upload: file
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    	}
    ]);
    reads.readAsDataURL(file);
    reads.onload = (e) => {
      document.getElementById(`img_${id}`).src = e.target.result;
      this.setState({hasImg: true})
      getImg(id, data);
    };
  }

  remove() {
    const { id, getImg } = this.props;
    document.getElementById(`img_${id}`).src = '';
    this.setState({hasImg: false})
    getImg(id, '');
  }

  render() {
    const { id } = this.props;
    return (
      <div className="upload-image-container flex-row flex-center">
        {
          this.state.hasImg ?
            <div
              className='upload-image-remove flex-row flex-center z-index-3'
              onClick={this.remove}
            >
					 		<RemoveIcon />
				 		</div>
            :
            null
        }
        <input
          id={`input_${id}`}
          type="file"
          accept="image/png, image/jpeg"
          style={{opacity: '0'}}
          className='upload-image-input absolute z-index-3'
          onChange={() => {
            this.uploadImage(this);
          }}
        />
        <AddIcon sx={{width: '6.5755vw', height: '6.5755vw', color: 'rgb(153, 153, 153)'}}/>
        <img className='upload-image-img absolute z-index-2' id={`img_${id}`}/>
      </div>
    );
  }
}
