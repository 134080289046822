import { Component } from 'react';

import './index.css';

export default class Bubble extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { visible, title, colorSet, className, style, children } = this.props;
    return (
      visible ?
        <div className={`bubble-container ${className}`} style={{...style}}>
          <div className={`bubble-title bubble-title_${colorSet} flex-row flex-center`}>{title}</div>
          <div className="bubble-block">
            {
              children
            }
          </div>
        </div>
        :
        null
    );
  }
}