import { Component } from 'react';

import Dialog from '../../../Dialog';
import Select from '../../../Select';
import Input from '../../../Input';
import DatePicker from '../../../DatePicker';
import CollabRequirements from '../../../CollabRequirements';
import Button from '../../../Button';
import Fits from '../../../Fits';

import request from '../../../../service/request';

import { PLATFORM } from '../../../../constants/platforms';

import Slider from '@mui/material/Slider';

import './index.css';

const style = {
  width: '77.4vw',
  height: '39.06vw'
}

export default class MakeCollaboration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: '',
      productId: '',
      productList: [],
      page: 1,
      total: 0,
      quota: {},
      startDate: new Date(),
      endDate: new Date(),
      maxApplication: 300,
      preferredNumFollower: 60,
      fits: [
        {
          name: 'Cosmetics',
          checked: false
        },
        {
          name: 'Fashion',
          checked: false
        },
        {
          name: 'Fitness',
          checked: false
        },
        {
          name: 'Nutrition',
          checked: false
        },
        {
          name: 'Food',
          checked: false
        },
        {
          name: 'Beverages',
          checked: false
        },
        {
          name: 'Hair Care',
          checked: false
        },
        {
          name: 'Home',
          checked: false
        },
        {
          name: 'Garden',
          checked: false
        },
        {
          name: 'Others',
          checked: false
        }
      ],
      sameAsProductCategory: {
        name: 'Same as product category',
        checked: false
      }
    }
    this.updateParams = this.updateParams.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.openToAll = this.openToAll.bind(this);
    //this.setNumber = this.setNumber.bind(this);
    this.selectFits = this.selectFits.bind(this);
    this.selectSameAsProductCategory = this.selectSameAsProductCategory.bind(this);
    this.save = this.save.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.getCounter = this.getCounter.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    this.getProducts();
  }

  async getProducts() {
    const { 
      state: {
        productList,
        page,
        total
      },
      props: {
        product = {}
      }
    } = this;
    if(JSON.stringify(product) != '{}') {
      this.setState({
        productList: productList.concat([product])
      });
    } else {
      const {
        data: {
          count,
          results = []
        } = {}
      } = await request(['product.list', {
        query: {
          page,
          page_size: 100
        }
      }]);
      results&&this.setState({
        productName: productList[0],
        productList: productList.concat(results),
        page: page + 1,
        total: count || total
      });
    }
  }

  updateParams(event) {
    let name = event.target.name;
    let value = event.target.value;
    if(name == 'productName') {
      this.setState({
        productName: event.target.selectedOptions[0].text,
        productId: value
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleChange(event, newValue) {
    let name = event.target.name;
    this.setState({[name]: newValue});
  }

  //openToAll() {
  //  this.setState({maxApplication: 1000});
  //}

  //setNumber() {}

  getCounter(platform, count) {
    this.setState({
      quota: Object.assign(this.state.quota, {[platform.toLowerCase()]: count})
    });
  }

  selectFits(event) {
    let name = event.target.innerText;
    let arr = this.state.fits;
    arr.map((each, i) => {
      if(each.name === name) {
        each.checked = !each.checked
      } else {
        each.checked = false;
        let obj = {
          name: 'Same as product category',
          checked: false
        }
        this.setState({sameAsProductCategory: obj});
      }
    });
    this.setState({fits: arr});
  }

  selectSameAsProductCategory() {
    let obj = {
      name: 'Same as product category',
      checked: !this.state.sameAsProductCategory.checked
    }
    this.setState({sameAsProductCategory: obj});
    let arr = this.state.fits;
    arr.map((each, i) => {
      each.checked = false;
    });
    this.setState({fits: arr});
  }

  async save() {
    const {
      state: {
        productId,
        startDate,
        endDate,
        requirements,
        quota,
        preferredNumFollower,
        maxApplication,
        fits,
        sameAsProductCategory
      },
      props: {
        closeDialog,
        saveFunc
      }
    } = this;
		
    for(let item in quota) {
      if(quota[item] == '' || quota[item] == 0 || quota[item] == null) {
        delete quota[item]
      }
    }
    if(productId && startDate && endDate && requirements && quota && preferredNumFollower && maxApplication && fits && sameAsProductCategory){
      let fit = '';
      if(sameAsProductCategory.checked) {
        fit = sameAsProductCategory.name;
      } else {
        fits.map((each, i) => {
          if(each.checked == true) {
            fit = each.name;
          }
        })
      }
      const { data } = await request(['collaboration.create', {
        data: {
          product: productId,
          start_date: startDate,
          end_date: endDate,
          quota,
          requirements,
          preferred_num_follower: preferredNumFollower,
          max_application: maxApplication,
          target_influencer_group: fit
        }
      }]);
      data && !data.detail && saveFunc && saveFunc(data);
      data && !data.detail && closeDialog();
    }
  }

  setDate(dates) {
    const [start, end] = dates;
    this.setState(
      {
        startDate: start,
        endDate: end
      }
    );
  }

  render() {
    const { 
      state: {
        startDate,
        endDate,
        requirements,
        productList,
        maxApplication,
        preferredNumFollower,
        sameAsProductCategory,
        productId,
        quota,
        fits
      },
      props: {
        visible,
        closeDialog
      }
    } = this;
    return (
      <div>
        <Dialog
          showBack={true}
          style={style}
          visible={visible}
          close={closeDialog}
        >
          {
            <div className="scroll-y make-collaboration-container">
              <div className="make-collaboration-select-block">
                <div className="make-collaboration-text make-collaboration-select-text">
									Select product you would like to make collaboration:
                </div>
                <Select
                  value= {productId}
                  name="productName"
                  onChange={this.updateParams}
                >
                  <option style={{display: 'none'}}></option>
                  {
                    productList.map((each, i) => (
                      <option key={i} value={each.id}>{each.name}</option>
                    ))
                  }
                </Select>
              </div>
              <div className="make-collaboration-period">
                <div className="make-collaboration-text">
									Choose collaboration period(Select start date and end date)
                </div>
                <div className='flex-row flex-center'>
                  <DatePicker 
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.setDate}
                  />
                </div>
              </div>
              <div className="make-collaboration-requirements">
                <div className="make-collaboration-text">
									Please add collaboration requirements
                </div>
                <div className='flex-column flex-center'>
                  <div className='make-collaboration-requirements-block flex-row flex-start-center flex-wrap'>
                    {
                      Object.keys(PLATFORM).map((each, i) => {
                        return (
                          //<div key={i} className='make-collaboration-requirements-item'>
                          //  <CollabRequirements getCounter={this.getCounter} editable={true} platform={each}/>
                          //</div>
                          <div key={i} className='make-collaboration-requirements-item'>
                            <CollabRequirements platform={each}>
                              {
                                <div>
                                  <Input
                                    type='text'
                                    className='make-collaboration-requirements-counter-num'
                                    width='2vw'
                                    height='2vw'
                                    value={this.state.quota[each.toLowerCase()] || 0}
                                    onChange={event => {
                                      //只能输入数字字符
                                      let val = event.target.value.replace(/[^\d]/g, '');
                                      let length = val.length;
                                      val = event.target.value.replace(/[^\d]/g, '').replace(/^0+/, '');
                                      // 全为 0 的情况，留一个 0
                                      if(val.length === 0 && length > 0) {
                                        val = 0;
                                      }
                                      this.setState({
                                        quota: Object.assign(this.state.quota, {[each.toLowerCase()]: parseInt(val)})
                                      })
                                    }}
                                  />
                                </div>
                              }
                            </CollabRequirements>
                          </div>
                        )
                      })
                    }
                  </div>
                  <textarea
                    name='requirements'
                    value={requirements}
                    onChange={this.updateParams}
                    placeholder='Collaboration Requirements'
                    className="make-collaboration-requirements-textarea"
                  />
                </div>
              </div>
              <div className='make-collaboration-apply'>
                <div className="make-collaboration-text">
									Please set a preferred number of followers of the influencer to apply
                </div>
                <div className='flex-column flex-center'>
                  {/*<div className='make-collaboration-apply-num'>{maxApplication} +</div>*/}
                  <Input 
                    className='make-collaboration-apply-num'
                    width='8vw'
                    onChange={event => this.setState({maxApplication: event.target.value})}
                    value={maxApplication}
                  />
                  <Slider
                    name="maxApplication"
                    value={maxApplication}
                    onChange={this.handleChange}
                    defaultValue={maxApplication}
                    valueLabelDisplay="auto"
                    max={1000}
                    min={0}
                    sx={{width: '18.22vw', color: '#009aff'}}
                  />
                  <div className='make-collaboration-apply-btn-group flex-row flex-around'>
                    <Button
                      className="make-collaboration-apply-btn"
                      onClick={() => this.setState({maxApplication: 1000})}
                      colorSet="white"
                    >
											Open to all
                    </Button>
                    {/*<Button
                      className="make-collaboration-apply-btn"
                      onClick={this.setNumber}
                      colorSet="black"
                    >
											Set a number
                    </Button>*/}
                  </div>
                </div>
              </div>
              <div className="make-collaboration-maximum">
                <div className="make-collaboration-text">
									Please set maximum of application for collaboration
                </div>
                <div className='flex-column flex-center'>
                  {/*<div className='make-collaboration-maximum-num'>{preferredNumFollower} +</div>*/}
                  <Input 
                    className='make-collaboration-maximum-num'
                    width='8vw'
                    onChange={event => this.setState({preferredNumFollower: event.target.value})}
                    value={preferredNumFollower}
                  />
                  <Slider
                    name="preferredNumFollower"
                    value={preferredNumFollower}
                    onChange={this.handleChange}
                    defaultValue={preferredNumFollower}
                    valueLabelDisplay="auto"
                    max={1000}
                    min={1}
                    sx={{width: '18.22vw', color: '#ff33e7'}}
                  />
                  <div className='make-collaboration-apply-btn-group flex-row flex-around'>
                    <Button
                      className="make-collaboration-apply-btn"
                      onClick={ () => this.setState({preferredNumFollower: 1000})}
                      colorSet="white"
                    >
											Open to all
                    </Button>
                    {/*<Button
                      className="make-collaboration-apply-btn"
                      onClick={this.setNumber}
                      colorSet="black"
                    >
											Set a number
                    </Button>*/}
                  </div>
                </div>
              </div>
              <div>
                <div className="make-collaboration-text">
									Please select target influencer groups
                </div>
                <div className='flex-row flex-center'>
                  <div className="make-collaboration-fits flex-row flex-start flex-wrap">
                    {
                      this.state.fits.map((each, i) => {
                        return <Fits key={i} onClick={this.selectFits} value={each} />
                      })
                    }
                  </div>
                </div>
                <Fits
                  onClick={this.selectSameAsProductCategory}
                  value={sameAsProductCategory}
                  className='make-collaboration-fits-sameAsProductCategory'
                />
              </div>
              <Button
                className='make-collaboration-btn-save'
                onClick={this.save}
                colorSet={
                  productId && startDate && endDate && requirements && quota && preferredNumFollower && maxApplication && fits && sameAsProductCategory 
                    ? 'blue' : 'gray'
                }
              >
								Save & Notify Influencers
              </Button>
            </div>
          }
        </Dialog>
      </div>
    );
  }
}
