import { Component } from 'react';

import Container from '../Container';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import './index.css';

export default class PhoneVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPwd: '',
      newPwd: '',
      confirmPwd: ''
    };
    this.updateParams = this.updateParams.bind(this);
    this.update = this.update.bind(this);
  }

  updateParams(event) {
    const name = event.target.name;
    this.setState({[name]: event.target.value});
  }

  update(){}

  render() {
    return (
      <Container
        title="Phone Verification"
        subtitle="Manage and edit your phone number"
      >
        <div className="phone-container">
          <Divider sx={{mt: '0.651vw', mb: '7.64vw', borderColor: 'black'}}/>
          <Stack
            direction="column"
            spacing = {3}
          >
            <div className="flex-row flex-start-center">
              <Input
                name="phoneNum"
                value={this.state.phoneNum}
                label="Phone Number"
                placeholder="xxx-xxx-xxxx"
              />
              <Button className='phone-btn-verify' colorSet='white' onClick={this.verify}>Verify</Button>
            </div>
            <Input
              name="zipCode"
              value={this.state.zipCode}
              label="Please Enter 6 Digits Verification Code"
              placeholder="xxxxxx"
            />
          </Stack>
          <Button className='phone-btn-confirm' onClick={this.confirm}>Confirm</Button>
        </div>
      </Container>
    )
  }
}