import { Component } from 'react';

import CollabCard from './components/CollabCard';
import Filter from './components/Filter';
import BrandList from './components/BrandList';

import request from '../../../../service/request';

import { INFLUENCER_PATH } from '../../../../router/config';

import './index.css';

export default class Collab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '', // product name query text
      page: 1, // pagination page number, optional, default 1
      pageSize: 10, // pagination size, optional, default 10
      list: [],
      total: 0,
      brandList: [],
      brandSelected: 0,
      socialStatusList: [],
      platformList: [
        {
          icon: require('../../../../assets/images/logo/logo-instagram.webp'),
          name: 'Instagram',
          value: 'instagram',
          checked: false
        },
        {
          icon: require('../../../../assets/images/logo/logo-tiktok.webp'),
          name: 'TikTok',
          value: 'tiktok',
          checked: false
        },
        {
          icon: require('../../../../assets/images/logo/logo-instagram_story.webp'),
          name: 'Instagram Story',
          value: 'instagram_story',
          checked: false
        },
        {
          icon: require('../../../../assets/images/logo/logo-youtube.webp'),
          name: 'YouTube',
          value: 'youtube',
          checked: false
        }
      ],
      payRateList: [
        {
          name: '0-100',
          value: '0-100',
          checked: false
        },
        {
          name: '100-200',
          value: '100-200',
          checked: false
        },
        {
          name: '200-300',
          value: '200-300',
          checked: false
        },
        {
          name: '300-400',
          value: '300-400',
          checked: false
        },
        {
          name: '400-500',
          value: '400-500',
          checked: false
        },
        {
          name: '500-600',
          value: '500-600',
          checked: false
        },
        {
          name: '600-1000',
          value: '600-1000',
          checked: false
        },
        {
          name: '1000+',
          value: '1000-',
          checked: false
        }
      ],
      collabStatusList: [
        {
          name: 'Start',
          value: 'Start',
          checked: false
        },
        {
          name: 'Selected',
          value: 'Selected',
          checked: false
        },
        {
          name: 'Rate Confirmed',
          value: 'RateConfirmed',
          checked: false
        },
        {
          name: 'Product Received',
          value: 'ProductReceived',
          checked: false
        },
        {
          name: 'Draft Approved',
          value: 'DraftApproved',
          checked: false
        },
        {
          name: 'Published',
          value: 'Published',
          checked: false
        }
      ],
      sortByList: [
        {
          name: 'Date Created',
          value: 'created_date',
          checked: false
        },
        {
          name: 'Alphabetical',
          value: 'collaboration__product__name',
          checked: false
        },
        {
          name: 'End Date',
          value: 'end_date',
          checked: false
        },
        {
          name: 'Pay Rate',
          value: 'rate',
          checked: false
        }
      ]
    }
    this.filterChecked = this.filterChecked.bind(this);
    this.queryBrand = this.queryBrand.bind(this);
    this.queryOrder = this.queryOrder.bind(this);
    this.searchOrder = this.searchOrder.bind(this);
    this.changeBrand = this.changeBrand.bind(this);
    this.getSocialStatus = this.getSocialStatus.bind(this);
  }

  async getSocialStatus() {
    const { data } = await request(['social.read.status']);
    let list = [];
    for(let item in data) {
      if(data[item]) {
        list.push(item);
      }
    }
    return list;
  }

  async queryBrand() {
    const {
      data
    } = await request(['brand.list', {
      query: {
        page: 1,
        page_size: 16
      }
    }]);
    data.map((each) => {
      each.image = each.avatar_base_url + each.avatar;
    });
    this.setState({brandList: data});
  }

  async searchOrder() {
    const {
      brandList,
      brandSelected,
      platformList,
      payRateList,
      collabStatusList,
      sortByList,
      socialStatusList
    } = this.state;
    let platform = [];
    platformList.map(each => {
      if(each.checked === true) {
        platform.push(each.value);
      }
    });
    //链接某个social media平台后只显示这个social media平台有的collaboration
    platform = Array.from(new Set([...platform, ...socialStatusList]));
    let payRate = [];
    payRateList.map(each => {
      if(each.checked === true){
        payRate.push(each.value);
      }
    });
    let collabStatus = [];
    collabStatusList.map(each => {
      if(each.checked === true) {
        collabStatus.push(each.value);
      }
    });
    const sortByItem = sortByList.find(each => each.checked === true) || '';
    const {
      data: {
        results = []
      }
    } = await request(['order.read', {
      query: {
        page: 1,
        page_size: 100,
        brand_user_id: brandList[brandSelected].user.id,
        platform_accounts: platform.join(','),
        pay_rates: payRate.join(','),
        collaboration_status: collabStatus.join(','),
        order_by: sortByItem && sortByItem.value || ''
      }
    }]);
    this.setState({
      list: results
    });
  }

  async queryOrder() {
    const {
      state: {
        query,
        page,
        pageSize,
        list,
        total
      }
    } = this;
    const {
      data: {
        count,
        results = []
      } = {}
    } = await request(['order.list', {
      query: {
        query,
        page,
        page_size: pageSize
      }
    }]) || '';
    this.setState({
      list: list.concat(results),
      page: page + 1,
      total: count || total
    });
  }

  async changeBrand(index) {
    this.setState({
      brandSelected: index
    }, async () => {
      await this.searchOrder();
    });
  }

  async componentDidMount() {
    await this.queryBrand();
    let list = await this.getSocialStatus();
    this.setState({
      socialStatusList: list
    }, async () => {
      //this.queryOrder();
      await this.searchOrder();
    });
  }

  async filterChecked(event, type) {
    const {
      platformList,
      payRateList,
      collabStatusList,
      sortByList
    } = this.state;
    let name = event.target.name;
    switch(type) {
    case 'platformList': 
      platformList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({platformList});
      break;
    case 'payRateList': 
      payRateList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({payRateList});
      break;
    case 'collabStatusList': 
      collabStatusList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({collabStatusList});
      break;
    case 'sortByList': 
      sortByList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        } else {
          each.checked = false;
        }
      });
      this.setState({sortByList});
      break;
    }
    await this.searchOrder();
  }

  render() {
    const {
      state: {
        brandList,
        brandSelected = 0,
        platformList,
        payRateList,
        collabStatusList,
        sortByList,
        list,
        socialStatusList
      }
    } = this
    return (
      <div className='influencer-collaboration-container'>
        <div className='influencer-collaboration-title'>COLLABORATIONS</div>
        {
          brandList.length > 0 ?
            <div className='flex-row flex-between-center'>
              <div className='influencer-collaboration-img-container'>
                <BrandList 
                  list={brandList}
                  selected={brandSelected}
                  onClick={this.changeBrand}
                />
              </div>
            </div>
            :<></>   
        }    
        <div className='flex-row flex-center'>
          <div className='flex-row flex-center influencer-collaboration-fiter-container'>
            <div className='flex-column flex-start'>
              <Filter
                label='Platform Accounts'
                list={(() => {
                  let arr = [];
                  for(let i = 0; i < socialStatusList.length; i++) {
                    platformList.map(each => {
                      if(each.value == socialStatusList[i]) {
                        arr.push(each);
                      }
                    })
                  }
                  return arr;
                })()}
                onChange={event => this.filterChecked(event, 'platformList')}
              />
              <Filter
                label='Pay Rate'
                list={payRateList}
                onChange={event => this.filterChecked(event, 'payRateList')}
              />
              <Filter
                label='Collaboration Status'
                list={collabStatusList}
                onChange={event => this.filterChecked(event, 'collabStatusList')}
              />
              <Filter
                label='Sort By'
                list={sortByList}
                onChange={event => this.filterChecked(event, 'sortByList')}
              />
            </div>
          </div>
        </div>
        <div className='flex-row flex-center'>
          <div className='influencer-collaboration-collab-card flex-row flex-start-center flex-wrap'>
            {
              list.map((each, i) => {
                const {
                  id: orderId,
                  state,
                  collaboration: {
                    end_date,
                    quota,
                    product: {
                      name,
                      image_base_url,
                      images
                    }
                  },
                  rate
                } = each;
                const image = images.split(':');
                let date = new Date(end_date);
                let endDate = date.getFullYear() + '/' + date.getMonth() + '/' +date.getDay();
                return (
                  <div key={i} className='influencer-collaboration-collab-card-item'>
                    <CollabCard
                      name={name}
                      rate={rate}
                      status={state}
                      image={`${image_base_url}${image[0]}`}
                      endDate={endDate}
                      quota={quota}
                      router={{pathname: `${INFLUENCER_PATH}/order/${orderId}`, state: {orders: list}}}
                    />
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}
