import { Component } from 'react';

//import Button from '../../../../../../components/Button';

//import request from '../../../../../../service/request';

import './index.css';

export default class StartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({value: event.target.value});
  }

  render() {
    const { 
      data: {
        message = ''
      } 
    } = this.props;
    return (
      <div>
        <div className='start-comp-title'>
					Your Message To Brand
        </div>
        <div className='start-comp-textarea'>{message}</div>
        {/*<textarea className="start-comp-textarea" value={value} onChange={this.onChange}></textarea>*/}
        {/*{
          editable && (
            <Button className="start-comp-btn" onClick={this.send}>Send</Button>
          )
        }*/}
      </div>
    );
  }
}
