import { Component } from 'react';

import Avatar from '../../../../../../components/Avatar';
import Step from '../../../../../../components/Step';

import './index.css';

// import request from '../../../../../../../../service/request';
//import { assembleFullName } from '../../../../../../utils/util';

const PAGE_SIZE = 14;

export default class BrandList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      from: 0
    };

    this.switchPage = this.switchPage.bind(this);
  }

  switchPage(step) {
    let {
      props: {
        list = [],
        onClick = new Function()
      },
      state: {
        page
      }
    } = this;
    page = step === 1
      ? Math.min(page + 1, Math.ceil(list.length / PAGE_SIZE))
      : Math.max(page - 1, 0);
    const from = page * PAGE_SIZE;
    onClick(from);
    this.setState({
      page,
      from
    });
  }

  render() {
    const {
      props: {
        selected = 0,
        list = [],
        onClick = new Function()
      },
      state: {
        from
      },
      switchPage
    } = this;

    return (
      <div className="flex-row">
        <Step
          iconClassName="collab-influencer-list-step"
          onClick={() => switchPage(-1)}
        />
        <div className="collab-influencer-list flex-row flex-wrap flex-start">
          {
            list.slice(from, from + PAGE_SIZE).map((each, i) => {
              const {
                name,
                avatar_base_url = '',
                avatar = ''
              } = each;
              return (
                <div
                  key={i}
                  className={`collab-brandlist-item flex-column flex-start-center ${
                    selected === i ? 'collab-brandlist-item_selected' : ''
                  }`}
                  onClick={() => onClick(i)}
                >
                  <Avatar className="collab-brandlist-avatar" avatar={`${avatar_base_url}${avatar}`} />
                  <div className="collab-brandlist-name">{name}</div>
                </div>
              );
            })
          }
        </div>
        <Step
          iconClassName="collab-influencer-list-step"
          onClick={() => switchPage(1)}
          reverse
        />
      </div>
    );
  }
}
