import { Component } from 'react';

import './index.css'
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import UploadImage from '../../../../../../components/UploadImage';


import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId: '',
      isNextStep: false
    },
    this.updateParams = this.updateParams.bind(this);
    this.getImg = this.getImg.bind(this);
  }

  updateParams(event, label) {
    if (event) {
      const name = event.target.name;
      let value = event.target.value;
      //删除country和state使用select逻辑
      //if(name === 'country') {
      //  for(let i = 0; i < countries.length; i++) {
      //    if(countries[i].code == event.target.value) {
      //      this.setState({countryId: countries[i].id});
      //      this.props.updateInfo({
      //        country: value
      //      });
      //    }
      //  }
      //} else {
      //  this.props.updateInfo({[name]: event.target.value});
      //}
      if(name == 'isCheck') {
        value = event.target.checked;
      }
      this.props.updateInfo({[name]: value});
    }
    //处理按钮，若存在未输入项，则不可点击
    setTimeout(() => {
      const {
        props: {
          info
        }
      } = this;
      for(let each in info) {
        if((info[each] == '')) {
          this.setState({isNextStep: false});
          return;
        }
      }
      this.setState({isNextStep: true});
    }, 0);
  }

  getImg(id, data) {
    const { updateInfo } = this.props;
    const { avatar, base_url } = data;
    updateInfo({avatar, baseUrl: base_url});
    this.updateParams()
  }

  render() {
    const {
      state: {
        isNextStep
      },
      props: {
        info,
        setStep
      }
    } = this;
    return (
      <Box sx={{textAlign: 'right'}} className="flex-column flex-between-center">
			  <div className="signup-title">PARTNER WITH OVER 500+ BRANDS</div>
        <div className="signup-subtitle">Luxury Skincare, Fitness, and Lifestyle well-being</div>
        <Box sx={{
          display: 'inline-block',
          mt: '0.78125vw',
          textAlign: 'left',
          width: '46.61vw',
          height: '29.75vw',
          'overflow-y': 'scroll'
        }}>
          <div className='flex-row flex-around-center'>
            <div className='signup-upload flex-column flex-center'>
              <UploadImage
                id='influencer_logo'
                uploadTransId='profile.create.avatar'
                getImg={this.getImg}
                image={`${info.baseUrl}${info.avatar}`}
              />
              <div>
                <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
                <div className='signup-upload-font'>Click to change photo(Please upload image less than 2MB)</div>
              </div>
            </div>
            <Box sx={{
              mt: '1.3vw'
            }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing = {1}
              >
                <Input
                  isRequire={true}
                  name="email"
                  value={info.email}
                  pattern={'^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'}
                  errMsg={'Please enter the correct format'}
                  label={'Email'}
                  colorSet="white"
                  placeholder="BCom@gmail.com"
                  width="25vw"
                  onChange={() => this.updateParams(event, 'Email')}
                />
                <Input
                  isRequire={true}
                  name="password"
                  label={'Password'}
                  value={info.password}
                  colorSet="white"
                  type="password"
                  placeholder="password"
                  width="25vw"
                  onChange={this.updateParams}
                />
                <Input
                  isRequire={true}
                  name="confirmPassword"
                  label={'Confirm Password'}
                  value={info.confirmPassword}
                  pattern={info.password}
                  colorSet="white"
                  type="password"
                  errMsg={'Password not match'}
                  placeholder="password"
                  width="25vw"
                  onChange={this.updateParams}
                />
              </Stack>
            </Box>
          </div>
        </Box>
        <div>
          <Button
            className="btn-next-step"
            colorSet={isNextStep ? 'blue' : 'gray'}
            disabled={!isNextStep}
            onClick={() => setStep(2)}
          >
						Next Step(1/3)
          </Button>
        </div>
      </Box>
    );
  };
};
