import { Component } from 'react';

import Container from '../Container';
import AccountTab from './components/AccountTab';
import AccountCard from './components/AccountCard';
import SelectableList from '../../../../../../components/SelectableList';
import SelectableImage from '../../../../../../components/SelectableImage';

import Divider from '@mui/material/Divider';

import { getInterestImgList } from './config';

import request from '../../../../../../service/request';

import './index.css';

import { observer } from 'mobx-react';
import { RootStoreContext } from '../../../../../../store';
import authStore from '../../../../../../store/user/auth';

const auth = authStore();

const containerStyle = {
  width: '71.22vw',
  paddingLeft: '2.1484vw'
}

const socialContainerStyle = {
  width: '73.37vw',
  minHeight: '11.39vw',
  padding: '0'
}
class SocialAccount extends Component {
  static contextType = RootStoreContext;
  constructor(props) {
    super(props);
    this.state = {
      //TODO
      accountList: [
        {
          title: 'Instagram',
          avatar:  require('../../../../../../assets/images/socialAccount/img1.jpg'),
          userName: 'Blair Bao',
          disabled: true,
          active: false,
          isAdd: false,
          info: ['1000+ subscribers', 'Minimum average of 2000 views for the last 5 videos', 'Relavent and consistent content', 'Active channel', 'Authenticity of audience'],
          login: (e) => {
            //e.preventDefault()
            return new Promise((resolve) => {
              window.FB.login((response) => {
                const {
                  authResponse: {
                    accessToken,
                    expiresIn,
                    signedRequest,
                    userID
                  }
                } = response;
                request(['instagram.create.oauth2callback', {
                  data: {
                    accessToken,
                    expiresIn,
                    signedRequest,
                    userID
                  }
                }]).then(() => {
                  let arr = this.state.accountList;
                  arr[0].active = true;
                  this.setState({accountList: arr});
                  resolve(true)
                });
                //window.FB.api('/me', function(response) {
                //});
                window.FB.api('/me/permissions', function(response) {
                });
              }, {
                scope: ['pages_show_list', 'instagram_basic', 'instagram_manage_insights', 'pages_read_engagement', 'public_profile']
              });
            });
          },
          logout: () => {
            return new Promise(resolve => {
              window.FB.getLoginStatus((res) => {
              //刷新页面后，不知道为什么要调用一下这个接口logout才能成功
                window.FB.logout((response) => {
                  let arr = this.state.accountList;
                  arr[0].active = false;
                  this.setState({accountList: arr});
                  request(['instagram.delete.delete']).then(() => {
                    resolve(true);
                  });
                });
              })
            });
          }
        },
        {
          title: 'YouTube',
          avatar:  require('../../../../../../assets/images/socialAccount/img1.jpg'),
          userName: 'Blair Bao',
          disabled: true,
          active: false,
          isAdd: false,
          info: ['1000+ subscribers', 'Minimum average of 2000 views for the last 5 videos', 'Relavent and consistent content', 'Active channel', 'Authenticity of audience'],
          href: `https://api.baocommunications.com/api/youtube/oauth?redirect=${location.href}&token=${auth.getToken()}`,
          login: async () => {
            //let arr = this.state.accountList;
            //arr[1].active = true;
            //this.setState({accountList: arr});
            //await request(['youtube.read.oauth', {
            //  data: {
            //    redirect: location.href
            //  }
            //}]);
          },
          logout: () => {
            return new Promise(resolve => {
              request(['youtube.delete.delete']).then(() => {
                resolve(true);
              });
            })
          }
        },
        {
          title: 'TikTok',
          avatar:  require('../../../../../../assets/images/socialAccount/img1.jpg'),
          userName: 'Blair Bao',
          disabled: true,
          active: false,
          isAdd: false,
          info: ['1000+ subscribers', 'Minimum average of 2000 views for the last 5 videos', 'Relavent and consistent content', 'Active channel', 'Authenticity of audience'],
          href: `https://api.baocommunications.com/api/tiktok/oauth?redirect=${location.href}&token=${auth.getToken()}`,
          login: () => {
            //let arr = this.state.accountList;
            //arr[1].active = true;
            //this.setState({accountList: arr});
            //await request(['youtube/oauth.read']);
          },
          logout: () => {
            return new Promise(resolve => {
              request(['tiktok.delete.delete']).then(() => {
                resolve(true);
              });
            })
          }
        }
      ]
    }
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.getSocialStatus = this.getSocialStatus.bind(this);
  }

  add(item) {
    const { accountList } = this.state;
    let temp = accountList;
    temp.map(each => {
      if(each.title == item) {
        each.isAdd = true;
      }
    });
    this.setState({accountList: temp});
  }

  remove(item) {
    const { accountList } = this.state;
    let temp = accountList;
    temp.map(each => {
      if(each.title == item) {
        each.isAdd = false;
      }
    });
    this.setState({accountList: temp});
  }

  async getSocialStatus() {
    const { data } = await request(['social.read.status']);
    const { accountList } = this.state;
    let temp = accountList;
    temp.map(each => {
      for(let i in data) {
        if(each.title.toLocaleLowerCase() == i) {
          each.isAdd = data[i];
          each.active = data[i];
        }
      }
    });
    this.setState({accountList: temp});
  }

  async componentDidMount() {
    //TODO 临时解决方式
    if(window.location.search.includes('follower_count')) {
      let arr = this.state.accountList;
      arr[1].active = true;
      this.setState({accountList: arr});
    }

    await this.getSocialStatus();
  }

  render() {
    const {
      state: {
        accountList
      },
      context: {
        influencer: {
          info: {
            interests
          }
        }
      }
	 } = this;
	 const interestsType = interests.split(':');
    return (
      <div>
        <Container
          title="Your Account(s)"
          subtitle="Please connect to accounts to use with BCom"
          style={containerStyle}
        >
          <div className="social-acct-tab-container">
            {
              accountList.map((each, key) => {
                return (
                  <AccountTab
                    key={key}
                    {...each}
                    remove={this.remove}
                  />
                )
              })
            }
          </div>
        </Container>
        <div className="flex-row flex-between-center flex-wrap social-acct-card-container">
          {
            accountList.map((each, key) => {
              return (
                <AccountCard
                  key={key}
                  {...each}
                  style={{marginBottom: '2.6vw'}}
                  add={this.add}
                />
              )
            })
          }
        </div>
        <Container style={socialContainerStyle}>
          <div className="social-acct-interests">
            <div className="social-acct-interests-text">Social Network Interests</div>
            <Divider sx={{mt: '0.5859vw', borderColor: '#999'}}/>
            <SelectableList
              className='flex-row flex-start flex-wrap'
              list={getInterestImgList(interestsType)}
              component={SelectableImage}
              style={{ width: '41.66vw' }}
              componentStyle={{
                style: {
                  width: '6.31vw',
                  height: '6.31vw',
                  edge: '0.9765vw',
                  borderRadius: '50%'
                },
                selectedStyle: {
                  backgroundColor: '#8657ff',
                  opacity: 0.3
                },
                unselectedStyle: {
                  boxSizing: 'border-box',
                  border: '0.1953vw solid #8657ff'
                },
                labelStyle: {
                  padding: '0.1302vw 0.0651vw',
                  fontSize: '0.78125vw',
                  backgroundColor: '#fff'
                }
              }}
            />
          </div>
        </Container>
      </div>
    )
  }
}

export default observer(SocialAccount);
