import { Component } from 'react';

import Inbox from '../../../../components/Inbox';
import Back from '../../../../components/Back';
import Avatar from '../../../../components/Avatar';

import { observer } from 'mobx-react';
import { RootStoreContext } from '../../../../store';

import './index.css';

import { withRouter } from 'react-router';

class InfluencerInbox extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    this.state = {
      room: {}
    }
    this.callback = this.callback.bind(this);
  }

  callback(room) {
    this.setState({room});
  }

  render() {
    const {
      state: {
        room,
        room: {
          avatar_base_url = '',
          avatar = ''
        }
      },
      props: {
        location: {
          state: {
            name = ''
          } = {}
        } = {}
      }
    }= this;
    return (
      <div>
        <Back className='influencer-inbox-back'></Back>
        <div className='flex-row flex-start-center'>
          <div className='flex-row flex-start'>
            <Inbox name={name} callback={this.callback}/>
          </div>
          <div className='influencer-inbox-avatar-container flex-column flex-center'>
            <Avatar 
              avatar={`${avatar_base_url}${avatar}`}
              className="influencer-inbox-avatar"
            />
            <div className='influencer-inbox-avatar-name'>{room.name}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(withRouter(InfluencerInbox));