import { Component } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';

import Back from '../Back';

import './index.css';

export default class Dialog extends Component {
  constructor(props = {}) {
    super(props);
  }

  render() {
    const { style, className = '', showBack = false, children, visible, close, cancelIcon } = this.props;
    return (
      visible ?
        <div className='z-index-5 fixed'>
          <div className="dialog-shadow"></div>
          <div className="dialog-container flex-row flex-center">
            {
              showBack && <div className='dialog-back'>
                <Back
                  onClick={close}
                />
              </div>
            }
            {
              cancelIcon ?
                <div onClick={close} className="dialog-close">
                  <CancelIcon sx={{fontSize: '2.27vw'}}/>
                </div>
                :
                null
            }
            <div className={`dialog-block ${className}`} style={{...style}}>
              {children}
            </div>
          </div>
        </div>
        :
        null
    );
  }
};
