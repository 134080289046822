import { Component } from 'react';
import Button from '../Button';
import MakeCollaboration from './components/MakeCollaboration';

export default class MakeCollabButton extends  Component{
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      makeCollabVisible: false
    }
    this.makeCollab = this.makeCollab.bind(this);
    this.closeMakeCollab = this.closeMakeCollab.bind(this);
  }

  makeCollab() {
    this.setState({makeCollabVisible: true});
  }

  closeMakeCollab() {
    this.setState({makeCollabVisible: false});
  }

  render() {
    const {
      label = 'Make Collaboration',
      className = '',
      labelClassName = '',
      onClick,
      product= {},
      saveFunc
    } = this.props;

    return (
      <div>
        <Button
          className={className}
          onClick={() => {
          	this.makeCollab();
            if(onClick){
              onClick();
            }
        	}}
          colorSet="pink"
        >
          <div className={labelClassName}>{label}</div>
        </Button>
        <MakeCollaboration
          saveFunc={saveFunc}
          product={product}
          visible={this.state.makeCollabVisible}
          closeDialog={this.closeMakeCollab}
        />
      </div>
    );
  }
}
