import { Component } from 'react';

import Avatar from '../../../../../../../../components/Avatar';
import Button from '../../../../../../../../components/Button';
import Dialog from '../../../../../../../../components/Dialog';

import Divider from '@mui/material/Divider';

import { RootStoreContext } from '../../../../../../../../store';

import './index.css';

export default class AccountTab extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
    this.remove = this.remove.bind(this);
  }
  remove() {
    const { title, remove, logout } = this.props;
    if(typeof logout == 'function'){
      logout().then(res => {
        if(res) {
          remove(title);
          this.setState({visible: false})
        }
      });
    } else {
      remove(title);
    }
  }
  render() {
    const { 
      state: {
        visible
      },
      props: {
        title, 
        disabled, 
        active,
        //href, 
        //login,
        style,
        isAdd
      },
      context: {
        influencer: {
          info: {
            baseUrl,
            avatar,
            firstName,
            lastName
          }
        }
      }
    } = this;
    return (
      <div>
        {
          isAdd ? 
            <div style={style}>
              <div className="account-tab-title">{title}</div>
              <Divider sx={{ borderColor: '#999999', width: '67.12vw'}}/>
              <div className="flex-row flex-between-center account-tab-info">
                <div className="flex-row flex-start-center">
                  <Avatar 
                    className="account-tab-avatar"
                    avatar={ `${baseUrl}${avatar}` }
                  />
                  <div className="account-tab-user-name">{`${firstName} ${lastName}`}</div>
                </div>
                <Button
                  className="account-tab-user-btn-primary"
                  colorSet={disabled ? 'gray' : 'blue'} 
                  disabled={disabled}
                >
                  { disabled ? 'Use as primary Account' : 'Primary Account'}
                </Button>
                {/*{
                  login ? 
                    <a href={href} style={{textDecoration: 'none'}}>
                      <Button className="account-tab-user-btn-primary" onClick={login}>{title} Login</Button>
                    </a>
                    :
                    null
                }*/}
                {/*{
                  logout ? 
                    <Button className="account-tab-user-btn-primary" onClick={logout}>{title} Logout</Button>
                    :
                    null
                }*/}
                {/*<div className="fb-login-button" data-width="" data-size="medium" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>*/}
                <div>
                  <div className={active ? 'account-tab-active' : 'account-tab-not-active'}>
							      Active
                  </div>
                  <div></div>
                </div>
              </div>
              <div>
                <Button className="account-tab-btn-remove" colorSet="white" onClick={() => this.setState({visible: true})}>Remove Account</Button>
              </div>
            </div>
            :
            <></>
        }
        <Dialog
          visible={visible}
        >
          {
            <div className="flex-column flex-center">
              <div className="account-tab-dialog-text">Delete This Account?</div>
              <div className='account-tab-dialog-text-red'>(This cannot be undone)</div>
              <div className="flex-row flex-between-center account-tab-btn">
                <Button className="account-tab-btn-dialog" colorSet="white" onClick={this.remove}>Confirm</Button>
                <Button className="account-tab-btn-dialog" colorSet="black" onClick={() => this.setState({visible: false})}>Cancel</Button>
              </div>
            </div>
          }
        </Dialog>
      </div>
    )
  }
}