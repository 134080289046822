import { Component } from 'react';

import Back from '../../../../../../components/Back';
import Image from '../../../../../../components/Image';
import CollabRequirements from '../../../../../../components/CollabRequirements';
import PlainLink from '../../../../../../components/PlainLink';
import TextContent from '../../../../../../components/TextContent';
import FollowCard from '../../../../../../components/FollowCard';
import Button from '../../../../../../components/Button';
import Footer from '../../../../../../components/Footer';
import Dialog from '../../../../../../components/Dialog';

import ApplyDialog from '../ApplyDialog';

import request from '../../../../../../service/request';

import './index.css';

import { redirect } from '../../../../../../router';
import { AUTH_ROUTE, INFLUENCER_PATH } from '../../../../../../router/config';
import { withRouter } from 'react-router';

import { RootStoreContext } from '../../../../../../store';

class ProductDetails extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          id = ''
        } = {}
      } = {},
      location: {
        state: {
          product = []
        } = {}
      } = {}
    } = props;
    let productIdList = [];
    let index = 0;
    product.map((each, i) => {
      if(each.id == id) index = i;
      productIdList.push(each.id);
    })
    this.state = {
      productIdList,
      index,
      collabrationId: parseInt(id),
      isApply: false,
      visible: false,
      quota: {
        instagram: 1,
        tiktok: 1
      },
      product: {},
      userInfo: {},
      requirements: '',
      isShowSignInDialog: false
    }
    this.load = this.load.bind(this);
    this.apply = this.apply.bind(this);
    this.closeApplyDialog = this.closeApplyDialog.bind(this);
  }

  async apply() {
    const {
      context: {
        auth: {
          role = undefined
        } = {}
      }
      //props: {
      //  match: {
      //    params: {
      //      id = ''
      //    } = {}
      //  } = {}
      //}
    } = this;
    if(role == undefined) {
      this.setState({isShowSignInDialog: true});
      //redirect({pathname:AUTH_ROUTE.path.replace(':role', 'influencer'), state: {redirectUrl: `${INFLUENCER_PATH}/product/${id}`}});
      return;
    }
    const { isApply } = this.state;
    if(!isApply) return;
    let {data} =  await request(['me.read']);
    this.setState({userInfo: data, visible: true});
  }

  closeApplyDialog() {
    this.setState({visible: false});
  }

  async load() {
    const {
      state: {
        collabrationId
      }
    } = this;
    //let res = await request(['collaboration.read', {
    //	  params: collabrationId
    //}])
    const { 
      data: {
        product,
        quota,
        can_apply,
        requirements
      } 
    } = await request([`collaboration.read.influencer.${collabrationId}`])
    this.setState({
      product,
      quota,
      isApply: can_apply,
      requirements
    });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      state: {
        collabrationId,
        visible,
        userInfo,
        product,
        requirements,
        product: {
          name = '',
          image_base_url = '',
          images = '',
          categories = '',
          price = '',
          description = '',
          owner: {
            brand = {}
          } = {}
        },
        productIdList,
        index,
        quota = {
          instagram: 1,
          tiktok: 1
        },
        isApply,
        isShowSignInDialog
      },
      props: {
        match: {
          params: {
            id = ''
          } = {}
        } = {}
      }
    } = this;
    return (
      <div>
        <div className='product-details-container'>
          <Back label={name}/>
          <div className="product-details-image-list flex-row flex-start">
            <Image
              image={`${image_base_url}${images.split(':')[0]}`}
              className='product-details-image product-details-image_large flex-unshrink'
            />
            <div className='flex-column flex-center product-details-block'>
              <div className='product-details-card'>
                <FollowCard
                  description={categories}
                  data={brand}
                />
              </div>
              {
                Object.keys(quota).map((each, i) =>
                  <div key={i} className="product-details-quota">
                    <CollabRequirements
                      className="product-details-quota-inner"
                      platform={each}
                      amount={quota[each]}
                      editable={false}
                    />
                  </div>
                )
              }
            </div>
          </div>
          <div className='flex-row product-details-pruduct-link-font'>
            <div className='flex-row product-details-pruduct-link-container'>
              <div className='product-details-pruduct-link'>Product Link:</div>
              <PlainLink>{name}</PlainLink>
            </div>
            <div className='flex-row'>
              <div className='product-details-pruduct-link'>Retail Price:</div>
              <PlainLink>{price}</PlainLink>
            </div>
          </div>
          <div className='product-details-info-container'>
            <TextContent 
              style={{marginBottom: '2.6vw'}}
              title='Product Details'
              info={description}
            //info='Vita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects ofVita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects ofVita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects ofVita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects of'
            />
            <TextContent 
              title='Compaign Details'
              info={requirements}
            />
          </div>
          <Button className="product-details-apply-btn" colorSet={isApply ? 'blue' : 'gray'} onClick={this.apply}>{isApply ? 'Apply Now' : 'Applied'}</Button>
          <div className='flex-row flex-between-center product-detals-bottom'>
            {
              index > 0 ?
                <Back 
                  label="Previous Product"
                  className="product-detals-bottom-block"	
                  onClick={() => {
                    this.setState({index: index-1, collabrationId: productIdList[index-1]}, async () => {
                      await this.load();
                    })
                  }}
                />
                :
                <div></div>
            }
            {
              index < productIdList.length-1 ?
						 	<Back 
                  label="Next Product"
                  direction="forward"
                  className="product-detals-bottom-block"	
                  onClick={() => {
                    this.setState({index: index+1, collabrationId: productIdList[index+1]}, async () => {
                      await this.load();
                    })
                  }}
                />
					 		:
					 		<div></div>
            }
          </div>
          <ApplyDialog
            id={collabrationId}
            userInfo={userInfo}
            product={product}
            visible={visible}
            close={this.closeApplyDialog}
          />
          <Dialog
            showBack={false}
            visible={isShowSignInDialog}
            className='flex-column flex-center'
          >
            {
              <div className='flex-column flex-center'>
                <div className='product-detals-sign-dialog-font'>Please Sign In/Sign Up To Apply</div>
                <div className='product-detals-sign-dialog-btn-group flex-row flex-between-around'>
                  <Button className="product-detals-sign-dialog-btn" colorSet="black" onClick={() => {
                    redirect({pathname:AUTH_ROUTE.path.replace(':role', 'influencer'), state: {redirectUrl: `${INFLUENCER_PATH}/product/${id}`}});
                  }}>
									Sign In
                  </Button>
                  <Button className="product-detals-sign-dialog-btn" colorSet="white" onClick={() => {
                    this.setState({isShowSignInDialog: false});
                  }}>
									Cancel
                  </Button>
                </div>
              </div>
            }
						
          </Dialog>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ProductDetails);