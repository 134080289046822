import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export default function(props) {
  const {
    startDate,
    endDate,
    onChange
  } = props;

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
    />
  );
};