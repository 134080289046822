import { Component } from 'react';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import './index.css';

export default class ChatBubble extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isNews } = this.props;
    return (
      isNews ? 
        <div className="chat-bubble-cantainer flex-row flex-center">
          <div>
            <div className="chat-bubble-round"></div>
            <ChatBubbleIcon sx={{color: 'rgb(96, 0, 255)'}}/>
          </div>
        </div>
        : 
        <div className="chat-bubble-cantainer flex-row flex-center">
          <div>
            <ChatBubbleIcon sx={{color: 'rgb(96, 0, 255)'}}/>
          </div>
        </div>
    )
  }
};
