import Image from '../Image';

export default function BgImage (props =  {}) {
  const {
    image,
    className = '',
    style = {}
  } = props;
  return (
    <Image
      className={`absolute ${className}`}
      image={image}
      style={style}
    />
  );
};
