import { Component } from 'react';

import Search from '../../../../../../components/Search';
import Image from '../../../../../../components/Image';
import Button from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog';
import Input from '../../../../../../components/Input';

import request from '../../../../../../service/request';

import { withRouter } from 'react-router';
import { INFLUENCER_PATH } from '../../../../../../router/config';

import './index.css';

const replaceStyle = {
  width: '49.34vw',
  height: '23.76vw'
}

const deleteStyle = {
  width: '49.34vw',
  height: '23.76vw'
}

class DraftCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      url: '',
      dialogType: 'replace' // replace, delete
    }
    this.submit = this.submit.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onChange = this.onChange.bind(this);
    this.delete = this.delete.bind(this);
  }

  onChange(event) {
    this.setState({url: event.target.value});
  }

  async delete() {
    const { 
      props: {
        orderId,
        draftId,
        callback
      }
    } = this;
    await request([`order.delete.draft/${draftId}`, {
      params: orderId,
      data: {
        id: orderId,
        draft_id: draftId
      }
    }]);
    await callback();
    this.closeDialog();
  }

  async submit() {
    const { 
      state: {
        url
      },
      props: {
        orderId,
        draftId,
        callback
      }
    } = this;
    await request([`order.update.draft/${draftId}`, {
      params: orderId,
      data: {
        url: url
      }
    }]);
    await callback();
    this.closeDialog();
  }

  openDialog(type) {
    this.setState({
      visible: true,
      dialogType: type
    });
  }

  closeDialog() {
    this.setState({
      visible: false
    });
  }

  render() {
    const { 
      state: {
        visible,
        dialogType
      },
      props: {
        name = '',
        image='',
        isApproved = false,
        link='',
        push = new Function()
      }
    } = this;
    return (
      <div style={{width: '26.43vw'}}>
        <Image 
          className='draft-card-image'
          image={image}	
        />
        {
          isApproved ? 
            <div className='flex-row flex-between'>
              <div className='draft-card-approved'>Approved</div>
              <a className='draft-card-link' href={link} target='_blank' rel="noreferrer">Link</a>
            </div>
            :
            <div className='flex-row flex-between'>
              <div className='flex-column flex-center'>
                <Button className='draft-card-btn' onClick={() => this.openDialog('replace')}>Replace</Button>
                <Button className='draft-card-btn' onClick={() => {
                  push({pathname:`${INFLUENCER_PATH}/inbox`, state: {name: `${name}`}});
                }}>Contact Brand</Button>
              </div>
              <Button colorSet='red' className='draft-card-delete-btn' onClick={() => this.openDialog('delete')}>Delete</Button>
            </div>
        }
        <Dialog
          close={this.closeDialog}
          visible={visible}
          className='flex-column flex-center'
          style={ dialogType == 'replace' ? replaceStyle : deleteStyle}
        >
          {
            dialogType == 'replace' ?
              <div className='flex-column flex-center'>
                <div className='draft-card-dialog-title'>Please enter your new draft link</div>
                <Input 
                  value={this.state.url}
                  onChange={this.onChange}
                  placeholder='URL'
                  width='42.1223vw'
                  height='4.7526vw'
                />
                <Button className='draft-card-dialog-submit-btn' onClick={this.submit}>Submit</Button>
              </div>
              :
              <div className='flex-column flex-center'>
                <div className='draft-card-dialog-title'>Are you sure to delete this draft?</div>
                <div className='draft-card-dialog-subtitle'>This cannot be undone</div>
                <div className='flex-row flex-between draft-card-dialog-delete-btn-group'>
                  <Button colorSet='white' className="draft-card-dialog-delete-btn" onClick={this.delete}>Yes</Button>
                  <Button colorSet='black' className="draft-card-dialog-delete-btn" onClick={this.closeDialog}>Cancel</Button>
                </div>
              </div>
          }
        </Dialog>
      </div>
    );
  }
}

class ProductReceivedComponet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draftList: this.props.data.drafts || []
    }
    this.submitLink = this.submitLink.bind(this);
    this.getDraft = this.getDraft.bind(this);
  }

  async submitLink(value) {
    const { orderId } = this.props;
    await request(['order.create.draft', {
      params: orderId,
      data: {
        url: value
      }
    }]);
    await this.getDraft();
  }

  async getDraft() {
    const { orderId } = this.props;
    const {
      data: {
        drafts
      } = {}
    } = await request(['order.read', {
      params: orderId
    }])
    this.setState({
      draftList: drafts
    });
  }

  render() {
    const {
      state: {
        draftList
      },
      props: {
        orderId,
        data: {
          collaboration: {
            product: {
              owner: {
                brand: {
                  name
                }
              }
            }
          }
        },
        editable,
        history: {
          push
        }
      }
    } = this;
    return (
      <div>
        {
          editable && (
            <div>
              <div className='product-received-comp-title'>Add Your Draft Here:</div>
              <Search 
                onClick={this.submitLink}
                className="product-received-comp-search"
                btnClassName="product-received-comp-search-btn"
                btnColorSet="blue"
                shrink={true}
                placeholder="URL"
                btnText="Submit Link"
              />
              <div className='product-received-comp-describle'>
					      Please provide a link to where you saved your draft, i.e. Google Drive. Add one at a time.
              </div>
            </div> 
          )
        }
        <div className='flex-row flex-start flex-wrap'>
          {
            (() => {
              let arr = [];
              for(let each in draftList) {
                const {
                  id,
                  url,
                  is_approved
                } = draftList[each]
                arr.push(
                  <div key={each} style={{marginRight: '1.3vw'}}>
                    <DraftCard  
                      orderId={orderId}
                      draftId={id}
                      name={name}
                      link={url}
                      isApproved={is_approved}
                      callback={this.getDraft}
                      push={push}
                    />
                  </div>
                );
              }
              return arr;
            })()
          }
        </div>
      </div>
    );
  }
}

export default withRouter(ProductReceivedComponet);