import { Component } from 'react';

import Dialog from '../../../../../../components/Dialog';
import Avatar from '../../../../../../components/Avatar';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { Grid } from '@mui/material';

import { withRouter } from 'react-router';
import { INFLUENCER_PATH } from '../../../../../../router/config';

import request from '../../../../../../service/request';

import './index.css';

const style = {
  width: '48.95vw',
  height: '39.06vw'
}

class ApplyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phoneNum: '',
      address: '',
      address2: '',
      city: '',
      postalCode: '',
      influencerRate: '',
      radioValue: 'correctAddr',
      message: '',
      isSend: false
    }
    this.updateParams = this.updateParams.bind(this);
    this.sendApplication = this.sendApplication.bind(this);
    this.onChange = this.onChange.bind(this);
    this.back = this.back.bind(this);
  }

  updateParams(event) {
    let name = event.target.name;
    this.setState({[name]: event.target.value});
  }

  onChange(event) {
    let value = event.target.value;
    this.setState({
      radioValue: value
    });
  }

  async sendApplication() {
    const { 
      state: {
        name,
      	phoneNum,
      	address,
      	address2,
      	city,
      	state,
        influencerRate,
      	postalCode,
        radioValue,
        message
      },
      props: {
        userInfo,
        id
      }
    } = this;
    let shippingAddress = {
      name,
      phone: phoneNum,
      address,
      address2,
      city,
      state,
      postal_code: postalCode
    }
    if(radioValue == 'correctAddr'){
      shippingAddress = {
        name: userInfo.first_name + '·' + userInfo.last_name,
        phone: userInfo.phone_number,
        address: userInfo.address,
        address2: userInfo.address2,
        city: userInfo.city,
        state: userInfo.state,
        postal_code: userInfo.postal_code
      }
    }
    await request(['order.create', {
      data: {
        collaboration: id,
        //influencer: 1,
        influencer_rate: influencerRate,
        shipping_address: shippingAddress,
        message: message
      }
    }]);
    this.setState({
      isSend: true
    });
  }

  back() {
    const { 
      props: {
        close,
        history: {
          push
        }
      }
    } = this;
    push(`${INFLUENCER_PATH}/product`);
    close();
  }

  render() {
    const { 
      state: {
        radioValue,
        influencerRate,
        message,
        isSend
      },
      props: {
        visible,
        userInfo,
        product,
        close
      }
    } = this;
    return (
      <div>
        <Dialog 
          style={style}
          visible={visible}
          close={close}
          className='flex-column flex-center'
        >
          {
            <div className='scroll-y apply-dialog-container'>
              <div className='flex-column flex-center'>
                <div className='apply-title'>Applying</div>
                <div className='apply-card'>
                  <Avatar
                    avatar={`${product.image_base_url}${(product.images&&product.images.split(':')[0]) || ''}`}
                    className="apply-avatar"
                    shape="cubic"
                  />
                  <div className='apply-card-style'>{product.categories}</div>
                  <div className='apply-card-label'>{product.name}</div>
                </div>
                {
                  !isSend ? 
                    <div className='flex-column flex-center'>
                      <div>
                        <div className='apply-message'>Message To Brand</div>
                        <textarea 
                          className="apply-textarea" 
                          value={message}
                          name='message'
                          onChange={this.updateParams}
                        ></textarea>
                      </div>
                      <div className='flex-row flex-center apply-rate-block'>
                        <div className='apply-rate'>Your Rate : $</div>
                        <Input
                          name="influencerRate"
                          value={influencerRate}
                          onChange={this.updateParams}
                          height="2.4vw" width="6.7vw"/>
                      </div>
                      <div className='apply-comfirm-address'>Confirm Shipping Address</div>
                      <div className='apply-address apply-address-font flex-column flex-center-start'>
                        <div>{userInfo.address} {userInfo.address2},{userInfo.city},{userInfo.postal_code}</div>
                        <div>{userInfo.first_name}·{userInfo.last_name}</div>
                        <div>{userInfo.phone_number}</div>
                      </div>

                      <div className='apply-checkbox'>
                        <div className='flex-row flex-start-center'>
                          <FormControl>
                            <RadioGroup
                              name="controlled-radio-buttons-group"
                              value={radioValue}
                              onChange={this.onChange}
                            >
                              <FormControlLabel value="correctAddr" control={<Radio />} label="Yes, this address is correct" />
                              <FormControlLabel value="anotherAddr" control={<Radio />} label="Please use another address" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      {
                        radioValue == 'anotherAddr' ?
                          <div className='apply-input-block'>
                            <Grid
                              container
                              spacing={1}
                            >
                              <Grid item xs={6}>
                                <Input 
                                  name="name"
                                  value={this.state.name}
                                  label="First-Last Name"
                                  width="16.92vw" 
                                  height="3.38vw"
                                  onChange={this.updateParams}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Input
							  			        name="phoneNum" 
                                  value={this.state.phoneNum}
                                  label="Phone Number"
                                  width="16.92vw" 
                                  height="3.38vw"
                                  onChange={this.updateParams}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Input
                                  value={this.state.address}
                                  name="address"
                                  label="Address"
                                  width="16.92vw" 
                                  height="3.38vw"
                                  colorSet="white"
                                  onChange={this.updateParams} />
                              </Grid>
                              <Grid item xs={6}>
                                <Input
                                  value={this.state.address2}
                                  name="address2"
                                  label="Address Line 2"
                                  width="16.92vw" 
                                  height="3.38vw"
                                  colorSet="white"
                                  onChange={this.updateParams}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Input
                                  value={this.state.city}
                                  name="city"
                                  label="City"
                                  width="16.92vw" 
                                  height="3.38vw"
                                  colorSet="white"
                                  onChange={this.updateParams}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Input
                                  value={this.state.state}
                                  name="state"
                                  label="State"
                                  width="6.1848vw"
                                  height="3.38vw"
                                  colorSet="white"
                                  onChange={this.updateParams}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Input
                                  value={this.state.postalCode}
                                  name="postalCode"
                                  label="Postal Code"
                                  width="6.1848vw"
                                  height="3.38vw"
                                  colorSet="white"
                                  onChange={this.updateParams}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          :
                          <></>
                      }
                      <div className='flex-row flex-between-center apply-btn'>
                        <Button colorSet="white" className="apply-send-btn" onClick={this.sendApplication}>Send Application</Button>
                        <Button colorSet="black" className="apply-send-cancel-btn" onClick={close}>Cancel</Button>
                      </div>
                    </div>
                    :
                    <div className='flex-column flex-center'>
                      <div className='apply-title'>Application Sent</div>
                      <div className='apply-text'>We will notify you once this Application is approved.</div>
                      <Button colorSet="white" className="apply-send-btn" onClick={this.back}>Back</Button>
                    </div>
                }   
              </div>
            </div>
             
          }
        </Dialog>
      </div>
    );
  }
}

export default withRouter(ApplyDialog);