export const BASE_URL = 'https://api.baocommunications.com/';
export const AUTH_TOKEN = 'BCOM_AUTH_TOKEN';

export const DEFAULT_PAGE_SIZE = 10;

export const REQUEST_CONFIG = {
  token: {
    create: {
      authorized: false,
      subRequest: false
    }
  },
  password_reset: {
    create: {
      authorized: true
    }
  },
  product: {
    create: {
      authorized: true
    },
    list: {
      authorized: true
    },
    read: {
      authorized: true
    },
    update: {
      authorized: true,
      subModule: true
    },
    delete: {
      authorized: true
    }
  },
  influencer: {
    create: {
      subRequest: true
    },
    update: {
      subRequest: true,
      authorized: true
    },
    list: {
      authorized: true
    },
    read: {
      authorized: true
    }
  },
  collaboration: {
    create: {
      authorized: true
    },
    list: {
      authorized: true
    },
    read: {
      authorized: true
    },
    delete: {
      subModule: true,
      authorized: true
    }
  },
  order: {
    create: {
      authorized: true,
      subModule: true
    },
    update: {
      subModule: true,
      authorized: true
    },
    list: {
      authorized: true
    },
    read: {
      authorized: true
    },
    delete: {
      subModule: true,
      authorized: true
    }
  },
  me: {
    read: {
      authorized: true
    }
  },
  brand: {
    list: {
      authorized: true
    },
    update: {
      authorized: true,
      subRequest: true
    }
  },
  message: {
    create: {
      authorized: true
    },
    list: {
      authorized: true
    },
    read: {
      authorized: true,
      subModule: true
    }
  },
  profile: {
    create: {
      //authorized: true
    }
  },
  user: {
    delete: {
      subModule: true,
      authorized: true
    }
  },
  social: {
    read: {
      authorized: true
    }
  },
  youtube: {
    read: {
      authorized: true
    },
    delete: {
      authorized: true
    }
  },
  instagram: {
    create: {
      authorized: true
    },
    delete: {
      authorized: true
    }
  },
  tiktok: {
    delete: {
      authorized: true
    }
  }
};

export const ACTION_STRATEGIES = {
  create: {
    method: 'POST'
  },
  list: {
    method: 'GET',
    pagination: true
  },
  read: {
    method: 'GET'
  },
  update: {
    method: 'PUT'
  },
  delete: {
    method: 'DELETE'
  }
};

export const ERROR_MSG = {
  401: '用户未登录'
};
