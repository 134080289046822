import { Component } from 'react';

import Back from '../../../../../../../../components/Back';
import Avatar from '../../../../../../../../components/Avatar';
import Image from '../../../../../../../../components/Image';
import List from '../../../../../../../../components/List';

import './index.css';

import { PLATFORM } from '../../../../../../../../constants/platforms';
import { assembleFullName, formatStatisticsByLevel } from '../../../../../../../../utils/util';

const STATISTICS = {
  'engagement_rate': {
    label: 'Engagement Rate',
    size: 'large'
  },
  followers: {
    label: 'Total Followers',
    size: 'medium'
  },
  likes: {
    label: 'Total Likes',
    size: 'medium'
  },
  comments: {
    label: 'Total Comments',
    size: 'medium'
  },
  'avg_view_per_post': {
    label: 'Average Views Per Post',
    size: 'medium'
  }
};

export default class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    };
  }

  render() {
    const {
      props: {
        data: {
          first_name,
          last_name,
          tiktok_statistics = {},
          youtube_statistics = {},
          instagram_statistics = {},
          instagram_story_statistics = {},
          //statistics = {
          //  engagement_rate: 0,
          //  followers: 0,
          //  likes: 0,
          //  comments: 0,
          //  avg_views_per_post: 0
          //},
          avatar_base_url,
          avatar
        } = {}
      },
      state: {
        selected
      }
    } = this;

    //const platforms = Object.keys(statistics).reduce(
    //  (prev, each) => {
    //    const statistic = statistics[each];
    //    Object.keys(statistics[each]).forEach(platform => {
    //      if (PLATFORM[platform.toLowerCase()]) {
    //        prev[platform] = prev[platform] || {};
    //        prev[platform][each] = statistic[platform];
    //      }
    //    });
    //    return prev;
    //  },
    //  {}
    //);
    const platforms = [
      {
        name: 'instagram',
        value: instagram_statistics
      },
      {
        name: 'instagram_story',
        value: instagram_story_statistics
      },
      {
        name: 'youtube',
        value: youtube_statistics
      },
      {
        name: 'tiktok',
        value: tiktok_statistics
      }
    ]
    //const platformKeys = Object.keys(platforms).filter(each => PLATFORM[each.toLowerCase()]);

    return (
      <div className="influencer-details-overview flex-auto croll-y flex-column">
        <Back className="influencer-back" />
        <div className="influencer-details-intro flex-column flex-start-center">
          <Avatar avatar={`${avatar_base_url}${avatar}`} className="influencer-details-avatar" />
          <div className="influencer-details-name">{assembleFullName(first_name, last_name)}</div>
          <div className="flex-row">
            {
              platforms.map((each, i) => (
                <div
                  key={i}
                  onClick={() => this.setState({ selected: i })}
                  className={`${selected === i ? '' : 'un'}selected-platform influencer-platform`}
                >
                  <Image className="influencer-platform-icon" image={PLATFORM[each.name].icon} />
                  <div className='influencer-platform-name'>{each.name}</div>
                </div>
              ))
            }
          </div>
          <List className="influencer-statistics flex-row flex-center-between">
            {
              Object.keys(STATISTICS).map((each, i) => {
                const { label, size } = STATISTICS[each];
                return (
                  <div key={i} className={`influencer-statistics_${size} flex-column flex-center`}>
                    <div>{formatStatisticsByLevel(platforms[selected]['value']&&platforms[selected]['value'][each])}{(each == 'avg_view_per_post' || each =='engagement_rate') ? '%' : ''}</div>
                    <div className="influencer-statistics-label">{label}</div>
                  </div>
                );
              })
            }
          </List>
        </div>
      </div>
    );
  }
};
