import { PureComponent  } from 'react';
import Back from '../../../../../../components/Back';
import Image from '../../../../../../components/Image';
import CollabRequirements from '../../../../../../components/CollabRequirements';
import PlainLink from '../../../../../../components/PlainLink';
import TextContent from '../../../../../../components/TextContent';
import FollowCard from '../../../../../../components/FollowCard';
import Footer from '../../../../../../components/Footer';
import Stepper from '../../../../../../components/Stepper';

import StartComponent from '../StartComponet';
import SelectedComponent from '../SelectedComponet';
import RateConfirmedComponet from '../RateConfirmedComponet';
import ProductReceivedComponet from '../ProductReceivedComponet';
import DraftApprovedComponet from '../DraftApprovedComponet';
import PublishedComponet from '../PublishedComponet';

import request from '../../../../../../service/request';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './index.css';

import { withRouter } from 'react-router';
import { INFLUENCER_PATH } from '../../../../../../router/config';

const COLLAB_STEPS = [
  {
    label: 'Start',
    value: 'Already Applied',
    component: StartComponent
  },
  {
    label: 'Selected',
    value: '',
    component: SelectedComponent
  },
  {
    label: 'Rate Confirmed',
    //value: `Pay Rate: $${payRate}`,
    component: RateConfirmedComponet
  },
  {
    label: 'Product Received',
    value: 'Product Received',
    component: ProductReceivedComponet
  },
  {
    label: 'Draft Approved',
    value: 'Draft Approved',
    component: DraftApprovedComponet
  },
  {
    label: 'Published',
    value: 'Published',
    component: PublishedComponet
  }
  //{
  //  label: 'Declined',
  //  value: 'Declined',
  //  component: new Function()
  //}
];

class CollabDetails extends PureComponent  {
  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          id = ''
        } = {}
      } = {},
      location: {
        state: {
          orders
        }
      }
    } = props;
    let ordersIdList = [];
    let index = 0;
    orders.map((each, i) => {
      if(each.id == id) index = i;
      ordersIdList.push(each.id);
    })
    this.state = {
      orders,
      orderId: parseInt(id),
      ordersIdList,
      index,
      collaboration: {},
      platformList: [],
      selected: 0,
      order: {},
      step: 0,
      selectedStep: 0
    }
    this.getOrder = this.getOrder.bind(this);
  }

  async getOrder() {
    const { 
      state: {
        orderId 
      },
      props: {
        history: { push }
      }
    } = this;
    const {
      data,
      data: {
        state,
        influencer,
        collaboration,
        rate
      } = {}
    } = await request(['order.read', {
      params: orderId
    }])
    if(state == 'Declined') {
      push(`${INFLUENCER_PATH}/product`);
      return;
    }
    //对各平台的数据进行合并，将其放到一个对象里面
    const {
      statistics: {
        followers = [],
        likes = [],
        comments = []
      }
    } = influencer;
    const list = ['instagram', 'tiktok', 'instagram_story', 'youtube'];
    let arr = [];
    list.forEach((i) => {
      let mergeObj = Object.assign({platform: i}, {followers: followers && followers[i] || ''}, {likes: likes && likes[i] || ''}, {comments: comments && comments[i] || ''});
      arr.push(mergeObj);
    });
    const step = COLLAB_STEPS.findIndex(each => each.label === state);
    this.setState({
      step,
      selectedStep: step,
      collaboration,
      payRate: rate,
      platformList: arr,
      order: data
    });
  }

  async componentDidMount() {
    await this.getOrder();
  }

  render() {
    const {
      state: {
        step,
        collaboration,
        payRate,
        platformList,
        order,
        //orderId,
        ordersIdList,
        index,
        selectedStep
      }
    } = this;
    const {
      product: {
        image_base_url,
        images,
        name,
        price,
        description,
        owner: {
          brand = {}
        } = {}
      } = {},
      quota = {},
      requirements
    } = collaboration;
   
    const StepComponent = COLLAB_STEPS[selectedStep].component;
    return (
      <div>
        <div className='collab-detals-container'>
          <Back label={name} />
          <div className="collab-details-image-list flex-row flex-start">
            <Image
              image={`${image_base_url}${images&&images.split(':')[0]}`}
              className='collab-details-image collab-details-image_large flex-unshrink'
            />
            <div className='flex-column flex-center collab-details-block'>
              <div className='collab-details-card'>
                <FollowCard data={brand} />
              </div>
              {
							 Object.keys(quota).map((each, i) =>
							 <div key={i} className="collab-details-quota">
								 <CollabRequirements
									 className="collab-details-quota-inner"
									 platform={each}
									 amount={quota[each]}
									 editable={false}
								 />
							 </div>
						 )
              }
            </div>
          </div>
          <div className='flex-row collab-detals-pruduct-link-font'>
            <div className='flex-row collab-detals-pruduct-link-container'>
              <div className='collab-detals-pruduct-link'>Product Link:</div>
              <PlainLink>{name}</PlainLink>
            </div>
            <div className='flex-row'>
              <div className='collab-detals-pruduct-link'>Retail Price:</div>
              <PlainLink>{price}</PlainLink>
            </div>
          </div>
          <div className='collab-detals-info-container'>
            <TextContent 
              style={{marginBottom: '2.6vw'}}
              title='Product Details'
              info={description}
            //info='Vita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects ofVita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects ofVita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects ofVita-Boomb Vitamin C Soft Chews are a great-tasting and convenient alternative to pills. Each delicious chew supplies 500mg of vitamin C, an important antioxidant.* Antioxidants help protect the body against the damaging effects of'
            />
            <TextContent 
              title='Compaign Details'
              info={requirements}
            />
          </div>
          <div className='collab-detals-your-work-container flex-row flex-between-center'>
            <div className='collab-detals-your-work'>Your Work</div>
            <KeyboardArrowDownIcon 
              className='collab-detals-arrow-down'
            />
          </div>
          <div>
            <Stepper
              step={COLLAB_STEPS.findIndex(each => each.label === order.state)}
              steps={COLLAB_STEPS}
              selected={selectedStep}
              onChange={selectedStep => this.setState({ selectedStep })}
            />
            <div className='collab-detals-stepcontent flex-row flex-center'>
              <StepComponent
                orderId={order.id}
                data={{
                  payRate,
                  platformList,
                  ...order
                }}
                next={({ order = {}, pass } = {}) => {
                  let step = Math.min(COLLAB_STEPS.length, selectedStep + (pass ? 1 : 0));
                  if (order && order.id) {
                    this.setState({ order });
                  }
                  this.setState({
                    step,
                    selectedStep: step
                  })
                }}
                editable={step === selectedStep}
                reject={() => this.setState({ blank: true })}
              />
            </div>
          </div>
          <div className='flex-row flex-between-center collab-detals-bottom'>
            {
              index > 0 ?
                <Back 
                  label="Previous Collaboration"
                  className="collab-detals-bottom-block"	
                  onClick={() => {
                    this.setState({index: index-1, orderId: ordersIdList[index-1]}, async () => {
                      await this.getOrder();
                    })
                  }}
                />
                :
                <div></div>
            }
            {
              index < ordersIdList.length-1 ?
                <Back 
                  label="Next Collaboration"
                  direction="forward"
                  className="collab-detals-bottom-block"	
                  onClick={() => {
                    this.setState({index: index+1, orderId: ordersIdList[index+1]}, async () => {
                      await this.getOrder();
                    })
                  }}
                />
                :
                <div></div>
            }
          </div>
        </div>
        <Footer /> 
      </div>
    );
  }
}

export default withRouter(CollabDetails);