import { Component } from 'react';

import Container from '../Container';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';

import './index.css';

import Stack from '@mui/material/Stack';

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPwd: '',
      newPwd: '',
      confirmPwd: ''
    };
    this.updateParams = this.updateParams.bind(this);
    this.update = this.update.bind(this);
  }

  updateParams(event) {
    const name = event.target.name;
    this.setState({[name]: event.target.value});
  }

  update(){}

  render() {
    return (
      <Container
        title="Change Password"
        subtitle="Manage your password anytime"
      >
        <div className="brand-change-pwd-container">
          <Stack
            direction="column"
            spacing = {3}
          >
            <Input
              name="oldPwd"
              value={this.state.oldPwd}
              label="Old Password"
              placeholder="Type Here..."
              onChange={this.updateParams}
            />
            <Input
              name="newPwd"
              value={this.state.newPwd}
              label="New Password"
              placeholder="Type Here..."
              onChange={this.updateParams}
            />
            <Input
              name="confirmPwd"
              value={this.state.confirmPwd}
              label="Confirm New Password"
              placeholder="Type Here..."
              onChange={this.updateParams}
            />
          </Stack>
          <Button className='brand-change-pwd-btn-update' onClick={this.update}>Update</Button>
        </div>
      </Container>
    )
  }
}