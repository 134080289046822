import { Component } from 'react';

import ReadMore from '../ReadMore';

import './index.css';

export default class TextContent extends Component {
  constructor(props) {
    super(props);
    this.state = {isReadMore: false};
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({isReadMore: !this.state.isReadMore})
  }

  render() {
    const {
      state: {
        isReadMore
      },
			 props:{
        title, 
        info,
        style
      }
    } = this;
    return (
      <div style={style}>
        <div>
          <div className='text-content-title'>{title}</div>
          <div className='text-content-info' style={{maxHeight: isReadMore ? 'none' : '5.9244vw'}}>
            {info}
          </div>
          <div className=''>
            <ReadMore 
              onClick={this.onClick}
              folded={true} 
              reverse={true}
              className="text-content-info-readmore"
              iconClassName="text-content-info-readmore-icon"
            />
          </div>
        </div>
      </div>
    );
  }
}