import './index.css';

export default function Card (props = {}) {
  const {
    top,
    bottom,
    children,
    title,
    subtitle,
    prefix,
    suffix,
    shadowed = true,
    onClick = new Function(),
    className = '',
    titleClassName = '',
    contentClassName = '',
    subtitleClassName = '',
    style = {},
    contentStyle,
    titleStyle,
    subtitleStyle
  } = props;
  return (
    <div
      className={`card${shadowed ? '-shadow' : ''}_default flex-column ${className}`}
      style={style}
      onClick={onClick}
    >
      <div className="card-top_default">{top}</div>
      <div
        className={`flex-grow flex-row flex-between-center ${contentClassName}`}
        style={contentStyle}
      >
        {prefix}
        {
          children ||
            (
              <div className="flex-grow flex-column flex-center-start">
                <div
                  className={`card-title_default ${titleClassName}`}
                  style={titleStyle}
                >
                  {title}
                </div>
                <div
                  className={`card-subtitle_default ${subtitleClassName}`}
                  style={subtitleStyle}
                >
                  {subtitle}
                </div>
              </div>
            )
        }
        {suffix}
      </div>
      {bottom}
    </div>
  );
}
