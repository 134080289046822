import { Component } from 'react';

import Dialog from '../../../../../../components/Dialog';
import Input from '../../../../../../components/Input';
import UploadImage from '../../../../../../components/UploadImage';
import Button from '../../../../../../components/Button';
import Fits from '../../../../../../components/Fits';

import request from '../../../../../../service/request';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import './index.css';

const style = {
  width: '65.1vw',
  height: '39.06vw'
}

export default class ProductAdd extends Component {
  constructor(props) {
    super(props);
    const {
      data: {
        name = '',
        price = '',
        images = '',
        description = '',
        categories = '',
        image_base_url = ''
      } = {}
    } = props;
    const fits = [
      {
        name: 'Cosmetics',
        checked: false
      },
      {
        name: 'Fashion',
        checked: false
      },
      {
        name: 'Fitness',
        checked: false
      },
      {
        name: 'Nutrition',
        checked: false
      },
      {
        name: 'Food',
        checked: false
      },
      {
        name: 'Beverages',
        checked: false
      },
      {
        name: 'Hair Care',
        checked: false
      },
      {
        name: 'Home',
        checked: false
      },
      {
        name: 'Garden',
        checked: false
      },
      {
        name: 'Others',
        checked: false
      }
    ]
    fits.map((each, i) => {
      if(each.name == categories) {
        each.checked = true;
      } else {
        each.checked = false;
      }
    });
    this.state = {
      productName: name || '',
      price: price || '',
      images: images.split(':') || [],
      description: description || '',
      fits: fits,
      deleteDialog: false,
      baseUrl: image_base_url
    }
    this.updateParam = this.updateParam.bind(this);
    this.selectFits = this.selectFits.bind(this);
    this.getImg = this.getImg.bind(this);
    this.setProduct = this.setProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
  }

  updateParam(e) {
    let name = e.target.name;
    this.setState({[name]: e.target.value});
  }

  selectFits(event) {
    let name = event.target.innerText;
    let arr = this.state.fits;
    arr.map((each, i) => {
      if(each.name === name) {
        each.checked = !each.checked
      } else {
        each.checked = false;
      }
    });
    this.setState({fits: arr});
  }

  getImg(id, data) {
    const { image_name, base_url } = data;
    let arr = this.state.images;
    arr[id] = image_name;
    this.setState({
      images: arr,
      baseUrl: base_url
    });
  }

  async setProduct(isNotify) {
    const {
      state: {
        productName,
      	price,
        description,
        images,
        fits
      },
      props: {
        saveFunc,
        closeDialog,
        data: {
          id = ''
        } = {}
      }
    } = this;
    if(productName && price && description && images && fits) {
      let image = '';
      images.forEach((each) => {
        if(each != '') {
          image += each + ':'
        }
      })
      let fit = '';
      fits.map((each, i) => {
        if(each.checked == true) {
          fit = each.name;
        }
      })
      let data = {
        id,
        productName,
        price,
        description,
        image,
        fit,
        isNotify
      }
      saveFunc(data);
      closeDialog();
    }
  }

  async deleteProduct() {
    const {
      deleteFunc,
      data: {
        id = ''
      } = {} 
    } = this.props;
    if(!id) return;
    const { 
      data: {
        detail = ''
      } = {}
    } = await request(['product.delete', {
      params: id
    }]);
    if(!detail) {
      this.setState({deleteDialog: false});
      deleteFunc(id);
    }
  }

  render() {
    const { 
      state: {
        productName,
      	price,
        description,
        baseUrl,
        images,
        fits,
        deleteDialog
      },
      props: {
        isShowDelete = false,
        visible,
        closeDialog
      }
    } = this;
    return (
      <div>
        <Dialog 
          style={style}
          visible={visible}
          close={closeDialog}
          showBack={true}
        >
          {
            <div className="scroll-y product-add-container">
              <div className="product-add-name">
                <Input 
                  isRequire={true}
                  name="productName"
                  value={productName}
                  onChange={this.updateParam}
                  label="Please provide product name"
                  labelClassName="product-add-label-text"
                  width="25.78vw"
                  height="3.38vw"
                  placeholder="Product Name"
                />
              </div>
              <div className="product-add-label-text" style={{marginBottom: '0.3255vw'}}>
								Please provide product retail price
              </div>
              <div className="flex-row flex-start-center product-add-price"> 
                <AttachMoneyIcon sx={{ marginRight: '0.3255vw'}} />
                <Input 
                  isRequire={true}
                  name="price"
                  value={price}
                  onChange={this.updateParam}
                  width="23.89vw"
                  height="3.38vw"
                  placeholder="Price"
                />
              </div>
              <div className="flex-row flex-start-center product-add-label-text">
                <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
								Please upload at least one product image(Please upload image less than 2MB)
              </div>
              <div>
                <div className="product-add-uplaod-block flex-row flex-around">
                  {
                    (() => {
                      let i = 0;
                      let arr = [];
                      while(i < 5){
                        arr.push(<UploadImage getImg={this.getImg} uploadTransId='product.create.image' image={images[i] ? `${baseUrl}${images[i]}` : ''} id={i} key={i}/>);
                        i++;
                      }
                      return arr;
                    })()
                  }
                </div>
                <div className="product-add-select-block">
                  <div className="flex-row flex-start-center product-add-select-text">
                    <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
										Please select product category
                  </div>
                  <div className="flex-row flex-start flex-wrap">
                    {
                      this.state.fits.map((each, i) => {
                        return <Fits key={i} onClick={this.selectFits} value={each} />
                      })
                    }
                  </div>
                </div>
                <div className="product-add-description-block">
                  <div className="flex-row flex-start-center product-add-description-text">
                    <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
											Please add product description
                  </div>
                  <textarea 
                    className="product-add-description-textarea" 
                    name='description'
                    value={description} 
                    onChange={this.updateParam}
                  />
                </div>
              </div>
              <div className="product-add-btn-block">
                <Button 
                  className="product-add-btn-save" 
                  onClick={() => (this.setProduct(false))}
                  colorSet={
                    productName && price && description && images && fits ?
        						'blue' : 'gray'
                  }
                >
									Save
                </Button>
                <Button 
                  className="product-add-btn-save-notify" 
                  onClick={() => (this.setProduct(true))}
                  colorSet={
                    productName && price && description && images && fits ?
        						'blue' : 'gray'
                  }
                >
									Save & Notify Influencers
                </Button>
                {
                  isShowDelete ?
                    <Button 
                      className="product-add-btn-delete" 
                      colorSet='red'
                      onClick={() => this.setState({deleteDialog: true})}
                    >
									    Delete Product
                    </Button>
                    :
                    <></>
                }
              </div>
            </div>
          }
        </Dialog>
        <Dialog
          visible={deleteDialog}
          className='flex-column flex-center'
        >
          {
            <div className='flex-column flex-center'>
              <div className='product-add-delet-dialog'>
                Are you sure to delete this product?
              </div>
              <div className='product-add-btn-group flex-row'>
                <Button className="product-add-btn-delete" colorSet='white' onClick={() =>{
                  this.setState({deleteDialog: false}); 
                  this.deleteProduct();
                }}>
									Yes
                </Button>
                <Button className="product-add-btn-delete" colorSet='black' onClick={() => this.setState({deleteDialog: false})}>NO</Button>
              </div>
            </div>
          }
        </Dialog>
      </div>
    );
  }
}