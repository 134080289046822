import { Component } from 'react';

import Avatar from '../../../../../../components/Avatar';
import Quota from '../../../../../../components/Quota';
import Button from '../../../../../../components/Button';
import ReadMore from '../../../../../../components/ReadMore';

import { withRouter } from 'react-router';

import './index.css';

class CollabCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      props: {
        image = '',
        name = '',
        rate= '',
        endDate = '',
        quota,
        status,
        router,
        history: {
          push
        }
      }
    }  = this;
    let quotaObj = {  // 每个平台的申请名额
      instagram: 0,
      youtube: 0,
      instagram_story: 0
    }
    if(quota != null) {
      Object.assign(quotaObj, quota);
    }
    return (
      <div className='influencer-collaboration-card'>
        <div className="flex-column flex-center">
          <Avatar
            avatar={image}
            className="influencer-collaboration-card-avatar"
            shape="cubic"
          />
        </div>
        <div className='influencer-collaboration-card-container'>
          <div className='influencer-collaboration-card-name'>{name}</div>
          <div className='influencer-collaboration-card-block flex-row flex-between-center'>
            <div className="flex-row flex-wrap">
              {
                Object.keys(quotaObj).map((each, i) =>
                  <Quota key={i} platform={each} count={quotaObj[each]} />
                )
              }
            </div>
            <Button className="influencer-collaboration-card-applied" colorSet="pink">{status}</Button>
          </div>
          <div className='influencer-collaboration-card-pay'>
						Pay: ${rate}
          </div>
          <div className='influencer-collaboration-card-end-date'>
						End Date: {endDate}
          </div>
          <div className='flex-row-reverse'>
            <ReadMore
              label="View Details"
              className="influencer-collaboration-viewdetails-label"
              iconClassName="influencer-collaboration-viewdetails-icon"
              onClick={() =>{
                push(router)
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CollabCard);