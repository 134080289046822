import { Component } from 'react';

import Button from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog';

import request from '../../../../../../service/request';

import { withRouter } from 'react-router';
import { INFLUENCER_PATH } from '../../../../../../router/config';

import './index.css';

class SelectedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDialog: false
    };
    this.confirm = this.confirm.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  async confirm() {
    const { 
      orderId,
      next = new Function()
    } = this.props;
    const { data } = await request(['order.update.confirm_brand_pay_offer', {
      params: orderId,
      data: {
        confirmed: true
      }
    }]);
    data && !data.detail && next({data, pass: true});
    this.closeDialog();
  }

  openDialog() {
    this.setState({isShowDialog: true})
  }

  closeDialog() {
    this.setState({isShowDialog: false});
  }

  render() {
    const { 
      props: {
        editable,
        data: {
          brand_pay_offer,
          influencer_rate,
          collaboration: {
            product: {
              owner: {
                brand: {
                  name
                }
              }
            }
          }
        },
        history: {
          push
        }
      }
    } = this;
    return (
      <div className='flex-row flex-around-center selected-comp-container'>
        <div className='flex-column flex-start-center selected-comp-brand-pay-offer-container'>
          <div className='selected-comp-brand-pay-offer'>Brand Pay Offer</div>
          <div className='flex-row flex-center selected-comp-payments'>{brand_pay_offer}</div>
          {
            editable && (
              <div className='flex-column flex-center'>
                {
                  brand_pay_offer ?
                    <Button className='selected-comp-btn' onClick={this.openDialog}>Confirm</Button>
                    :
                    <div></div>
                }
                <Button className='selected-comp-btn' onClick={() => push({pathname:`${INFLUENCER_PATH}/inbox`, state: {name: `${name}`}})}>Negotiate Rate</Button>
              </div>
            )
          }  
        </div>
        <div className='flex-column flex-around-center selected-comp-rate-applied-container'>
          <div className='selected-comp-rate-applied'>Rate Applied</div>
          <div className='selected-comp-rate-applied-payments'>{influencer_rate}</div>
        </div>
        <Dialog
          close={this.closeDialog}
          visible={this.state.isShowDialog}
        >
          {
            <div className='flex-column flex-center'>
              <div className='selected-comp-dialog-pay'>Pay: ${brand_pay_offer}</div>
              <div className='selected-comp-dialog-confirm'>Confirm this rate?</div>
              <div className='selected-comp-dialog-tips'>(We will send your confirmation to the brand, it cannot be undone)</div>
              <div className='selected-comp-dialog-btn-container flex-row flex-between'>
                <Button className="selected-comp-dialog-btn" colorSet="white" onClick={this.confirm}>Confirm</Button>
                <Button className="selected-comp-dialog-btn" colorSet="black" onClick={this.closeDialog}>Cancel</Button>
              </div>
            </div>
          }
        </Dialog>
      </div>
    );
  }
}

export default withRouter(SelectedComponent);