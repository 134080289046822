import { Component } from 'react';
import Input from '../../../../../../../../components/Input';
import PlainLink from '../../../../../../../../components/PlainLink';
import Dialog from '../../../../../../../../components/Dialog';
import SubmitTrackingButton from './components/SubmitTrackingButton';

import './index.css';

import request from '../../../../../../../../service/request';

import { checkURL } from '../../../../../../../../utils/util';

export default class RateConfirmed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: '',
      visible: false
    };

    this.upload = this.upload.bind(this);
    this.updateLink = this.updateLink.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  updateLink(event) {
    const value = event.target.value;
    this.setState({link: value});
  }

  showDialog() {
    this.setState({visible: true});
  }

  closeDialog() {
    this.setState({visible: false});
  }

  async upload() {
    const {
      state: { link },
      props: {
        id,
        data: {
          tracking_links = []
        },
        next = new Function()
      }
    } = this;
    if(checkURL(link)) {
      const {
        data
      } = await request(['order.update.upload_tracking_links', {
        params: id,
        data: { tracking_links: [...(tracking_links || []), link] }
      }]) || {};
      if (data && !data.detail) {
        next({ order: data });
        this.setState({ link: '' });
      }
      return true;
    } else {
      //链接不合法
      this.showDialog();
      return false;
    }
  }

  render () {
    const {
      props: {
        className = '',
        data: {
          influencer,
          tracking_links = []
        }
      },
      state: {
        link,
        visible
      },
      upload
    } = this;

    return (
      <div className="flex-row">
        <div className="flex-grow" />
        <div className={`flex-column flex-start-center ${className}`}>
          <div className='flex-column flex-center brandcollabrc-title-container'>
            <div className="brandcollabrc-title">Please Upload Tracking Link Here</div>
            <div className='brandcollabrc-subtitle'>(Or other resources)</div>
          </div>
          <Input
            className="brandcollabrc-input"
            value={link}
            onChange={this.updateLink}
            placeholder="URL"
          />
          <SubmitTrackingButton
            className="btn-brandcollabrc"
            influencer={influencer}
            submit={upload}
          />         
          <div className='brandcollabrc-link-container'>
            {
              (tracking_links || []).map((each, i) => (
                <div key={i} className="brandcollabrc-link flex-self-start flex-center flex-row">
                You sent the link to the influencer:&nbsp;<PlainLink to={each} href={true} colorSet="black">Link</PlainLink>
                </div>
              ))
            }
          </div>
        </div>
        <div className="flex-grow" />
        <Dialog
          className='brandcollabrc-dialog flex-row flex-center'
          visible={visible}
          close={this.closeDialog}
        >
          {
            <div>Incorrect URL</div>
          }
        </Dialog>
      </div>
    );
  }
}
