import { Component } from 'react';

import PublishedCard from '../../../../../../components/PublishedCard';
import CommentList from '../../../../../../components/CommentList';

import request from '../../../../../../service/request';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './index.css';

export default class PublishedComponet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: []
    }
    this.getComments = this.getComments.bind(this);
  }

  async getComments() {
    const {
      props: {
        orderId
      } = {}
    } = this; 
    const {
      data: {
        results = []
      }
    } = await request(['order.list.comment', {
      params: orderId,
      query: {
        page: 1,
        page_size: 10
      }
    }]);
    results && this.setState({
      comments: results
    });
  }

  async componentDidMount() {
    await this.getComments();
  }

  render() {
    const { 
      state: {
        comments
      },
      props: {
        data: {
          //platformList,
          social_links,
          influencer: {
            statistics
          }
        }
      }
    } = this;
    return (
      <div className='flex-row flex-start-center flex-wrap'>
        {
          (() => {
            //TODO
            //let arr = platformList.filter(each => {
            //  for(let i in social_links) {
            //    if(social_links[i].source == each.platform) {
            //      each.link = social_links[i].link;
            //      return each;
            //    }
            //  }
            //});
            return social_links.map((each, i) => {
              return (
                <PublishedCard 
                  key={i}
                  data={{each, ...statistics}}
                  className='published-card-item'
                />
              )
            })
          })()
        }
        <div className='published-comp-comments-container'>
          <div className='published-comp-comments-block flex-row flex-between-center'>
            <div className='published-comp-comments'>Comments</div>
            <KeyboardArrowDownIcon 
              className='published-comp-arrow-down'
            />
          </div>
          {
            comments.length > 0 ?
              <div className="flex-self-start">
                <CommentList className="brandcollabpublished-comment" data={comments} />
              </div>
              :
              <div>(No comments yet)</div>
          }          
        </div>
      </div>
    );
  }
}
