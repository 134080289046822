import { Component } from 'react';
import './index.css';

export default class Container extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, subtitle, children, style } = this.props;
    return (
      <div>
        <div className="brand-setting-container" style={style}>
          <div>
            <div className="brand-setting-title">{title}</div>
            <div className="brand-setting-subtitle">{subtitle}</div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}
