import { Switch } from 'react-router-dom';
import SubRoute from './router/SubRoute';

import Dialog from './components/Dialog';
import Button from './components/Button';

import { observer } from 'mobx-react';
import { useRootStore } from './store';

import './App.css';

function App({ routes }) {
  const { exception } = useRootStore();
  return (
    <div className="App">
      <Dialog
        showBack={false}
        visible={exception.message != ''}
        className='flex-column flex-center'
      >
        {
          <div className='flex-column flex-center'>
            <div className='exception-dialog-font'>{exception.message}</div>
            <Button className="exception-dialog-btn" colorSet="white" onClick={() => exception.setMessage('')}>OK</Button>
          </div>
        }
      </Dialog>
      <Switch>
        {
          routes.map((each, i) => (
            <SubRoute key={i} {...each} />
          ))
        }
      </Switch>
    </div>
  );
};

export default observer(App);