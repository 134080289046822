import { Component } from 'react';

import BgImage from '../BgImage';
import { isFunction } from '../../utils/type';

const DEFAULT_INTERVAL = 1500;
const DEFAULT_WIDTH = '26.04vw';
const DEFAULT_HEIGHT = '26.04vw';
const DEFAULT_BGCOLOR = '#fff';
const DEFAULT_OPACITY = 1;

export default class ImageCarousel extends Component {
  constructor(props = {}) {
    super(props);
    let {
      interval = DEFAULT_INTERVAL,
      className = '',
      shadowClassName = '',
      style = {},
      shadowStyle = {}
    } = props;
    const defaultStyle = { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT };
    if (className) {
      style = {};
    } else {
      style = { ...defaultStyle, ...style };
    };
    if (shadowClassName) {
      shadowStyle = { ...style };
    } else {
      shadowStyle = {
        ...defaultStyle,
        backgroundColor: DEFAULT_BGCOLOR,
        opacity: DEFAULT_OPACITY,
        ...style,
        ...shadowStyle
      };
    };
    this.state = { index: 0, interval, style, shadowStyle };
  }

  componentDidMount() {
    this.timer = setInterval(
      () => {
        const { props: { images, callback }, state: { index } } = this;
        let next = index < images.length - 1
          ? index + 1
          : 0
        this.setState({
          index: next
        });
        isFunction(callback) && callback(next);

      },
      this.state.interval
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const {
      props: {
        images = [],
        className = '',
        shadowClassName = '',
        children,
        image
      },
      state: { index, style, shadowStyle }
    } = this;
    return (
      <div className={`relative ${className}`} style={style}>
        <div className={`absolute z-index-2 ${className}`} style={style}>
          {children}
        </div>
        <div
          className={`absolute z-index-1 ${className} ${shadowClassName}`}
          style={shadowStyle}
        />
        <BgImage
          key={index}
          image={image}
          className={`${className} ${images[index]}`}
          style={style}
        />
      </div>
    );
  }
};
