import { PureComponent  } from 'react';
import Avatar from '../Avatar';
import Card from '../Card';

import './index.css';

import ReadMore from '../ReadMore';

import { withRouter } from 'react-router';

import { toCamel } from '../../utils/string';
import { formatStatisticsByLevel, assembleFullName } from '../../utils/util';
import { BRAND_PATH } from '../../router/config';

const STATISTICS = ['likes', 'views', 'followers'];

class InfluencerBusiCard extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      data: {
        user: id,
        avatar_base_url = '',
        avatar = '',
        first_name = '',
        last_name = '',
        statistics = {
          likes: {},
          views: {},
          followers: {},
          engagement_rate: ''
        }
      } = {},
      className = '',
      history: {
        push
      }
    } = this.props;
    return (
      <Card className={`influencer-busicard ${className}`}>
        <div className="full flex-row flex-start">
          <Avatar
            avatar={`${avatar_base_url}${avatar}`}
            className="influencer-busicard-line influencer-busicard-avatar"
            shape="square"
          />
          <div className="influencer-busicard-info flex-column flex-start">
            <div className="influencer-busicard-plain">{assembleFullName(first_name, last_name)}</div>
            <div className="flex-row">
              <div className="influencer-busicard-statistics-section flex-column flex-between-start">
                {
                  STATISTICS.map((each, i) =>
                    <div key={i} className="flex-row flex-start-center">
                      <div className="influencer-busicard-plain">
                        {
                          formatStatisticsByLevel(
                            Object.keys(statistics&&statistics[each] || {})
                              .reduce((prev, current) => prev + statistics[each][current], 0)
                          ) || 0
                        }
                      </div>
                      <div className="influencer-busicard-light">
                        {toCamel(each)}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="flex-grow" />
          <div className="influencer-busicard-line flex-column flex-between-end">
            <div className="influencer-busicard-engage flex-column flex-start-center">
              <div className="influencer-busicard-bold">{statistics&&statistics.engagement_rateto}%</div>
              <div className="influencer-busicard-light">Engagement Rate</div>
            </div>
            <ReadMore
              className="influencer-busicard-readmore-label"
              iconClassName="influencer-busicard-readmore-icon"
              onClick={() => push(`${BRAND_PATH}/influencer/${id}`)}
            />
          </div>
        </div>
      </Card>
    );
  }
}

export default withRouter(InfluencerBusiCard);
