export const getHorizontalSuffix =
  orientation =>`_${parseInt(orientation) ? 'right' : 'left'}`;
export const getVerticalSuffix =
  orientation =>`_${parseInt(orientation) ? 'bottom' : 'top'}`;

export const getAxisSuffix =
  orientation =>`${parseInt(orientation) ? 'y' : 'x'}`;
export const getFlexSuffix =
  orientation =>`${parseInt(orientation) ? 'column' : 'row'}`;

export const CHART_PALETTE = [
  '#ff33e7',
  '#8657ff',
  '#ffbb00',
  '#4cb8c2',
  '#696969'
];

export const paletteCalculator =
  index => CHART_PALETTE[index % CHART_PALETTE.length];
