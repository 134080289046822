import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Navigation from '../../layout/Navigation';
import Logo from '../../components/Logo';
import Avatar from '../../components/Avatar';
import './index.css';

import { BRAND_ROUTE } from '../../router/config';

import {observer} from 'mobx-react';
import { RootStoreContext } from '../../store';

import request from '../../service/request';

//@observer
class Brand extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    this.state = {
      userInfo: {}
    };
    this.getBrandInfo = this.getBrandInfo.bind(this);
  }

  async getBrandInfo() {
    const { brand } = this.context;
    const { 
      data = {},
      data: {
        user:{
          id,
          email
        },
        avatar_base_url,
        name,
        avatar,
        website,
        secondary_email,
        contact_number,
        country,
        position,
        industry,
        company_name,
        employees_number,
        market_budget_yearly
      }
    } = await request(['me.read']);
    this.setState({userInfo: data});
    brand.setInfo({
      id,
      name,
      website,
      primaryEmail: email,
      secondaryEmail: secondary_email,
      contactNumber: contact_number,
      country,
      employeesNumber: employees_number,
      marketBudgetYearly: market_budget_yearly,
      companyName: company_name,
      avatarBaseUrl: avatar_base_url,
      avatar,
      position,
      industry
    });
  }

  async componentDidMount() {
    await this.getBrandInfo();
  }

  render() {
    const {
      context: {
        brand: {
          info: {
            name,
            avatarBaseUrl,
            avatar,
            position,
            companyName
          }
        }
      },
      props: {
        match: { url },
        history: { push }
      }
    } = this;
    const { routes } = BRAND_ROUTE;
    const route = routes.find(each => (url.search(each.path) !== -1));
    const Container = (route || {}).component || Redirect;
    const mode = route && route.path.match(/overview/i) ? 'light' : 'dark';

    return (
      <div className="flex-row flex-start">
        {
          route
            ? (
              <>
                <Navigation
                  routes={routes}
                  path={url}
                  className="navigation"
                  bgClassName={`navigation_${mode}`}
                  itemClassName="brand-nav-item"
                  selectedItemClassName="brand-nav-item_selected"
                  iconStyle={{
                    sx: { fontSize: 18 },
                    style: { marginRight: 10 }
                  }}
                  prefix={(
                    <div
                      className="brand-nav-prefix flex-column flex-center"
                      style={{ width: 'inherit' }}
                    >
                      {
                        [
                          <Logo key={0} className="brand-logo" />,
                          <Avatar
                            key={1}
                            className="manager-avatar"
                            avatar={`${avatarBaseUrl}${avatar}`}
                          />,
                          <div key={2} className="manager-name">{name}</div>,
                          <div key={3} className="manager-position">{position}</div>,
                          <div key={4} className="manager-brandname">@{companyName}</div>
                        ].map((each, i) => (
                          <div key={i} className="flex-row flex-center" >
                            {each}
                          </div>
                        ))
                      }
                    </div>
                  )}
                  onClick={push}
                />
                <div className={`brand-container brand-container_${mode}`}>
                  <Container />
                </div>
              </>
            )
            : <Container to={routes[0].path} />
        }
      </div>
    );
  }
};

export default observer(Brand);