import { Component } from 'react';

import Divider from '@mui/material/Divider';
import './index.css';

export default class InfluencerNavigation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { 
      path,
      prefix,
      suffix,
      children, 
      routes, 
      onClick, 
      selectedItemClassName, 
      itemClassName, 
      selectedItemStyle,
      itemStyle,
      footer
    } = this.props;
    return (
      <div className="influencer-navigation-container flex-column">
        <div>
          <div className="influencer-navigation-block flex-row flex-start-center">
            {prefix}
            <div>
              <div className="flex-row flex-around influencer-navigation-text">
                {
                  routes.map((each, i) => {
                    let selected = each.path === path;
                    return (
                      each.tab ?
                        <div 
                          key={i}
                          className={`${selected
                            ? selectedItemClassName
                            : itemClassName
                          }`}
                          style={selected ? selectedItemStyle : itemStyle}
                          onClick={() => onClick(each.path)}
                        >
                          {each.title}
                        </div>
                        :
                        null
                    )
                  })
                }
              </div>
            </div>
            {suffix}
          </div>
          <Divider sx={{ borderColor: '#999'}}/>
        </div> 
        <div className="overflow-y influencer-navigation-children">
          {children}
        </div>
        <div className="influencer-navigation-footer">
          {footer}
        </div>
      </div>
    );
  }
}
