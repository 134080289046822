import { Component } from 'react';

import Switch from '../../../../../../../../components/Switch';

import Divider from '@mui/material/Divider';

import './index.css';

export default class SwitchTap extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, summary, name, onChange } = this.props;
    return (
      <div>
        <div className="flex-row flex-between-center">
          <div>
            <div className="switchtab-title">{title}</div>
            <div className="switchtab-summary inline-block">{summary}</div>
          </div>
          <Switch name={name} className="inline-block" onChange={onChange}/>
        </div>
        <div><Divider sx={{mt: '0.9765vw', mb: '2.6vw', borderColor: 'black'}}/></div>
      </div>
    )
  }
}