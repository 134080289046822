import { Component } from 'react';

import './index.css'
import Select from '../../../../../../components/Select';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import { calcDays, formatPhoneNumber, formatSSN } from '../../../../../../utils/util';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import {countryList} from '../../../../../../utils/countryList';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId: '',
      isNextStep: false,
      avatar: '',
      baseUrl: ''
    },
    this.updateParams = this.updateParams.bind(this);
    this.getImg = this.getImg.bind(this);
  }

  updateParams(event, label) {
    const name = event.target.name;
    let value = event.target.value;
    //删除country和state使用select逻辑
    //if(name === 'country') {
    //  for(let i = 0; i < countries.length; i++) {
    //    if(countries[i].code == event.target.value) {
    //      this.setState({countryId: countries[i].id});
    //      this.props.updateInfo({
    //        country: value
    //      });
    //    }
    //  }
    //} else {
    //  this.props.updateInfo({[name]: event.target.value});
    //}
    if(name == 'isCheck') {
      value = event.target.checked;
    }
    this.props.updateInfo({[name]: value});
    //处理按钮，若存在未输入项，则不可点击
    setTimeout(() => {
      const {
        props: {
          info
        }
      } = this;
      for(let each in info) {
        if((info[each] == '' && each != 'address2') || info['isCheck'] == false) {
          this.setState({isNextStep: false});
          return;
        }
      }
      this.setState({isNextStep: true});
    }, 0);
  }

  getImg(id, data) {
    const { updateInfo } = this.props;
    const { avatar, base_url } = data;
    this.setState({
      avatar,
      baseUrl: base_url
    });
    updateInfo({avatar});
  }

  render() {
    const {
      state: {
        isCheck,
        isNextStep
      },
      props: {
        info,
        setStep
      }
    } = this;
    return (
      <Box sx={{textAlign: 'right'}} className="flex-column flex-between-center">
			  <div className="signup-title">PARTNER WITH OVER 500+ BRANDS</div>
        <div className="signup-subtitle">Luxury Skincare, Fitness, and Lifestyle well-being</div>
        <Box sx={{
          display: 'inline-block',
          mt: '0.78125vw',
          textAlign: 'left',
          width: '46.61vw',
          height: '29.75vw',
          'overflow-y': 'scroll'
        }}>
          <Box sx={{
            mt: '0.9114vw',
            pl: '1.9531vw',
            mb: '1.3vw'
          }}>
            <Grid container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={6}>
                <Input
                  isRequire={true}
                  value={info.firstName}
                  name="firstName"
                  label="First Name"
                  width="19.59vw"
                  height="3.32vw"
                  colorSet="white"
                  onChange={this.updateParams}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  isRequire={true}
                  value={info.lastName}
                  name="lastName"
                  label="Last Name"
                  width="19.59vw"
                  height="3.32vw"
                  colorSet="white"
                  onChange={this.updateParams}
                />
              </Grid>
              <Grid item xs={6}>
                <div className='flex-row flex-start-center'>
                  <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
                  <div className='signup-birth-date-font'>Birth Date(YYYY/MM/DD)</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around'
                  }}
                >
                  <Select
                    //isRequire={true}
                    isRed='true'
                    value= {info.birthYear}
                    name="birthYear"
                    //label="Birth Date"
                    width="6.31vw"
                    onChange={this.updateParams}
                  >
                    <option style={{display: 'none'}}></option>
                    {
                      (()=>{
                        let arr = [];
                        for(let i = 2022; i > 1950; i--){
                          arr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return arr;
                      })()
                    }
                  </Select>
                  <Select
                    //isRequire={true}
                    isRed='true'
                    value={info.birthMonth}
                    name="birthMonth"
                    width="6.31vw"
                    //style={{marginTop: '0.2604vw'}}
                    onChange={this.updateParams}
                  >
                    <option style={{display: 'none'}}></option>
                    {
                      (()=>{
                        let arr = [];
                        for(let i = 1; i <= 12; i++){
                          arr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return arr;
                      })()
                    }
                  </Select>
                  <Select
                    //isRequire={true}
                    isRed='true'
                    value={info.birthDay}
                    name="birthDay"
                    width="6.0546vw"
                    //style={{marginTop: '0.2604vw'}}
                    onChange={this.updateParams}
                  >
                    <option style={{display: 'none'}}></option>
                    {
                      (()=>{
                        let arr = [];
                        let days = calcDays(info.birthYear, info.birthMonth);
                        for(let i = 1; i <= days; i++){
                          arr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return arr;
                      })()
                    }
                  </Select>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                  <Select
                    //isRequire={true}
                    isRed='true'
                    isRequire={true}
                    value={info.country}
                    name="country"
                    label="Country"
                    width="9vw"
                    //style={{marginTop: '0.2604vw'}}
                    onChange={this.updateParams}
                  >
                    <option style={{display: 'none'}}></option>
                    {
                      countryList.map(country => <option key={country} value={country}>{country}</option>)
                    }
                  </Select>
                  {/*<Input*/}
                  {/*  isRequire={true}*/}
                  {/*  value={info.country}*/}
                  {/*  name="country"*/}
                  {/*  label="Country"*/}
                  {/*  width="9vw"*/}
                  {/*  height="3.32vw"*/}
                  {/*  colorSet="white"*/}
                  {/*  onChange={this.updateParams}*/}
                  {/*/>*/}
                  <Input
                    isRequire={true}
                    value={info.state}
                    name="state"
                    label="State"
                    width="9vw"
                    height="3.32vw"
                    colorSet="white"
                    onChange={this.updateParams}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Input
                  isRequire={true}
                  value={info.address}
                  name="address"
                  label="Address"
                  width="45vw"
                  height="3.32vw"
                  colorSet="white"
                  onChange={this.updateParams} />
              </Grid>
              <Grid item xs={12}>
                <Input
                  value={info.address2}
                  name="address2"
                  label="Address Line 2"
                  width="45vw" height="3.32vw"
                  colorSet="white"
                  onChange={this.updateParams}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  isRequire={true}
                  value={info.city}
                  name="city"
                  label="City"
                  width="19.59vw"
                  height="3.32vw"
                  colorSet="white"
                  onChange={this.updateParams}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  isRequire={true}
                  value={info.postalCode}
                  name="postalCode"
                  label="Postal Code"
                  pattern="[0-9]{5}"
                  width="19.59vw"
                  errMsg={'Please enter the correct format'}
                  height="3.32vw"
                  colorSet="white"
                  placeholder="xxxxx"
                  onChange={this.updateParams} />
              </Grid>
              <Grid item xs={6}>
                <Input
                  isRequire={true}
                  value={formatPhoneNumber(info.phoneNum)}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  name="phoneNum"
                  label="Phone Number"
                  width="19.59vw"
                  height="3.32vw"
                  errMsg={'Please enter the correct format'}
                  colorSet="white"
                  placeholder="xxx-xxx-xxxx"
                  onChange={this.updateParams}
                />
              </Grid>
              {/*<Grid item xs={6}>
                <Input
                  value={info.zipCode}
                  name="zipCode"
                  label="Please Enter 6 Digits Verification Code"
                  width="19.59vw"
                  height="3.32vw"
                  colorSet="white"
                  placeholder="xxxxxx"
                  onChange={this.updateParams}
                />
              </Grid>
              <Grid item xs={12}>
                <Button colorSet="white" className="btn-verify">Verify</Button>
              </Grid>*/}
              <Grid item xs={6}>
                <Input
                  isRequire={true}
                  value={formatSSN(info.ssn)}
                  name="ssn"
                  label="Social Secuity Number(SSN)"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{4}"
                  width="19.59vw"
                  height="3.32vw"
                  colorSet="white"
                  placeholder="xxx-xx-xxxx"
                  errMsg={'Please enter the correct format'}
                  onChange={this.updateParams}
                />
                <div className="ssn-text">
                  SSN will be used to process payments, otherwise user cannot apply for any paid collaboration.
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div className="text">
          <Checkbox
            name='isCheck'
            value={isCheck}
            onChange={this.updateParams}
            sx={{
              color: 'rgb(235, 235, 235)',
              '&.Mui-checked': {
                color: 'rgb(255, 82, 82)'
              }
            }
            }
          />
          <span>By proceeding, I agree to <span className="text-blue">BCom&apos;s </span>Terms and Conditions and acknowledge that I have read the<span className="text-blue"> Privacy </span>terms.</span>
        </div>
        <div style={{
          display: 'flex',
          gap: '20px'
        }}>
          <Button
            className="btn-next-step"
            colorSet={isNextStep ? 'blue' : 'gray'}
            disabled={!isNextStep}
            onClick={() => setStep(3)}
          >
						Next Step(2/3)
          </Button>
        </div>
      </Box>
    );
  };
};
