import Logo from '../../components/Logo';
import ImageCarousel from '../../components/ImageCarousel';
import Button from '../../components/Button';
import PlainLink from '../../components/PlainLink';

import { getHorizontalSuffix } from '../../utils/style';
import './index.css';
import './bg.css';

import { ROLE } from '../../store/user/auth';
import { toCamel } from '../../utils/string';

const roles = Object.keys(ROLE).reduce(
  (prev, each) => {
    const role = ROLE[each];
    const { label, article } = role;
    prev[label.toLowerCase()] = { article };
    return prev;
  },
  {});

const images = [
  'bg-sign-0',
  'bg-sign-1',
  'bg-sign-2',
  'bg-sign-3',
  'bg-sign-4'
];
const imageStyle = {
  width: '30.07vw',
  height: '56.11vw'
};
const BTN_LABEL = {
  0: 'SignIn',
  1: 'SignUp'
};

export default function SignLayout (props = {}) {
  const {
    orientation = 0,
    title = '',
    type = '0',
    role,
    disableSubTitle = false
  } = props;
  const suffix = getHorizontalSuffix(orientation);
  const flexOrientation = `flex-row${parseInt(orientation) ? '-reverse' : ''}`;
  const btnType = BTN_LABEL[Math.abs(parseInt(type) - 1)];
  const link = `/bcom/${(role || '').toLowerCase()}/${(btnType || {}).toLowerCase()}`;
  return (
    <div className="signin-page-wrap">
      <div className="signin-page flex-column">
        <Logo className={`sign-logo ${flexOrientation}`} />
        <div className={`signin-form signin-form${suffix} ${flexOrientation}`}>
          {props.children}
        </div>
      </div>
      <div
        className={`absolute signin-image-wrap signin-image-wrap${suffix}`}
        style={{ ...imageStyle }}
      >
        <ImageCarousel
          images={images}
          style={imageStyle}
          shadowClassName="image-shadow"
        >
          <div
            className="flex-column flex-between-center"
            style={{ height: 'inherit' }}
          >
            <div className="image-title">{title}</div>
            <div className="flex-grow" />
            <div className="image-bottom flex-column flex-between-center">
              {
                role == 'influencer' ?
                  <PlainLink
                    to={link}
                    colorSet="white"
                    underline={false}
                  >
                    <Button
                      className="sign-btn sign-btn-font"
                      colorSet="transparent"
                    >
                      {btnType === 'SignIn' && 'Sign In'}
                      {btnType === 'SignUp' && 'Sign Up'}
                    </Button>
                  </PlainLink>
                  :
                  <></>
              }
              {!disableSubTitle && (
                <div
                  className="sign-btn-font"
                  style={{
                    color: '#fff'
                  }}
                >
                  Join BCom as {roles[role].article} {toCamel(role)}
                </div>
              )}
            </div>
          </div>
        </ImageCarousel>
      </div>
    </div>
  );
};
