import PublishedList from '../PublishedList';
import ConfirmPayButton from './components/ConfirmPayButton';
import Button from '../../../../../../../../components/Button';

import './index.css';

import request from '../../../../../../../../service/request';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../../../../../../../router/config';

function DraftApproved(props) {
  const {
    id,
    data: {
      influencer,
      influencer: {
        first_name = '',
        last_name = ''
      },
      social_links = []
    } = {},
    next = new Function(),
    className = '',
    history: {
      push
    }
  } = props;
  const confirm = async () => {
    const {
      data
    } = await request(['order.update.confirm_social_links', {
      params: id,
      data: {
        id
      }
    }]) || {};
    data && !data.detail && next({
      pass: true,
      order: data
    });
  };

  return (
    <div className={`flex-column flex-start-center ${className}`}>
      <PublishedList links={social_links} status="published" influencer={influencer} />
      {
        social_links ?
          <ConfirmPayButton
            className="btn-brandcollabda"
            influencer={influencer}
            confirm={confirm}
          />
          :
          <div className='flex-column flex-center'>
            <div className='product-received-comp-not-receiving'>Influencer has not yet posted after 7 days of approval?</div>
            <Button className='product-received-comp-btn' onClick={() => {
              push({pathname: `${BRAND_PATH}/inbox`, state: {name: `${first_name} ${last_name}`}});
            }}>Contact Influencer</Button>
          </div>
      }
    </div>
  );
}

export default withRouter(DraftApproved);