import { Component } from 'react';

import AllCategories from './components/AllCategories';
import Select from '../../../../../../components/Select';
import FilterCheckBox from '../../../../../../components/FilterCheckBox';

import './index.css';

//const categories = ['Cosmetics', 'Fashion', 'Fitness & Nutrition', 'Food & Beverages', 'Hair Care', 'Home & Garden'];

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platformList: [
        {
          icon: require('../../../../../../assets/images/logo/logo-instagram.webp'),
          name: 'Instagram',
          value: 'instagram',
          checked: false
        },
        {
          icon: require('../../../../../../assets/images/logo/logo-tiktok.webp'),
          name: 'TikTok',
          value: 'tiktok',
          checked: false
        },
        {
          icon: require('../../../../../../assets/images/logo/logo-instagram_story.webp'),
          name: 'Instagram Story',
          value: 'instagram_story',
          checked: false
        },
        {
          icon: require('../../../../../../assets/images/logo/logo-youtube.webp'),
          name: 'YouTube',
          value: 'youtube',
          checked: false
        }
      ],
      levelList: [
        {
          name: '0-1k',
          value: '0-1000',
          checked: false
        },
        {
          name: '1k-5k',
          value: '1000-5000',
          checked: false
        },
        {
          name: '5k-10k',
          value: '5000-10000',
          checked: false
        },
        {
          name: '10k-100k',
          value: '10000-100000',
          checked: false
        },
        {
          name: '100k-1M',
          value: '100000-1000000',
          checked: false
        },
        {
          name: '1M +',
          value: '1000000-',
          checked: false
        }
      ],
      categories: [
        {
          name: 'Cosmetics',
          value: 'cosmetics',
          checked: false
        },
        {
          name: 'Fashion', 
          value: 'fashion', 
          checked: false
        },
        {
          name: 'Fitness', 
          value: 'fitness', 
          checked: false
        },
        {
          name: 'Nutrition', 
          value: 'nutrition', 
          checked: false
        },
        {
          name: 'Food',
          value: 'food',
          checked: false
        },
        {
          name: 'Beverages',
          value: 'beverages',
          checked: false
        },
        {
          name: 'Hair Care',
          value: 'hair care',
          checked: false
        },
        {
          name: 'Home',
          value: 'home',
          checked: false
        },
        {
          name: 'Garden',
          value: 'garden',
          checked: false
        },
        {
          name: 'Others',
          value: 'others',
          checked: false
        }
      ],
      sortBy: ''
    }
    this.filterChecked = this.filterChecked.bind(this);
    this.selectSortBy = this.selectSortBy.bind(this);
  }

  filterChecked(event, type) {
    const {
      state: {
        categories,
        platformList,
        sortBy,
        levelList
      },
      props: {
        searchCollab
      }
    } = this;
    let name = event.target.name;
    let category = event.target.outerText;
    switch(type) {
    case 'categories': 
      categories.map((each, i) => {
        if(each.name === category) {
          each.checked = !each.checked;
        } else {
          each.checked = false;
        }
      });
      this.setState({categories});
      break;
    case 'platformList': 
      platformList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({platformList});
      break;
    case 'levelList': 
      levelList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({levelList});
    }
    searchCollab({
      categories,
      platformList,
      sortBy,
      levelList
    });
  }

  selectSortBy(event) {
    const {
      state: {
        categories,
        platformList,
        levelList
      },
      props: {
        searchCollab
      }
    } = this;
    this.setState({sortBy: event.target.value}, () => {
      searchCollab({
        categories,
        platformList,
        levelList,
        sortBy: this.state.sortBy
      });
    });
  }

  render() {
    return (
      <div>
        <div className="influencer-list-filter-categories">
          <AllCategories
            categories={this.state.categories}
            onChange={event => this.filterChecked(event, 'categories')}
          />
        </div>
        <div className="influencer-list-filter-platform">
          <FilterCheckBox
            onChange={event => this.filterChecked(event, 'platformList')}
            filterList={this.state.platformList}
            label="Platform Accounts"
          />
        </div>
        <div>
          <FilterCheckBox
            onChange={event => this.filterChecked(event, 'levelList')}
            filterList={this.state.levelList}
            label="Number Of Followers"
          />
        </div>
        <div className="influencer-list-filter-select-container">
          <Select value={this.state.sortBy} onChange={this.selectSortBy} className='influencer-list-filter-select'>
            <option style={{display: 'none'}}>Sort product by</option>
            <option value='-statistics__likes'>Number of likes</option>
            <option value='name'>Alphabetical</option>
          </Select>
        </div>
      </div>
    );
  }
}
