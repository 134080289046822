import { Component } from 'react';

import Button from '../../../../../../components/Button';
import SelectableList from '../../../../../../components/SelectableList';
import SelectableImage from '../../../../../../components/SelectableImage';

import { INTEREST_IMG_LIST } from './config';
import { formatDate } from '../../../../../../utils/util';
import request from '../../../../../../service/request';
import { withRouter } from 'react-router';

import './index.css';
import Checkbox from '@mui/material/Checkbox';
import {INFLUENCER_ROUTE} from '../../../../../../router/config';


class Interest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interests: '',
      rawInterest: {}
    }
    this.submit = this.submit.bind(this);
    this.update = this.update.bind(this);
  }

  update(params) {
    this.setState({rawInterest: params})
    let str = []
    for(let i in params) {
      if(params[i]) {
        str.push(INTEREST_IMG_LIST[i].label.toLocaleLowerCase())
      }
    }
    this.setState({
      interests: str.join(':')
    });
  }

  async submit() {
    const {
      email,
      password,
      avatar
    } = this.props.info;
    const {
      firstName,
      lastName,
      country,
      state,
      postalCode,
      address,
      address2,
      city,
      birthYear,
      birthMonth,
      birthDay,
      phoneNum
    } = this.props.profile
    let params = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      country,
      state,
      postal_code: postalCode,
      address,
      address2,
      city,
      birthdate: formatDate(birthYear, birthMonth, birthDay),
      phone_number: phoneNum,
      avatar,
      interests: this.state.interests
    }
    try {
      const newUserRes = await request(['influencer.create', {data: params}]);
      if (newUserRes.status === 400) {
        alert(JSON.stringify(newUserRes.data));
        return
      }
      const {
        props: {
          history: { push }
        }
      } = this;
      await request(['token.create', {
        data: {
          email,
          password
        }
      }], {isErrorHandler: false}) || '';
      push(INFLUENCER_ROUTE.path.replace(':page', 'product'));
      // push(AUTH_ROUTE.path.replace(':role', 'influencer'));
    } catch(e) {
      alert(e);
    }
  }

  render() {
    return (
      <div className="interest-wrap flex-column flex-start-center">
        <div className="signup-right-title">SELECT YOUR INTERESTS</div>
        <div className="signup-right-subtitle">
          We will prioritise products based on your interests
        </div>
        <div style={{
          width: '100%',
          marginTop: '40px'
        }}>
          <Checkbox
            name='isCheck'
            onChange={(event, checked) => {
              if (checked) {
                const totalLength = INTEREST_IMG_LIST.length
                const updateParam = {}
                for (let i = 0; i < totalLength; i ++) {
                  updateParam[i] = true
                }
                this.update(updateParam)
              } else {
                this.update({})
              }
            }}
          />
          <span>Select All</span>
        </div>
        <div className="flex-grow">
          <SelectableList
            list={INTEREST_IMG_LIST}
            component={SelectableImage}
            style={{ width: '41.66vw' }}
            update={this.update}
            selected={this.state.rawInterest}
            componentStyle={{
              style: {
                width: '6.31vw',
                height: '6.31vw',
                edge: '0.9765vw',
                borderRadius: '50%'
              },
              selectedStyle: {
                backgroundColor: '#8657ff',
                opacity: 0.3
              },
              unselectedStyle: {
                boxSizing: 'border-box',
                border: '0.1953vw solid #8657ff'
              },
              labelStyle: {
                padding: '0.1302vw 0.0651vw',
                fontSize: '0.78125vw',
                color: '#fff',
                backgroundColor: '#8657ff',
                opacity: '0.8',
                width: '113px',
                textAlign: 'center'
              }
            }}
          />
        </div>
        <div>
          <Button style={{
            width: '200px',
            height: '50px',
            fontSize: '20px',
            marginRight: '40px'
          }} onClick={() => this.props.setStep(2)} colorSet={'white'}>{'< '}Previous Step</Button>
          <Button style={{
            width: '392px',
            height: '50px',
            fontSize: '20px'
          }} onClick={this.submit}>Join Us!</Button>
        </div>
      </div>
    );
  }
};

export default withRouter(Interest);
