import { Component } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import './index.css';

export default class ReadMore extends Component {
  render() {
    const {
      props: {
        label = '',
        folded,
        reverse = false,
        colorSet = 'black',
        className = '',
        iconClassName = '',
        style = {},
        iconStyle = {},
        onClick = new Function()
      }
    } = this;
    const ArrowComponent = folded === undefined
      ? ArrowForwardIosIcon
      : folded
        ? KeyboardArrowDownIcon
        : KeyboardArrowUpIcon;

    return (
      <div
        className={`flex-row${reverse ? '-reverse' : ''} font_${colorSet} flex-start-center fit-content`}
        onClick={onClick}
      >
        <div className={`readmore-label_default ${className}`} style={style}>
          { label || (folded || folded === undefined ? 'Read More' : 'Show Less') }
        </div>
        <ArrowComponent
          className={`readmore-icon_default ${iconClassName}`}
          style={iconStyle}
        />
      </div>
    );
  }
}
