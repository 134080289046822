import { Component } from 'react';
import './index.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Divider from '@mui/material/Divider';

export default class SettingLayout extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const { children, setEffect, menuList, isBack } = this.props;
    return(
      <div className="setting-layout-container">
        {
          isBack ? 
            <div className="setting-layout-title">
              <ArrowBackIosNewIcon />
              <div className="setting-layout-back">Back</div>
            </div>
            :
            null
        }
        <div className="flex-row">
          <div className="setting-layout-menu">
            <div className="menu-container">
              {
                (() => {
                  let arr = [];
                  menuList.map((menuItem, j) => {
                    menuItem.map((each, i) => {
                      arr.push(
                        <div 
                          key={each.name} 
                          className={ each.selected ? 'menu-item-selected' : 'menu-item' } 
                          onClick={() => {
                            setEffect(each);
                          }}
                        >
                          {each.name}
                        </div>);
                    })
                    arr.push(<Divider key={j} sx={{ mt: '1.4973vw' }} />);
                  })
                  return arr;
                })()
              }
            </div>
          </div>
          {children}
        </div>
      </div>
    );
  }
}
