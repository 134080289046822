import { Component } from 'react';

import Card from '../../../../../../../../components/Card';

import './index.css';

// import request from '../../../../../../../../service/request';
//import { PLATFORM } from '../../../../../../../../constants/platforms';
import { formatStatisticsByLevel } from '../../../../../../../../utils/util';

export default class FollowerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
    this.load = this.load.bind(this);
  }

  async load() {
    // const { list: [] } = await request(['', {
    //   data: {}
    // }]) || '';
  }

  render() {
    const {
      props: {
        data: {
          tiktok_statistics = {},
          youtube_statistics = {},
          instagram_statistics = {},
          instagram_story_statistics = {}
        }
      }
    } = this;

    const platforms = [
      {
        name: 'instagram',
        value: instagram_statistics
      },
      {
        name: 'instagram_story',
        value: instagram_story_statistics
      },
      {
        name: 'youtube',
        value: youtube_statistics
      },
      {
        name: 'tiktok',
        value: tiktok_statistics
      }
    ]

    return (
      //Object.keys(data).filter(each => PLATFORM[each.toLowerCase()]).map((each, i) => {
      platforms.map((each, i) => {
        return (
          <Card
            key={i}
            title={each.name}
            subtitle={formatStatisticsByLevel(each.value&&each.value.followers)}
            className="follower-card"
            titleClassName="follower-card-title"
            subtitleClassName="follower-card-subtitle"
          />
        );
      })
    );
  }
}
