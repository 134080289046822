import {
  getAxisSuffix,
  getFlexSuffix
} from '../../utils/style';

export default function Navigation (props = {}) {
  const {
    routes,
    path,
    orientation = '1',
    scrollable = true,
    className = '',
    bgClassName = '',
    itemClassName = '',
    selectedItemClassName = '',
    style = {},
    itemStyle = {},
    selectedItemStyle = {},
    iconStyle = {},
    prefix,
    suffix,
    onClick
  } = props;
  return (
    <div
      className={`flex-${getFlexSuffix(orientation)} flex-start ${className} ${bgClassName}`}
      style={style}
    >
      {prefix}
      <div
        className={`flex-grow ${bgClassName} ${scrollable
          ? `scroll-${getAxisSuffix(orientation)}`
          : ''
        }`}
        style={{ width: 'inherit' }}
      >
        {
          routes.map((each, i) => {
            const selected = each.path === path;
            const Icon = each.icon;
            return (
              <div
                key={i}
                className={`flex-row flex-start-center ${selected
                  ? selectedItemClassName
                  : itemClassName
                }`}
                style={selected ? selectedItemStyle : itemStyle}
                onClick={() => onClick(each.path)}
              >
                {Icon && <Icon sx={iconStyle.sx} style={iconStyle.style} />}
                <div>{each.title}</div>
              </div>
            );
          })
        }
      </div>
      {suffix}
    </div>
  );
};
