import { Component } from 'react';

import Card from '../Card';
import Avatar from '../Avatar';
import Button from '../Button';
import ReadMore from '../ReadMore';
import List from '../List';
import Image from '../Image';
import Dialog from '../Dialog';

import Quota from '../Quota';
import Timeline from './components/Timeline';
import AvatarList from './components/AvatarList';

import './index.css';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../router/config';
import { PLATFORM } from '../../constants/platforms';
import { formatStatisticsByLevel } from '../../utils/util';

const BUTTON_COLOR = {
  open: 'green',
  done: 'gray'
};

const ACTIVE = 'open';

class CollabCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      visible: false
    };
  }

  render() {
    const {
      state: {
        visible
      },
      props: {
        data: {
          id,
          product: {
            name,
            image_base_url = '',
            images = ''
          } = {},
          status,
          start_date = '',
          current_date = new Date(),
          end_date = '',
          quota = {},
          statistics: {
            likes = {},
            views = {}
          } = {},
          orders = []
        },
        className = '',
        history: {
          push
        },
        deleteFunc = new Function
      }
    } = this;
    const platforms = Object.keys(likes || {}).slice(0, 4);
    const applied = orders.length;

    return (
      <div> 
        <Card className={`collab-card ${className}`} contentClassName="inherit">
          <div className="inherit flex-row flex-between">
            <div className="flex-grow flex-row flex-between">
              <div className="flex-column flex-center">
                <Avatar
                  avatar={`${image_base_url}${images.split(':')[0]}`}
                  className="collab-card-avatar"
                  shape="square"
                />
              </div>
              <div className="collab-card-content flex-grow flex-column">
                <div className="flex-grow flex-row flex-between-start">
                  <div className="collab-card-name">{name}</div>
                  <Button
                    className="fit-content collab-card-statusbtn"
                    colorSet={BUTTON_COLOR[status]}
                    disabled={true}
                  >
                    {status.toUpperCase()}
                  </Button>
                </div>
                {
                //(status !== ACTIVE || !orders.length) &&
                  <div className="collab-card-quota flex-row">
                    {
                      Object.keys(quota).map((each, i) =>
                        <Quota key={i} platform={each} count={quota[each]} />
                      )
                    }
                  </div>
                }
                <Timeline
                  className="collab-card-timeline"
                  status={status}
                  start={new Date(start_date)}
                  end={new Date(end_date)}
                  now={new Date(current_date)}
                />
                {
                  status === ACTIVE && orders.length
                    ? <AvatarList
                      className="collab-card-avatarlist"
                      list={orders.map(each => `${each.influencer.avatar_base_url}${each.influencer.avatar}` || '')}
                    />
                    : <></>
                }
                <div className="width-inherit flex-column">
                  <div className="collab-card-viewdetails flex-row-reverse">
                    <ReadMore
                      label="View Details"
                      className="collab-card-viewdetails-label"
                      iconClassName="collab-card-viewdetails-icon"
                      onClick={() => push(`${BRAND_PATH}/collaboration/${id}`)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg_lightpurple height-inherit flex-column flex-center">
              {
                status === ACTIVE && orders.length
                  ? (
                    <div className="collab-card-right-empty">
                      <div className="flex-row flex-center">
                        <Button 
                          className="btn-viewapplications" 
                          colorSet="purple"
                          onClick={() => {push(`${BRAND_PATH}/collaboration/${id}`)}}
                        >
                        View Applications
                        </Button>
                      </div>
                      <div className="collab-card-appliedpeople flex-row flex-center">
                        <div>{applied} People Applied</div>
                      </div>
                    </div>
                  )
                  : (
                    <List className="collab-card-statistics height-inherit flex-between">
                      {
                        platforms.map((each, i) => (
                          <Card
                            key={i}
                            className="collab-card-statistics-card"
                            contentClassName="flex-center-start"
                          >
                            {
                              <div className="flex-column flex-start-center">
                                <div className="collab-card-platform flex-row flex-center">
                                  <Image className="collab-card-platform-icon" image={PLATFORM[each.toLowerCase()].icon} />
                                  <div>{PLATFORM[each.toLowerCase()].label}</div>
                                </div>
                                <div
                                  className="collab-card-likes collab-card-statistics-title"
                                >
                                  {formatStatisticsByLevel(likes[each] || 0)} Likes
                                </div>
                                <div
                                  className="collab-card-views collab-card-statistics-subtitle"
                                >
                                  {formatStatisticsByLevel(views[each] || 0)} Views
                                </div>
                              </div>
                            }                         
                          </Card>
                        ))
                      }
                    </List>
                  )
              }
            </div>
          </div>
        </Card>
        {
          status === ACTIVE && orders.length == 0 ?
            <Button
              className='collab-card-delete-btn'
              colorSet="white"
              onClick={() => this.setState({visible: true})}
            >
              Delete Collaboration
            </Button>
            :
            <></>
        }
        <Dialog
          showBack={false}
          visible={visible}
          className='flex-column flex-center'
        >
          {
            <div className='flex-column flex-center'>
              <div className='collab-card-delete-dialog-font'>Are you sure to delete this collaboration ?</div>
              <div className='collab-card-delete-btn-group flex-row flex-around '>
                <Button 
                  className="collab-card-delete-dialog-btn" 
                  colorSet="white" 
                  onClick={() => {
                    deleteFunc(id)
                    this.setState({visible: false})
                  }}>
                Yes
                </Button>
                <Button className="collab-card-delete-dialog-btn" colorSet="black" onClick={() => this.setState({visible: false})}>Cancel</Button>
              </div>
            </div>
          }
        </Dialog>
      </div>
    )
  }
}

export default withRouter(CollabCard);
