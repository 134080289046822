import Dialog from '../../../../components/Dialog';
import Button from '../../../../components/Button';

import { useHistory } from 'react-router-dom';

import './index.css';

import authStore from '../../../../store/user/auth';
const auth = authStore();

export default function Logout (props =  {}) {

  let history = useHistory();

  function logout() {
    auth.logout();
    history.push('/');
  }

  return (
    <div>
      <Dialog
        showBack={true}
        visible={true}
        className='flex-column flex-center'
      >
        {
          <div className='flex-column flex-center'>
            <div className='log-out-block'>Logout of BCom ?</div>
            <Button className='log-out-btn' colorSet="white" onClick={logout}>OK</Button>
          </div>
        }
      </Dialog>
    </div>
  );
};
