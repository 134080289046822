import './index.css';

export default function Tabs(props = {}) {
  const {
    tabs = [],
    selected = 0,
    className = '',
    tabClassName = '',
    selectedClassName = '',
    unselectedClassName = '',
    onClick = new Function()
  } = props;
  return (
    <div className={`flex-row ${className}`}>
      {
        tabs.map((each, i) =>
          <div
            key={i}
            className={
              `tab_default ${tabClassName} ${selected === i
                ? selectedClassName || 'tab-selected_default'
                : unselectedClassName || 'tab-unselected_default'
              } ${className}`
            }
            onClick={each.onClick || onClick}
          >
            {each.label}
          </div>
        )
      }
    </div>
  );
}
