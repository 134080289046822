import Avatar from '../../../Avatar';

import { calTimeDiff } from '../../../../utils/util';

import './index.css';

export default function Message(props = {}) {
  const {
    data: {
      avatar_base_url = '',
      from_avatar = '',
      from_name = '',
      message = '',
      date = ''
    } = {},
    className
  } = props;
  // const currentTimestamp = new Date();

  return (
    <div>
      {
        message &&
        <div className={`flex-row flex-start-center ${className}`}>
          <Avatar
            avatar={`${avatar_base_url}${from_avatar}`}
            className="inbox-messagepanel-avatar"
          />
          <div className="flex-column flex-start">
            <div className="flex-row flex-start-center">
              <div className="inbox-messagepanel-name">{from_name}</div>
              <div className="inbox-messagepanel-time">{calTimeDiff(date)}</div>
            </div>
            <div className="inbox-messagepanel-message">{message}</div>
          </div>
        </div>
      }
    </div>
  );
}
