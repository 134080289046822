import { Component } from 'react';

//import AllCategories from './components/AllCategories';
import Select from '../Select';
import FilterCheckBox from '../FilterCheckBox';

import './index.css';

//const categories = ['Cosmetics', 'Fashion', 'Fitness & Nutrition', 'Food & Beverages', 'Hair Care', 'Home & Garden'];

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platformList: [
        {
          icon: require('../../assets/images/logo/logo-instagram.webp'),
          name: 'Instagram',
          value: 'instagram',
          checked: false
        },
        {
          icon: require('../../assets/images/logo/logo-tiktok.webp'),
          name: 'TikTok',
          value: 'tiktok',
          checked: false
        },
        {
          icon: require('../../assets/images/logo/logo-instagram_story.webp'),
          name: 'Instagram Story',
          value: 'instagram_story',
          checked: false
        },
        {
          icon: require('../../assets/images/logo/logo-youtube.webp'),
          name: 'YouTube',
          value: 'youtube',
          checked: false
        }
      ],
      levelList: [
        {
          name: '0-1k',
          value: '0',
          checked: false
        },
        {
          name: '1k-5k',
          value: '1',
          checked: false
        },
        {
          name: '5k-10k',
          value: '2',
          checked: false
        },
        {
          name: '10k-100k',
          value: '3',
          checked: false
        },
        {
          name: '100k-1M',
          value: '4',
          checked: false
        },
        {
          name: '1M +',
          value: '5',
          checked: false
        }
      ],
      sortBy: ''
    }
    this.filterChecked = this.filterChecked.bind(this);
    this.selectSortBy = this.selectSortBy.bind(this);
  }

  filterChecked(event, type) {
    const {
      state: {
        platformList,
        sortBy
      },
      props: {
        searchCollab
      }
    } = this;
    let name = event.target.name;
    switch(type) {
    case 'platformList': 
      platformList.map((each, i) => {
        if(each.name === name) {
          each.checked = !each.checked;
        }
      });
      this.setState({platformList});
      break;
    }
    searchCollab({
      platformList,
      sortBy
    });
  }

  selectSortBy(event) {
    const {
      state: {
        platformList
      },
      props: {
        searchCollab
      }
    } = this;
    this.setState({sortBy: event.target.value}, () => {
      searchCollab({
        platformList,
        sortBy: this.state.sortBy
      });
    });
  }

  render() {
    return (
      <div>
        {/*<div className="influencer-list-filter-categories">
          <AllCategories
            categories={categories}
          />
        </div>*/}
        <div className="influencer-list-filter-platform">
          <FilterCheckBox
            onChange={event => this.filterChecked(event, 'platformList')}
            filterList={this.state.platformList}
            label="Platform Accounts"
          />
        </div>
        {/*<div>
          <FilterCheckBox
            onChange={this.filterChecked}
            filterList={this.state.levelList}
            label="Number Of Followers"
          />
        </div>*/}
        <div className="influencer-list-filter-select-container">
          <Select value={this.state.sortBy} onChange={this.selectSortBy} className='influencer-list-filter-select'>
            <option style={{display: 'none'}}>Sort product by</option>
            <option value='start_date'>Start Date</option>
            <option value='end_date'>End Date</option>
          </Select>
        </div>
      </div>
    );
  }
}
