import { Component } from 'react';

import Image from '../Image';
import Counter from '../Counter';

import { PLATFORM } from '../../constants/platforms';

import './index.css';

export default class CollabRequirements extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      props: {
        platform,
        amount = 0,
        children,
        editable,
        className = '',
        getCounter = new Function()
      }
    } = this;
    const { icon = '', label = '' } = PLATFORM[platform.toLowerCase()] || {};
    return (
      <div className='collab-requirements-container flex-row flex-center'>
        <div className={`collab-requirements-block flex-row flex-between-center ${className}`}>
          <div className='flex-row flex-center'>
            <Image
              className='collab-requirements-img'
              image={icon}
            />
            <div>{label}</div>
          </div>
          {
            children ||
            (
              editable
                ? <Counter getCounter={getCounter} platform={platform.toLowerCase()}/>
                : <div className="collab-requirements-amount">{amount}</div>
            )
          }
        </div>
      </div>
    );
  }
}
