import { Component } from 'react';

import Container from '../Container';
import SelectableList from '../../../../../../components/SelectableList';
import SelectableImage from '../../../../../../components/SelectableImage';
import Button from '../../../../../../components/Button';
import { INTEREST_IMG_LIST } from './config';

import request from '../../../../../../service/request';

import { observer } from 'mobx-react';

import { RootStoreContext } from '../../../../../../store';

import './index.css';

class Interests extends Component {
  static contextType = RootStoreContext;
  constructor(props) {
    super(props);
    this.state = {
      interests: ''
    }
    this.update = this.update.bind(this);
    this.updateInterests = this.updateInterests.bind(this);
  }

  update(params) {
    let str = []
    for(let i in params) {
      if(params[i]) {
        str.push(INTEREST_IMG_LIST[i].label.toLocaleLowerCase())
      }
    }
    this.setState({
      interests: str.join(':')
    });
  }

  updateInterests() {
    const { 
      state: { 
        interests 
      },
      context: {
        influencer
      }
    } = this;
    request(['influencer.update.interests', {data: {interests}}]);
    influencer.setInfo({interests});
  }

  render() {
    return (
      <Container
        title="Choose Your Interests"
        subtitle="Select any categroy that interests you"
      >
        <div className="setting-info">
          <SelectableList
            className='flex-row flex-start flex-wrap'
            list={INTEREST_IMG_LIST}
            component={SelectableImage}
            style={{ width: '41.66vw' }}
            update={this.update}
            componentStyle={{
              style: {
                width: '6.31vw',
                height: '6.31vw',
                edge: '0.9765vw',
                borderRadius: '50%'
              },
              selectedStyle: {
                backgroundColor: '#8657ff',
                opacity: 0.3
              },
              unselectedStyle: {
                boxSizing: 'border-box',
                border: '0.1953vw solid #8657ff'
              },
              labelStyle: {
                padding: '0.1302vw 0.0651vw',
                fontSize: '0.78125vw',
                backgroundColor: '#fff'
              }
            }}
          />
        </div>
        <Button className="interests-btn-update" onClick={this.updateInterests}>Update</Button>
      </Container>
    )
  }
};

export default observer(Interests);