import { Component } from 'react';

import Button from '../Button';
import ProductAdd from '../../views/Brand/components/ProductList/components/ProductAdd';

import request from '../../service/request';

export default class EditProductButton extends Component{
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isShowDelete: false
    }
    this.closeAddProduct = this.closeAddProduct.bind(this);
    this.getProduct = this.getProduct.bind(this);
  }

  closeAddProduct() {
    this.setState({visible: false});
  }

  async getProduct() {
    const {
      data: {
        id
      }
    } = this.props;
    const { 
      data = {}
    } = await request(['product.read', {
      params: id
    }]);
    return data;
  }


  render() {
    const {
      state: {
        visible,
        isShowDelete
      },
      props: {
        data,
        className = '',
        onClick = new Function,
        plain,
        updateProduct,
        deleteFunc
      }
    } = this;
    return (
      <div>
        {
          plain ?
            <div>
              <div
                onClick={async () => {
                  const { collaboration_count = 0 } = await this.getProduct();
                  this.setState({
                    visible: true,
                    isShowDelete: collaboration_count == 0
                  });
                  if(onClick) {
                    onClick();
                  }
                }}
                className={`${className} flex-self-end flex-column flex-center-end`}
              >
							Edit Product
              </div>
            </div>
            :
            <div>
              <Button onClick={() => {
                this.setState({visible: true});
                if(onClick) {
                  onClick();
                }
              }} 
              className={className}
              colorSet="blue"
              >
								Edit Product
              </Button>
            </div>
        }
        <ProductAdd
          visible={visible}
          data={data}
          saveFunc={updateProduct}
          deleteFunc={(id) => {
            deleteFunc(id);
            this.closeAddProduct();
          }}
          closeDialog={this.closeAddProduct}
          isShowDelete={isShowDelete}
        />
      </div>
    );
  }
}
