import { Component } from 'react';
/* eslint-disable */
import Checkbox from '@mui/material/Checkbox';
import Image from '../../../../../../components/Image';

import './index.css';


export default class Filter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, list, onChange, checkBoxClassName, style } = this.props;
    return (
      <div className='influencer-collaboration-fiter-item flex-row flex-center'>
        <div className='influencer-collaboration-fiter-label'>
          {label}:
        </div>
				<div className='flex-row flex-start-center'>
				{
          list.map((each, i) => {
            return (
              <div key={i} className='flex-row flex-center'>
                <Checkbox
								  checked={each.checked}
                  name={each.name}
                  value={each.value}
                  onChange={onChange}
                  className={checkBoxClassName}
                  sx={{
                    color: 'rgb(235, 235, 235)',
                    '&.Mui-checked': {
                      color: 'rgb(255, 82, 82)'
                    },
                    ...style
                  }
                  }
                />
								{
									each.icon ? 
									<Image image={each.icon} className="filter-check-box-img" />
									:
									null
								}
                <div className="filter-check-box-text">{each.name}</div>
              </div>
            )
          })
        }
				</div>
      </div>
    );
  }
}