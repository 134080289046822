/**
 * @description 计算某年某月有几天
 * @param {*} year
 * @param {*} month
 * @returns
 */
export const calcDays = (year, month) => {
  let days;
  switch(month) {
  case '1':
  case '3':
  case '5':
  case '7':
  case '8':
  case '10':
  case '12':
    days = 31;
    break;
  case '2':
    if(((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) {
      days = 29;
    } else {
      days = 28;
    }
    break;
  default:
    days = 30;
    break;
  }
  return days;
}

/**
 * @description 格式化date，返回yyyy-mm-dd
 * @param {*}
 * @returns
 */
export const formatDate = (year, month, day) => {
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function calTimeDiff(time) {
  var interval = new Date().getTime()/1000-time;
  //计算出相差天数
  var days=Math.floor(interval/(24*3600*1000))
  if(days==0)
  {        
    //计算出小时数
    var leaveTime=interval%(24*3600*1000)    //计算天数后剩余的毫秒数
    var hours=Math.floor(leaveTime/(3600*1000))
    if(hours==0)
    {
      //计算相差分钟数
      leaveTime=leaveTime%(3600*1000)        //计算小时数后剩余的毫秒数
      var minutes=Math.floor(leaveTime/(60*1000))
      if(minutes==0)
      {
        //计算相差秒数
        leaveTime=leaveTime%(60*1000)      //计算分钟数后剩余的毫秒数
        var seconds=Math.round(leaveTime/1000)                                    
        return seconds+' Seconds';
      }
      return minutes+' Minutes';
    }
    return hours+' Hours';

  }
  return days + ' Days';
}

export const formatStatistics = val =>
  val && val.toString().replace(/(\d)(?=(\d{3})+(\.?)$)/g, '$1, ');

const STATISTICS_LEVEL = [
  {
    symbol: ''
  },
  {
    symbol: 'K'
  },
  {
    symbol: 'M'
  },
  {
    symbol: 'B'
  }
];

export const formatStatisticsByLevel = val => {
  if (!val) return 0;
  if(val.toString().indexOf('.')>0) return Math.floor(val * 100) / 100;
  const level = (val.toString().match(/(\d)(?=(\d{3})+(\.?)$)/g) || []).length;
  return `${
    level ? (val/Math.pow(1000, level)).toFixed(1) : val
  }${
    STATISTICS_LEVEL[Math.min(level, STATISTICS_LEVEL.length)].symbol
  }`;
}

export const assembleFullName = (first_name, last_name) =>
  [first_name, last_name].join(' ');

/**
 *@description 将url资源转换为base64
 */
export const getBase64 = (url, callback) => {
  let Img = new Image(),
    dataURL = '';
  Img.setAttribute('crossOrigin', 'anonymous');
  Img.src = url + '?v=' + Math.random();
  Img.onload = function() {
    let canvas = document.createElement('canvas'),
      width = Img.width,
      height = Img.height;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
    dataURL = canvas.toDataURL('image/jpeg');
    return callback ? callback(dataURL) : null;
  };
}

/**
 * @descript 校验url是否合法
 * @param {*} URL 
 * @returns 
 */
export const  checkURL = (URL) => {
  let str = URL;
  //判断URL地址的正则表达式为:http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&=]*)?
  //下面的代码中应用了转义字符"\"输出一个字符"/"
  let Expression=/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;
  let objExp=new RegExp(Expression);
  if(objExp.test(str)==true){
    return true;
  }else{
    return false;
  }
} 

export function formatPhoneNumber(phone) {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return phone;
}

export function formatSSN(ssn) {
  let cleaned = ('' + ssn).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return ssn;
}