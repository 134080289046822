import { Component } from 'react';
import Filter from './components/Filter';

import Search from '../../../../components/Search';
import List from '../../../../components/List';
import ProductCard from '../../../../components/ProductCard';
import Quota from '../../../../components/Quota';
import LoadMore from '../../../../components/LoadMore';

import './index.css';

import request from '../../../../service/request';

import { withRouter } from 'react-router';
import { INFLUENCER_PATH } from '../../../../router/config';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '', // product name query text
      page: 1, // pagination page number, optional, default 1
      pageSize: 9, // pagination size, optional, default 10
      list: [],
      total: 0,
      searchPage: 1,
      searchName: '',
      brandList: [],
      platformList: [],
      socialStatusList: [],
      categories: [],
      //withList,
      sortBy: ''
    }
    this.load = this.load.bind(this);
    this.getSocialStatus = this.getSocialStatus.bind(this);
    this.queryCollab = this.queryCollab.bind(this);
  }

  async load() {
    const {
      state: {
        query,
        page,
        pageSize,
        list,
        total
      }
    } = this;
    const {
      data: {
        count,
        results = []
      } = {}
    } = await request(['collaboration.list.search', {
      query: {
        query,
        page,
        page_size: pageSize
      }
    }]) || '';
    this.setState({
      list: list.concat(results),
      page: page + 1,
      total: count || total
    });
  }

  async queryCollab() {
    const {
      page,
      searchPage,
      searchName,
      brandList,
      categories,
      platformList,
      socialStatusList,
      //withList,
      sortBy,
      total,
      list
    } = this.state;
    let brands = [];
    brandList.map(each => {
      if(each.checked === true) {
        brands.push(each.user.id);
      }
    });
    let category = [];
    categories.map(each => {
      if(each.checked === true) {
        category.push(each.value);
      }
    })
    let platform = [];
    platformList.map(each => {
      if(each.checked === true) {
        platform.push(each.value);
      }
    });
    //链接某个social media平台后只显示这个social media平台有的collaboration
    platform = Array.from(new Set([...platform, ...socialStatusList]));
    const {
      data: {
        count,
        results = []
      }
    } = await request(['collaboration.read.search', {
      query: {
        page: searchName ? searchPage : page,
        page_size: 9,
        brands: brands.join(','),
        category: category.join(','),
        platform_accounts: platform.join(','),
        order_by: sortBy,
        query: searchName
      }
    }]);
    this.setState({
      list: list.concat(results),
      page: searchName ? page : page + 1,
      searchPage: searchName ? searchPage + 1 : searchPage,
      total: count || total
    });
  }

  async getSocialStatus() {
    const { data } = await request(['social.read.status']);
    let list = [];
    for(let item in data) {
      if(data[item]) {
        list.push(item);
      }
    }
    return list;
  }

  async componentDidMount() {
    let list = await this.getSocialStatus();
    this.setState({
      socialStatusList: list
    }, async () => {
      await this.queryCollab();
    });
  }

  render() {
    const { 
      state: {
        list,
        total,
        socialStatusList
      }
    } = this;
    return (
      <div>
        <div className="flex-row">
          <div className="influencer-product-list-filter z-index-1 scroll-y">
            <Filter
              socialStatusList={socialStatusList}
              queryCollab={data => {
                const {
                  brandList,
                  categories,
                  platformList,
                  //withList,
                  sortBy
                } = data;
                this.setState({
                  brandList,
                  categories,
                  platformList,
                  //withList,
                  sortBy,
                  page: 1,
                  searchPage: 1,
                  list: []
                }, () => this.queryCollab());
              }}
            />
          </div>
          <div className="influencer-product-list-content scroll-y">
            <Search 
              placeholder="Search Product..."
              onClick={searchName => {
                this.setState({
                  list: [], 
                  searchName, 
                  page: 1, 
                  searchPage: 1 
                }, () => this.queryCollab());
              }}
            />
            <List className="influencer-product-card-list flex-start">
              {
                list.map((each, i) => {
                  const {
                    id: collaborationId,
                    product: {
                      categories,
                      name,
                      image_base_url,
                      images
                    },
                    quota
                  } = each;
                  const image = images.split(':');
                  return (
                    <div key={i} className="flex-column influencer-product-card-listitem">
                      <ProductCard
                        id={collaborationId}
                        title={categories.split(':')[0]}
                        subtitle={name}
                        image={`${image_base_url}${image[0]}`}
                        product={list}
                        router={`${INFLUENCER_PATH}/product/${collaborationId}`}
                      />
                      <div className="influencer-product-card-quota flex-row flex-wrap">
                        {
                          Object.keys(quota).map((each, i) =>
                            <Quota key={i} platform={each} count={quota[each]} />
                          )
                        }
                      </div>
                    </div>
                  );
                })
              }
            </List>
            <div className="influencer-product-list-bottom width-inherit flex-row flex-center">
              { total > list.length ? <LoadMore className="btn-loadmore-productlist" onClick={this.queryCollab} /> : <></> }
            </div>
          </div>
      	</div>
      </div>
    );
  }
}

export default withRouter(Products);