import { Component } from 'react';

import SignLayout from '../../layout/SignLayout';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ForgotPwd from './components/ForgotPwd';
import './index.css';
import jwt_decode from 'jwt-decode';

import request from '../../service/request';

import { HOME, BRAND_ROUTE, INFLUENCER_ROUTE } from '../../router/config';
import { RootStoreContext } from '../../store';
import { ROLE } from '../../store/user/auth';
// import { rootStore } from '../../store';
// const { exception } = rootStore;

export default class Signin extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmedPassword: '',
      isShowForgotPwd: false,
      errorMsg: ''
    };
    this.updateParams = this.updateParams.bind(this);
    this.signin = this.signin.bind(this);
    this.submit = this.submit.bind(this);
  }

  updateParams(event) {
    this.removeErrMsg()
    const name = event.target.name;
    const value = event.target.value;
    this.setState({[name]: value});
  }

  async signin() {
    const {
      state: { email, password },
      props: {
        match: { params: { role } },
        history: { push },
        location: {
          state: {
            redirectUrl = ''
          } = {}
        } = {}
      },
      context: {
        auth: {
          setRole
        } = {}
      }
    } = this;
    setRole(role);
    const {
      data: {
        detail = '',
        access = ''
      }
    } = await request(['token.create', {
      data: {
        email,
        password
      }
    }], {isErrorHandler: false}) || '';
    if (!detail) {
      const { user_role } = jwt_decode(access);
      if(user_role.toLowerCase() != role) {
        this.setState({errorMsg: 'No active account found with the given credentials'})
        // exception.message = 'No active account found with the given credentials';
        return;
      }
      switch(role) {
      case 'brand':
        push(redirectUrl ? redirectUrl : BRAND_ROUTE.path.replace(':page', 'overview'));
        break;
      case 'influencer':
        push(redirectUrl ? redirectUrl : INFLUENCER_ROUTE.path.replace(':page', 'product'));
        break;
      default:
        push(HOME.path);
      };
    } else {
      this.setState({errorMsg: detail})
      // exception.message = detail;
    }
  }

  submit() {
    this.setState({
      isShowForgotPwd: false,
      isShowDialog: true
    });
  }
  removeErrMsg() {
    this.setState({errorMsg: ''})
  }

  render() {
    const {
      state: {
        email,
        password,
        confirmedPassword,
        isShowForgotPwd
      },
      props: {
        match: {
          params: { role }
        }
      }
    } = this;
    return (
      <SignLayout title="NEW HERE?" role={role}>
        {
          !isShowForgotPwd ?
            <div className="login-block flex-column flex-start-center" >
              <div className="signin-title">WELCOME TO BCom</div>
              <div className="signin-subtitle">
                Sign in to your {role === ROLE[2].label.toLowerCase() ? '' : `${role} `}account
              </div>
              <div className="input-block">
                <Input
                  label="Email"
                  name='email'
                  value={email}
                  colorSet="gray"
                  className="input-email"
                  placeholder="Bcom@gmail.com"
                  onChange={this.updateParams}
                />
                <Input
                  label="Password"
                  name='password'
                  value={password}
                  colorSet="gray"
                  type="password"
                  className="input-password"
                  placeholder="password"
                  onChange={this.updateParams}
                />
                <div style={{
                  margin: '10px 0',
                  color: '#f00'
                }}>{this.state.errorMsg}</div>
                <div className="forgot-text" onClick={() => this.setState({isShowForgotPwd: true, password: ''})}>Forgot my password</div>
                <Button className="btn-signin" onClick={this.signin}>Sign In</Button>
              </div>
            </div>
            :
            <ForgotPwd
              email={email}
              password={password}
              confirmedPassword={confirmedPassword}
              callback={() => this.setState({isShowForgotPwd: false})}
            />
        }
      </SignLayout>
    );
  }
};
