import { Component } from 'react';

import Button from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog';

import request from '../../../../../../service/request';

import { withRouter } from 'react-router';
import { INFLUENCER_PATH } from '../../../../../../router/config';

import './index.css';

class RateConfirmedComponet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDialog: false
    };
    this.confirm = this.confirm.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  async confirm() {
    const { orderId, next } = this.props;
    const {
      data
    } = await request(['order.update.product_received', {
      params: orderId,
      data: {
        id: orderId
      }
    }]);
    data && !data.detail && next({data, pass: true})
    data && !data.detail && this.closeDialog();
  }

  openDialog() {
    this.setState({isShowDialog: true});
  }

  closeDialog() {
    this.setState({isShowDialog: false});
  }

  render() {
    const {
      editable,
      data: {
        tracking_links,
        collaboration: {
          product: {
            owner: {
              brand: {
                name
              }
            }
          }
        }
      },
      history: {
        push
      }
    } = this.props;
    return (
      <div>
        {
          tracking_links ? 
            <div>
						 <div className='flex-column flex-around-center rate-confirm-comp-container'>
                <div className='rate-confirm-comp-title'>Here Is Your Tracking Link</div>
                <a className='rate-confirm-comp-link' href={tracking_links[tracking_links.length-1]} target='_blank' rel="noreferrer">Track Your Order</a>
                {
                  editable && (
                    <Button className='rate-confirm-comp-btn' onClick={this.openDialog}>Product Received</Button>
                  )
                } 
              </div>
            </div>
            :
            <div></div>
        }
        <div className='flex-column flex-center'>
          <div className='rate-confirm-comp-not-receiving'>Not receiving your order?</div>
          <Button className='rate-confirm-comp-btn' onClick={() => {
            push({pathname:`${INFLUENCER_PATH}/inbox`, state: {name: `${name}`}});
          }}>Contact Brand</Button>
        </div>
        <Dialog
          close={this.closeDialog}
          visible={this.state.isShowDialog}
        >
          {
            <div className='flex-column flex-center'>
              <div className='rate-confirm-comp-dialog-confirm'>Confirm you received the product?</div>
              <div className='rate-confirm-comp-dialog-tips'>(This cannot be undone)</div>
              <div className='rate-confirm-comp-dialog-btn-container flex-row flex-between'>
                <Button className="rate-confirm-comp-dialog-btn" colorSet="white" onClick={this.confirm}>Confirm</Button>
                <Button className="rate-confirm-comp-dialog-btn" colorSet="black" onClick={this.closeDialog}>Cancel</Button>
              </div>
            </div>
          }
        </Dialog>
      </div>
    );
  }
}

export default withRouter(RateConfirmedComponet);