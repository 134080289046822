import { Component } from 'react';

import Container from '../Container';
import SwitchTap from './components/SwitchTap';
import Button from '../../../../../../components/Button';

import Divider from '@mui/material/Divider';

import './index.css';

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productStatus: false,
      orderStatus: false,
      messageStatus: false
    };
    this.setSwitch = this.setSwitch.bind(this);
  }

  setSwitch(event) {
    let name = event.target.name;
    this.setState({[name]: event.target.checked});
  }

  render() {
    return (
      <Container
        title="Notification Preferences"
        subtitle="Select and manage your notification options"
      >
        <div className="notifications-container">
          <div className="notifications-email-text">Email Notification</div>
          <div><Divider sx={{mt: '0.651vw', mb: '2.2135vw', borderColor: 'black'}}/></div>
          <SwitchTap
            name="productStatus"
            title="New Products"
            summary="Get notified when new products added that fit your interests"
            onChange={ this.setSwitch }
            value={this.state.productStatus}
          />
          <SwitchTap
            name="orderStatus"
            title="Orders"
            summary="Get notified on your order updates"
            onChange={ this.setSwitch }
            value={this.state.orderStatus}
          />
          <SwitchTap
            name="messageStatus"
            title="Messages"
            summary="Get notified when you have new messages"
            onChange={ this.setSwitch }
            value={this.state.messageStatus}
          />
          <Button className='notifications-btn-update' onClick={this.update}>Update</Button>
        </div>
      </Container>
    )
  }
}