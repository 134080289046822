import { createContext, useContext } from 'react';
import brandStore from './brand';
import InfluencerStore from './influencer';
import authStore from './user/auth';
import exception from './exception';

class RootStore {
  constructor() {
    this.brand = new brandStore();
    this.influencer = new InfluencerStore();
    this.auth = new authStore();
    this.exception = new exception();
  }
}

const RootStoreContext = createContext();

const RootStoreProvider = ({ store, children }) => {
  return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
};

const useRootStore = () => {
  return useContext(RootStoreContext);
};

const rootStore = new RootStore();

export { rootStore, RootStoreProvider, useRootStore, RootStoreContext };
