import './index.css';

import React, { useState } from 'react';

import Input from '../../../Input';
import TextArea from '../../../TextArea';
import Button from '../../../Button';


export default function NewMessage(props = {}) {

  const { 
    onClick = new Function()
	 } = props;

  const [text, setText] = useState();
  const [recipients, setRecipients] = useState();
  //const [subject, setSubject] = useState();

  return (
    <div className='flex-column flex-start'>
      <div className='new-message-title flex-row flex-start-center'>
        <span className='new-message-title-font'>New Message</span>
      </div>
      <Input 
        value={recipients}
        onChange={event => setRecipients(event.target.value)}
        className='new-message-subtitle'
        placeholder='Recipients'
        width='50.97vw'
        height='3.38vw'
      />
      {/*<Input 
        value={subject}
        name='subject'
        onChange={event => setSubject(event.target.value)}
        className='new-message-subtitle'
        placeholder='Subject'
        width='50.97vw'
        height='3.38vw'
      />*/}
      <TextArea 
        value={text}
        onChange={txt => setText(txt)}
        className='new-message-text'
      />
      <Button 
        className='new-message-send' 
        colorSet={ recipients && text ? 'blue' : 'gray'}
        onClick={() => {
          if(recipients && text) {
            onClick({recipients, text})
          }} 
        }>Send</Button>
    </div>
  );
}
