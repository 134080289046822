import Logo from '../Logo';

import './index.css';

export default function Footer(props = {}) {
  const { className } = props;

  return (
    <div className={`flex-column flex-center footer-container ${className}`}>
      <Logo className="footer-logo"/>
      <div className="footer-text">
        2012-2021 BCom ® All rights reserved. Legal, Privacy policy and Contact Us
      </div>
    </div>
  );
}
