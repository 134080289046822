import { Component } from 'react';
import Card from '../../../../../../../../components/Card';
import Button from '../../../../../../../../components/Button';
import Input from '../../../../../../../../components/Input';
import NextTimeButton from '../../../../../../../../components/NextTimeButton';
//import ConfirmRateButton from './components/ConfirmRateButton';
import SubmitOfferButton from './components/SubmitOfferButton';

import './index.css';

import request from '../../../../../../../../service/request';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../../../../../../../router/config';

class Selected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand_rate: this.props.data.brand_pay_offer || '',
      submitted: false
    };

    this.confirm = this.confirm.bind(this);
    this.submit = this.submit.bind(this);
  }

  async confirm(confirmed) {
    const {
      props: {
        id,
        next = new Function()
      }
    } = this;
    const {
      data
    } = await request(['order.update.confirm_influencer_rate', {
      params: id,
      data: { confirmed }
    }]) || {};
    data && !data.detail && next({ order: data, pass: true });
  }

  async submit() {
    const {
      state: { brand_rate },
      props: {
        id = '',
        next = new Function(),
        data: {
          influencer_rate
        } = {}
      }
    } = this;
    if (!brand_rate) return;
    const {
      data
    } = await request(['order.update.send_brand_pay_offer', {
      params: id,
      data: { brand_pay_offer: brand_rate || influencer_rate}
    }]);
    if (data && !data.detail) {
      next({ order: data });
      this.setState({ submitted: true });
    }
  }

  render() {
    const {
      props: {
        id,
        next = new Function(),
        className = '',
        data: {
          influencer,
          influencer: {
            first_name,
            last_name
          },
          influencer_rate,
          brand_pay_offer
        } = {},
        editable,
        reject = new Function(),
        history: {
          push
        }
      },
      state: {
        brand_rate,
        submitted
      },
      //confirm,
      submit
    } = this;

    return (
      <div className={`brandcollabselected flex-column ${className}`}>
        <div className="flex-row flex-center">
          <Card className="brandcollabselected-card brandcollabselected-card-left">
            <div className="full flex-column flex-start-cnter">
              <div className="flex-row flex-center brandcollabselected-title">
                Influencer Rate
              </div>
              <div className="flex-row flex-center-start flex-grow">
                <Card className="brandcollabselected-pricecard">
                  <div className="full flex-row flex-center">
                    <div className="flex-row flex-center">${influencer_rate}</div>
                  </div>
                </Card>
              </div>
              {/*{
                brand_pay_offer ? 
                  <div className="flex-row flex-center">
                    <ConfirmRateButton
                      className="btn-brandcollabselected btn-brandcollabselected-confirm"
                      rate={influencer_rate}
                      influencer={influencer}
                      confirm={() => confirm(true)}
                    />
                  </div>
                  :
                  <></>
              }*/}
            </div>
          </Card>
          <Card className="brandcollabselected-card brandcollabselected-card-right">
            <div className="full flex-column flex-start-cnter">
              <div className="flex-row flex-center brandcollabselected-title">
                Brand Pay Off
              </div>
              <div className="flex-row flex-center-start flex-grow">
                {
                  editable && !submitted
                    ? (
                      <div className="flex-row flex-center">
                        <div className="brandcollabselected-currency">$</div>
                        <Input
                          value={brand_rate}
                          onChange={evt => this.setState({ brand_rate: evt.target.value })}
                          className="brandcollabselected-input"
                        />
                      </div>
                    )
                    : (
                      <Card className="brandcollabselected-pricecard">
                        <div className="full flex-row flex-center">
                          <div className="flex-row flex-center">${brand_pay_offer}</div>
                        </div>
                      </Card>
                    )
                }
              </div>
              {
                editable && !submitted &&
                  <div className="flex-row flex-center">
                    <SubmitOfferButton
                      className="btn-brandcollabselected btn-brandcollabselected-send"
                      rate={brand_rate}
                      influencer={influencer}
                      submit={submit}
                    />
                    <Button
                      className="btn-brandcollabselected btn-brandcollabselected-negotiate"
                      onClick={() => push({pathname: `${BRAND_PATH}/inbox`, state: {name: `${first_name} ${last_name}`}})}
                    >
                      Negotiate Rate
                    </Button>
                  </div>
              }
            </div>
          </Card>
        </div>
        {
          editable &&
            <NextTimeButton
              className="btn-brandcollabselected-nexttime"
              id={id}
              influencer={influencer}
              callback={() => next({ pass: false })}
              reject={reject}
            />
        }
      </div>
    );
  }
}

export default withRouter(Selected);